<script setup>
import { toRefs, watch } from 'vue';
import { useField } from 'vee-validate';
import trixAttachmentApi from '@/api/trixAttachment';
import VueTrix from './vue-trix.vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: { type: String, required: true },
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  name: { type: String, default: undefined },
  variant: {
    type: String,
    default: 'base',
    validator: (value) => ['base'].includes(value),
  },
  size: {
    type: String,
    default: 'base',
    validator: (value) => ['small', 'base'].includes(value),
  },
});

const { name } = toRefs(props);
const {
  value: inputValue,
  handleChange,
  errorMessage,
} = useField(name, undefined, { initialValue: props.modelValue });

watch(inputValue, (newVal) => {
  emit('update:modelValue', newVal);
});

watch(() => props.modelValue, handleChange);

function handleAttachmentAdd({ attachment }) {
  // @TODO
  // if (!attachment.file) return;
  // trixAttachmentApi.create(attachment).then(({ url, contentType }) => {
  //   attachment.setAttributes({ url, href: url, contentType });
  // });
}
</script>

<template>
  <div class="flex w-full flex-col gap-y-4">
    <label
      :for="name"
      class="flex flex-col gap-y-1"
    >
      <span class="text-sm font-medium text-gray-900">
        {{ label }}
      </span>
      <span
        v-if="description"
        class="text-xs font-light text-gray-700"
      >
        {{ description }}
      </span>
    </label>
    <vue-trix
      :src-content="modelValue"
      v-bind="$attrs"
      :input-name="name"
      :class="[
        `vue-trix-editor vue-trix-editor--${variant}`,
        { 'vue-trix-editor--error': errorMessage }
      ]"
      @input="handleChange"
      @trix-attachment-add="handleAttachmentAdd"
    />
    <span
      v-if="errorMessage"
      class="mt-0.5 text-sm text-red-600"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<style lang="scss">
  .vue-trix-editor {
    &.vue-trix-editor--base {
      trix-editor {
        @apply bg-gray-100 box-border border border-transparent text-gray-700
          ring-1 ring-inset ring-gray-300 transition duration-300 ease-in-out
          focus:ring-2 focus:ring-primary-600
          hover:border-primary-500 #{!important};
      }
    }

    &.vue-trix-editor--error {
      trix-editor {
        @apply border-red-600 #{!important};
      }
    }

    trix-editor {
      @apply appearance-none rounded-md w-full py-2 px-3 text-sm sm:text-base;

      .attachment__caption {
        @apply hidden;
      }

      pre {
        @apply text-red-500;
      }

      ul {
        @apply list-disc;
      }

      ol {
        @apply list-decimal;
      }
    }

    trix-toolbar {
      .trix-button-group {
        @apply border-none flex justify-around mb-2;

        &:not(:first-child) {
          @apply ml-4;
        }
      }

      .trix-button-row {
        @apply flex flex-row px-1;
      }

      .trix-button {
        @apply w-6 h-6 max-w-none mx-0.5 border-none rounded-md #{!important};

        &--dialog {
          @apply w-auto;
        }

          &--icon {
          top: 50%;
          left: 50%;
          @apply w-4 h-4 transform -translate-x-1/2 -translate-y-1/2 opacity-100;
        }

        &--icon::before {
          top: 50%;
          left: 50%;
          @apply w-4 h-4 transform -translate-x-1/2 -translate-y-1/2 opacity-100;
        }

        &:not(:first-child) {
          @apply border-l-0;
        }

        &.trix-button--icon-bold::before {
          background-image: url('/trix-toolbar/bold-inactive.svg');
        }

        &.trix-button--icon-italic::before {
          background-image: url('/trix-toolbar/italic-inactive.svg');
        }

        &.trix-button--icon-strike::before {
          background-image: url('/trix-toolbar/strike-inactive.svg');
        }

        &.trix-button--icon-link::before {
          background-image: url('/trix-toolbar/link-inactive.svg');
        }

        &.trix-button--icon-heading-1::before {
          background-image: url('/trix-toolbar/heading-1-inactive.svg');
        }

        &.trix-button--icon-quote::before {
          background-image: url('/trix-toolbar/quote-inactive.svg');
        }

        &.trix-button--icon-code::before {
          background-image: url('/trix-toolbar/code-inactive.svg');
        }

        &.trix-button--icon-bullet-list::before {
          background-image: url('/trix-toolbar/bullet-list-inactive.svg');
        }

        &.trix-button--icon-number-list::before {
          background-image: url('/trix-toolbar/number-list-inactive.svg');
        }

        &.trix-button--icon-decrease-nesting-level::before {
          background-image: url('/trix-toolbar/decrease-nesting-level-inactive.svg');
        }

        &.trix-button--icon-increase-nesting-level::before {
          background-image: url('/trix-toolbar/increase-nesting-level-inactive.svg');
        }

        &.trix-button--icon-attach::before {
          background-image: url('/trix-toolbar/attach-inactive.svg');
        }

        &.trix-button--icon-undo::before {
          background-image: url('/trix-toolbar/undo-inactive.svg');
        }

        &.trix-button--icon-redo::before {
          background-image: url('/trix-toolbar/redo-inactive.svg');
        }

        &.trix-active {
          @apply bg-primary-600;

          &.trix-button--icon-bold::before {
            background-image: url('/trix-toolbar/bold-active.svg');
          }

          &.trix-button--icon-italic::before {
            background-image: url('/trix-toolbar/italic-active.svg');
          }

          &.trix-button--icon-strike::before {
            background-image: url('/trix-toolbar/strike-active.svg');
          }

          &.trix-button--icon-link::before {
            background-image: url('/trix-toolbar/link-active.svg');
          }

          &.trix-button--icon-heading-1::before {
            background-image: url('/trix-toolbar/heading-1-active.svg');
          }

          &.trix-button--icon-quote::before {
            background-image: url('/trix-toolbar/quote-active.svg');
          }

          &.trix-button--icon-code::before {
            background-image: url('/trix-toolbar/code-active.svg');
          }

          &.trix-button--icon-bullet-list::before {
            background-image: url('/trix-toolbar/bullet-list-active.svg');
          }

          &.trix-button--icon-number-list::before {
            background-image: url('/trix-toolbar/number-list-active.svg');
          }

          &.trix-button--icon-decrease-nesting-level::before {
            background-image: url('/trix-toolbar/decrease-nesting-level-active.svg');
          }

          &.trix-button--icon-increase-nesting-level::before {
            background-image: url('/trix-toolbar/increase-nesting-level-active.svg');
          }

          &.trix-button--icon-attach::before {
            background-image: url('/trix-toolbar/attach-active.svg');
          }

          &.trix-button--icon-undo::before {
            background-image: url('/trix-toolbar/undo-active.svg');
          }

          &.trix-button--icon-redo::before {
            background-image: url('/trix-toolbar/redo-active.svg');
          }
        }
      }

      .trix-input {
        &--dialog {
          @apply mr-0;
        }
      }
    }
  }
</style>
