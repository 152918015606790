<script setup>
const navigation = {
  social: [
    {
      name: 'Facebook',
      href: 'https://web.facebook.com/people/Grupalia/61554161525665/',
      icon: 'facebook',
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/grupalia.mx/',
      icon: 'instagram',
    },
  ],
};
</script>

<template>
  <footer class="mt-10 w-full bg-white">
    <div class="mx-auto max-w-7xl overflow-hidden px-6 py-12 lg:px-8">
      <div class="flex justify-center space-x-10">
        <a
          v-for="item in navigation.social"
          :key="item.name"
          :href="item.href"
          class="text-gray-400 hover:text-gray-500"
          target="_blank"
        >
          <span class="sr-only">{{ item.name }}</span>
          <base-icon
            class="h-6 w-6 text-gray-400"
            aria-hidden="true"
            :icon="item.icon"
          />
        </a>
      </div>
    </div>
  </footer>
</template>
