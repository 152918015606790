import { api } from './index';

const path = '/api/internal/phone_number_validations';


function create(phoneNumber: string) {
  return api({
    method: 'post',
    url: path,
    data: { phoneNumber },
  });
}

export default { create };
