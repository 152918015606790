import { ref, computed, readonly } from 'vue';
import { defineStore } from 'pinia';
import useUserStore from './user';

// eslint-disable-next-line max-statements
export default defineStore('group', () => {
  const userStore = useUserStore();

  const group = ref(null);
  const groupMembers = ref(null);
  const groupLoanApplication = ref(null);
  const groupPendingInvitations = ref(null);
  const groupMemberLoanApplications = ref(null);

  function setGroup(value) {
    group.value = value;
  }

  function setGroupMembers(members) {
    groupMembers.value = members;
  }

  function setMemberLoanApplications(applications) {
    groupMemberLoanApplications.value = applications;
  }

  function setGroupLoanApplication(applications) {
    groupLoanApplication.value = applications;
  }

  function setGroupPendingInvitations(invitations) {
    groupPendingInvitations.value = invitations;
  }

  const currentUserMember = computed(
    () => groupMembers.value.find(member => member.user.id === userStore.currentUser?.id),
  );

  const variables = {
    group,
    groupMembers,
    groupLoanApplication,
    currentUserMember,
    groupPendingInvitations,
    groupMemberLoanApplications,
  };

  const functions = {
    setGroup,
    setGroupMembers,
    setGroupLoanApplication,
    setGroupPendingInvitations,
    setMemberLoanApplications,
  };

  return {
    ...Object.entries(variables).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: readonly(value) }), {},
    ),
    ...functions,
  };
});
