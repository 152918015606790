<script setup>
import { DevicePhoneMobileIcon, CheckCircleIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from '@headlessui/vue';

const platforms = ['whatsapp', 'web'];
const selectedPlatform = ref(platforms[0]);
</script>

<template>
  <div class="flex w-full flex-col">
    <rails-flash-alerts />
    <p>
      Ahora debes realizar la solicitud individual de tu crédito. <b>Para esto te pedimos que selecciones
        por dónde deseas continuar la solicitud</b>:
    </p>
    <v-form-native-wrapper
      class="mt-6 flex w-full flex-col"
      action="/group_member_loan_application/platform"
      method="PATCH"
    >
      <input
        type="hidden"
        name="group_member_loan_application[selected_platform]"
        :value="selectedPlatform"
      >
      <RadioGroup
        v-model="selectedPlatform"
        class="flex flex-col gap-y-4"
      >
        <RadioGroupOption
          v-slot="{ active, checked }"
          as="template"
          value="whatsapp"
        >
          <div
            :class="[
              active
                ? 'border-primary-600 ring-2 ring-primary-600'
                : 'border-gray-300',
              'relative flex cursor-pointer flex-row gap-x-4 rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
            ]"
          >
            <div class="flex h-10 w-10 items-center justify-center rounded-full bg-green-200 p-2.5 transition duration-300 group-hover:scale-125">
              <base-icon
                class="h-full w-full text-green-400"
                icon="whatsapp"
              />
            </div>
            <span class="flex flex-1">
              <span class="flex flex-col">
                <RadioGroupLabel
                  as="span"
                  class="block text-sm font-medium text-gray-900"
                >
                  Whatsapp
                </RadioGroupLabel>
                <RadioGroupDescription
                  as="span"
                  class="mt-1 flex items-center text-sm text-gray-500"
                >
                  Te enviaremos un mensaje a tu Whatsapp para que puedas
                  continuar la solicitud.
                </RadioGroupDescription>
              </span>
            </span>
            <CheckCircleIcon
              :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-primary-600']"
              aria-hidden="true"
            />
            <span
              :class="[
                active ? 'border' : 'border-2',
                checked ? 'border-primary-600' : 'border-transparent',
                'pointer-events-none absolute -inset-px rounded-lg'
              ]"
              aria-hidden="true"
            />
          </div>
        </RadioGroupOption>

        <RadioGroupOption
          v-slot="{ active, checked }"
          as="template"
          value="web"
        >
          <div
            :class="[
              active
                ? 'border-primary-600 ring-2 ring-primary-600'
                : 'border-gray-300',
              'relative flex cursor-pointer flex-row gap-x-4 rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
            ]"
          >
            <div class="flex h-10 w-10 items-center justify-center rounded-full bg-blue-200 p-2.5 transition duration-300 group-hover:scale-125">
              <DevicePhoneMobileIcon class="h-full w-full text-blue-600" />
            </div>
            <span class="flex flex-1">
              <span class="flex flex-col">
                <RadioGroupLabel
                  as="span"
                  class="block text-sm font-medium text-gray-900"
                >
                  Plataforma web
                </RadioGroupLabel>
                <RadioGroupDescription
                  as="span"
                  class="mt-1 flex items-center text-sm text-gray-500"
                >
                  Podras rellenar un formulario en nuestra plataforma.
                </RadioGroupDescription>
              </span>
            </span>
            <CheckCircleIcon
              :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-primary-600']"
              aria-hidden="true"
            />
            <span
              :class="[
                active ? 'border' : 'border-2',
                checked ? 'border-primary-600' : 'border-transparent',
                'pointer-events-none absolute -inset-px rounded-lg'
              ]"
              aria-hidden="true"
            />
          </div>
        </RadioGroupOption>
      </RadioGroup>

      <base-button
        text="Continuar"
        type="submit"
        class="mt-6 w-full self-center sm:w-auto"
      />
    </v-form-native-wrapper>
  </div>
</template>
