import { api } from './index';

interface IUser {
  id: number;
  firstName: string;
  paternalSurname: string;
  maternalSurname: string;
  fullName: string;
  phoneNumber: string;
  birthDate: string | null;
  sex: 'male' | 'female';
  country: string;
  city: string;
  state: string;
  avatarUrl: string | null;
  removeAvatar: boolean;
}

function update(user: IUser) {
  const path = '/api/internal/current_user';

  return api({
    method: 'patch',
    url: path,
    data: { user },
  });
}

function updateAvatar(form: FormData) {
  const path = '/api/internal/current_user';

  return api({
    method: 'patch',
    url: path,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: form,
  });
}

export default { update, updateAvatar };
