<script setup>
import { toCurrency } from '@/utils/filters';

const props = defineProps({
  groupLoanApplication: { type: Object, required: true },
  groupMembers: { type: Array, required: true },
});

function findGroupMember(id) {
  return props.groupMembers.find(member => member.id === id);
}

function findGroupMemberFromGroupMemberLoanApplicationId(id) {
  const groupMemberLoanApplication = props.groupLoanApplication.memberLoanApplications.find(mla => mla.id === id);

  return findGroupMember(groupMemberLoanApplication.groupMemberId);
}

function formatMemberNames(ids) {
  return ids.map(id => findGroupMemberFromGroupMemberLoanApplicationId(id).user.fullName).join(', ');
}
</script>

<template>
  <div class="flex flex-col gap-y-4 rounded-lg bg-white py-6 px-4 sm:px-6 lg:px-8">
    <h1 class="text-base font-semibold text-gray-900">
      Consolidaciones del grupo
    </h1>
    <div class="flex flex-col gap-y-4">
      <div
        v-for="consolidatedLoan in groupLoanApplication.consolidatedLoans"
        :key="consolidatedLoan.groupMemberLoanApplicationIds.join('-')"
        class="flex flex-col gap-y-4 rounded-lg bg-gray-100 p-6"
      >
        <div class="flex flex-row justify-between">
          <div class="flex flex-col gap-y-1">
            <span class="text-sm font-semibold">
              {{ formatMemberNames(consolidatedLoan.groupMemberLoanApplicationIds) }}
            </span>
            <span class="text-sm">
              Ciclos: {{ consolidatedLoan.loans.length }}
            </span>
            <span class="text-sm">
              Fecha de apertura más reciente: {{ consolidatedLoan.mostRecentLoanStartDate }}
            </span>
            <span class="text-sm">
              Peor atraso: {{ consolidatedLoan.worstDelinquencyStrike }}
            </span>
          </div>
          <div v-if="consolidatedLoan.mostRecentDelinquentBalanceAmount > 0" class="flex flex-col gap-y-1">
            <span class="text-sm text-red-600 font-bold">
              Saldo vencido: {{ toCurrency(consolidatedLoan.mostRecentDelinquentBalanceAmount) }}
            </span>
            <span class="text-sm">
              Número de pagos vencidos: {{ consolidatedLoan.mostRecentDelinquentPaymentsCount }}
            </span>
            <span class="text-sm">
              Fecha de saldo vencido más reciente: {{ consolidatedLoan.mostRecentDelinquentBalanceDate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

