<script setup>
import { object, string } from 'yup';

defineProps({
  phoneNumber: { type: String, default: '' },
});

const validationSchema = object({
  'user[first_name]': string().required('Primer nombre es obligatorio'),
  'user[paternal_surname]': string().required('Apellido paterno es obligatorio'),
  'user[maternal_surname]': string().required('Apellido materno es obligatorio'),
  'user[phone_number]': string()
    .mobilePhone(['es-MX', 'es-CL'])
    .required('Número de teléfono es obligatorio'),
});
</script>

<template>
  <div class="container flex w-full max-w-lg flex-col items-center sm:mt-20">
    <v-form-native-wrapper
      class="flex w-full flex-col gap-y-8 rounded-xl bg-white px-4 py-6 sm:gap-y-12 sm:px-8"
      :validation-schema="validationSchema"
      action="/users"
      method="POST"
    >
      <base-image
        img-name="logo"
        alt="Logo de grupalia"
        class="my-4 h-12 w-auto fill-current text-black"
      />
      <h2 class="text-xl font-bold">
        Crear cuenta
      </h2>
      <rails-flash-alerts flash-alert-classes="mb-2 last:-mb-2" />
      <div class="flex flex-col gap-y-4">
        <base-short-text-input
          name="user[first_name]"
          label="Primer nombre"
          autocomplete="given-name"
        />
        <base-short-text-input
          name="user[paternal_surname]"
          label="Apellido paterno"
          autocomplete="family-name"
        />
        <base-short-text-input
          name="user[maternal_surname]"
          label="Apellido materno"
          autocomplete="additional-name"
        />
        <base-phone-input
          name="user[phone_number]"
          label="Número de teléfono"
          :value="phoneNumber"
        />
        <base-button
          text="Crear mi cuenta"
          type="submit"
          class="mt-6"
        />
        <span class="mt-2 self-center text-xs sm:text-sm">
          Ya tienes cuenta?
          <a
            class="text-blue-400 hover:opacity-75"
            href="/users/sign_in"
          >
            Inicia sesión acá
          </a>
        </span>
      </div>
    </v-form-native-wrapper>
  </div>
</template>
