<script setup>
import { ref } from 'vue';
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from '@headlessui/vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  group: { type: Object, required: true },
  groupInvitations: { type: Array, required: true },
});

const selected = ref(props.groupInvitations[0].id);
</script>

<template>
  <div class="container flex w-full max-w-xl flex-col items-center">
    <div
      class="flex w-full flex-row items-center gap-x-4 rounded-xl bg-white p-4 sm:gap-x-6 sm:p-6"
    >
      <base-icon-box
        :src="InformationCircleIcon"
        color="blue"
        size="sm"
      />
      <span class="text-xs font-bold sm:text-sm">
        Debes asignar a una Vicepresidenta para el grupo
      </span>
    </div>

    <div class="mt-10 w-full">
      <RadioGroup v-model="selected">
        <RadioGroupLabel class="sr-only">
          Privacy setting
        </RadioGroupLabel>
        <div class="-space-y-px rounded-md bg-white">
          <RadioGroupOption
            v-for="(invitation, idx) in groupInvitations"
            :key="invitation.id"
            v-slot="{ checked, active }"
            as="template"
            :value="invitation.id"
          >
            <div
              :class="[
                idx === 0 ? 'rounded-t-md' : '',
                idx === groupInvitations.length - 1
                  ? 'rounded-b-md'
                  : '',
                checked
                  ? 'z-10 border-primary-200 bg-primary-50'
                  : 'border-gray-200',
                'relative flex cursor-pointer border p-4 focus:outline-none',
              ]"
            >
              <span
                :class="[
                  checked
                    ? 'border-transparent bg-primary-600'
                    : 'border-gray-300 bg-white',
                  active ? 'ring-2 ring-primary-600 ring-offset-2' : '',
                  'mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border',
                ]"
                aria-hidden="true"
              >
                <span class="h-1.5 w-1.5 rounded-full bg-white" />
              </span>
              <span class="ml-3 flex flex-col">
                <RadioGroupLabel
                  as="span"
                  :class="[
                    checked ? 'text-gray-900' : 'text-gray-700',
                    'block text-sm font-medium',
                  ]"
                >
                  {{ invitation.invitee.firstName }}
                </RadioGroupLabel>
                <RadioGroupDescription
                  as="span"
                  :class="[
                    checked ? 'text-gray-900' : 'text-gray-500',
                    'block text-sm',
                  ]"
                >
                  {{ $t(`groupInvitation.relationship.${invitation.relationship}`) }}
                </RadioGroupDescription>
              </span>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
      <v-form-native-wrapper
        action="/group/roles"
        method="PATCH"
        class="mt-10 w-full"
      >
        <input
          v-for="invitation in groupInvitations"
          :key="invitation.id"
          :name="`group_invitation[${invitation.id}][role]`"
          :value="selected === invitation.id ? 'vice_president' : 'guest'"
          type="hidden"
        >
        <base-button
          text="Continuar"
          type="submit"
          class="w-full"
        />
      </v-form-native-wrapper>
    </div>
  </div>
</template>
