<script setup>
import { ref, onMounted, inject } from 'vue';
import { useEventBus } from '@vueuse/core';
import { mobileAppStoreUrlKey } from '../utils/keys.js';

const mobileAppStoreUrl = inject(mobileAppStoreUrlKey);

const steps = [
  {
    emoji: '🔄',
    title: '1. Haz o únete a tu grupo',
    description: 'Forma un grupo nuevo o invita a tu grupo actual a ' +
      'la plataforma para que hagan su solicitud.',
  },
  {
    emoji: '📱',
    title: '2. Completa tu solicitud digital',
    description: 'Cada miembro del grupo llena su información en su ' +
      'solicitud desde su celular.',
  },
  {
    emoji: '✅',
    title: '3. Recibe tu aprobación',
    description: 'Recibe tu aprobación, y todo el grupo firma un contrato ' +
    'electrónico desde su celular.',
  },
  {
    emoji: '👥',
    title: '4. Cóbralo en tu cuenta',
    description: 'Cada persona recibe el monto de su crédito a su cuenta bancaria al instante 🤝.',
  },
];

const elementToScrollTo = ref(null);
const scrollEventBus = useEventBus('scroll');

onMounted(() => {
  scrollEventBus.on((event) => {
    if (elementToScrollTo.value && event === 'how-it-works') {
      elementToScrollTo.value.scrollIntoView({ behavior: 'smooth' });
    }
  });
});
</script>

<template>
  <div
    ref="elementToScrollTo"
    class="container flex max-w-7xl flex-col items-center self-center px-4 py-16 sm:px-4 sm:py-32 md:px-12 lg:items-start"
  >
    <h2 class="self-center text-4xl font-bold">
      ¿Cómo funciona el trámite?
    </h2>
    <p class="mt-4 self-center text-lg text-gray-700">
      Con Grupalia puedes obtener un crédito grupal de forma 100% digital
    </p>
    <ul class="mt-12 grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-12">
      <li
        v-for="step in steps"
        :key="step.title"
        class="flex flex-col gap-y-4 rounded-lg bg-primary-50 p-4"
      >
        <span class="inline-flex text-xl">
          {{ step.emoji }}
          <h3 class="ml-4 font-semibold">
            {{ step.title }}
          </h3>
        </span>
        <p>
          {{ step.description }}
        </p>
      </li>
    </ul>
    <div class="mt-12 flex flex-col self-center md:mt-20">
      <base-button
        text="Empezar mi solicitud"
        class="w-full"
        :href="mobileAppStoreUrl"
      />
      <p class="mt-4 text-center text-xs text-gray-700">
        Escríbenos por
        <a
          href="https://wa.me/5213326303147"
          target="_blank"
          class="underline"
        >
          Whatsapp
        </a>
        si tienes alguna duda sobre nuestro proceso.
      </p>
    </div>
  </div>
</template>
