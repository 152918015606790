export function groupMemberLoanApplicationBadgeColor(aasmState) {
  switch (aasmState) {
  case 'draft':
    return 'gray';
  case 'submitted':
    return 'green';
  case 'in_kyc':
    return 'blue';
  case 'kyc_submitted':
    return 'green';
  case 'contract_signature_pending':
    return 'yellow';
  case 'contract_signed':
    return 'green';
  default:
    return 'gray';
  }
}
