<script setup>
import { ref, computed } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { formatDistanceToNow } from 'date-fns';
import { ArrowPathRoundedSquareIcon, TrashIcon, ClockIcon } from '@heroicons/vue/24/outline';
import BaseListBox from '@/components/base-list-box.vue';
import GroupLoanApplicationSummary from '@/components/reviews/group-loan-application-summary.vue';
import LoanApplicationPreApprovalSlideOver from
  '@/components/reviews/group-loan-application-pre-approval-slide-over.vue';
import groupLoanApplicationApi from '@/api/reviews/groupLoanApplication.ts';
import useUIStore from '@/stores/ui';

const emit = defineEmits(['refetch-group-loan-application']);
const props = defineProps({
  group: { type: Object, required: true },
  groupLoanApplication: { type: Object, required: true },
  previousGroupLoanApplications: { type: Array, required: true },
});

const uiStore = useUIStore();

const groupLoanApplicationBadgeColor = computed(() => {
  switch (props.groupLoanApplication.aasmState) {
  case 'inviting':
    return 'blue';
  case 'draft':
    return 'yellow';
  default:
    return 'gray';
  }
});

const president = computed(() => props.group.president);

const {
  mutate: editGroupLoanApplicationAasmState,
  isPending: isEditGroupLoanApplicationAasmStatePending,
} = useMutation({
  mutationFn: (aasmState) => groupLoanApplicationApi.update(props.groupLoanApplication.id, { aasmState }),
  onSuccess: () => {
    uiStore.toast({
      message: 'Solicitud actualizada',
      type: 'success',
      position: 'top',
    });

    emit('refetch-group-loan-application');
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response.data?.detail || 'Hubo un error al editar la solicitud',
      type: 'error',
      position: 'top',
    });
  },
});

const { mutate: fireAasmStateEvent, isPending: isFireAasmStateEventPending } = useMutation({
  mutationFn: (aasmStateEvent) => groupLoanApplicationApi.update(props.groupLoanApplication.id, {
    aasmStateEvent,
  }),
  onSuccess: (_, aasmStateEvent) => {
    const message = {
      'start_kyc': 'Solicitud enviada a KYC',
      'accept_kyc': 'KYC aprobado',
      'pass_application_to_review': 'Solicitud enviada a revisión',
      'reject_application': 'Solicitud rechazada',
    }[aasmStateEvent];

    uiStore.toast({
      message,
      type: 'success',
      position: 'top',
    });

    emit('refetch-group-loan-application');
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response.data?.detail || 'Hubo un error al editar la solicitud',
      type: 'error',
      position: 'top',
    });
  },
});

const listBoxOptions = [
  { name: 'Invitaciones', value: 'inviting' },
  { name: 'Draft', value: 'draft' },
  { name: 'Review', value: 'application_in_review' },
  { name: 'KYC', value: 'in_kyc' },
];

const currentListBoxOption = computed(() => listBoxOptions.find(
  (option) => option.value === props.groupLoanApplication.aasmState),
);

const preApprovalSlideOverOpen = ref(false);

function handlePreApproval() {
  preApprovalSlideOverOpen.value = false;
  emit('refetch-group-loan-application');
}

const rejectApplicationConfirmationModalOpen = ref(false);
function rejectApplication() {
  fireAasmStateEvent('reject_application');
  rejectApplicationConfirmationModalOpen.value = false;
}

const passApplicationToReviewConfirmationModalOpen = ref(false);
function passApplicationToReview() {
  fireAasmStateEvent('pass_application_to_review');
  passApplicationToReviewConfirmationModalOpen.value = false;
}
</script>

<template>
  <div class="flex w-full flex-col gap-y-4">
    <div class="flex flex-row items-center justify-between">
      <h1 class="text-lg font-bold">
        {{ group.name }}
      </h1>
      <div class="flex flex-row items-center gap-x-4">
        <base-badge
          v-if="group.promoter"
          :label="group.promoter.firstName"
          color="gray"
          class="self-center"
        />
        <base-list-box
          v-if="currentListBoxOption"
          :key="currentListBoxOption"
          class="w-44 rounded-md border border-gray-200"
          :options="listBoxOptions"
          :initial-selected="currentListBoxOption"
          :loading="isEditGroupLoanApplicationAasmStatePending"
          @update:model-value="(option) => editGroupLoanApplicationAasmState(option.value)"
        />
        <base-badge
          v-else
          :label="$t(`groupLoanApplication.aasmState.${groupLoanApplication.aasmState}`)"
          :color="groupLoanApplicationBadgeColor"
        />
        <button
          v-if="[
            'inviting',
            'draft',
            'application_in_review',
            'in_kyc',
            'kyc_in_review'
          ].includes(groupLoanApplication.aasmState)"
          class="flex items-center justify-center rounded-lg bg-red-500 p-2"
          @click="rejectApplicationConfirmationModalOpen = true"
        >
          <TrashIcon
            class="h-4 w-4 shrink-0 stroke-white"
          />
        </button>
      </div>
    </div>
    <div class="flex flex-col">
      <span class="text-sm text-gray-700">
        <b>ID grupo:</b> {{ group.publicId }}
      </span>
      <span class="text-sm text-gray-700">
        <b>ID solicitud:</b> {{ groupLoanApplication.id }}
      </span>
      <div
        v-if="groupLoanApplication.submittedAt"
        class="mt-2 flex flex-row items-center gap-x-1"
      >
        <ClockIcon
          class="h-4 w-4 text-primary-600/70"
        />
        <span class="text-sm text-gray-700">
          Solicitud enviada
          {{ formatDistanceToNow(new Date(groupLoanApplication.submittedAt), { addSuffix: true }) }}
        </span>
      </div>
    </div>
    <div
      v-if="groupLoanApplication.cycle > 1"
      class="flex flex-col gap-y-2"
    >
      <div class="flex flex-row items-center gap-x-1">
        <ArrowPathRoundedSquareIcon class="h-4 w-4" />
        <span class="text-sm">
          Renovación (ciclo {{ groupLoanApplication.cycle }})
        </span>
      </div>
      <ul class="flex flex-row items-center gap-x-2">
        <span>
          Ciclos anteriores:
        </span>
        <li
          v-for="prevGroupLoanApplication in previousGroupLoanApplications"
          :key="prevGroupLoanApplication.id"
          class="mt-[1px]"
        >
          <a
            :href="`/reviews/group_loan_applications/${prevGroupLoanApplication.id}`"
            target="_blank"
            class="text-blue-400 hover:opacity-75"
          >
            {{ prevGroupLoanApplication.cycle }}
          </a>
        </li>
      </ul>
    </div>
    <span>
      <b>nro presidenta: </b>
      <a
        :href="`https://wa.me/${president.phoneNumber}`"
        class="text-blue-500"
        target="_blank"
      >
        {{ president.phoneNumber }}
      </a>
    </span>
    <div class="flex flex-row items-center gap-x-2">
      <base-button
        v-if="!['draft', 'inviting'].includes(groupLoanApplication.aasmState)"
        :text="groupLoanApplication.aasmState === 'application_in_review'
          ? 'Pre aprobar grupo'
          : 'Revisar cálculos'"
        class="self-start"
        @click="preApprovalSlideOverOpen = true"
      />
      <base-button
        v-if="groupLoanApplication.aasmState === 'draft'"
        text="Pasar a revisión"
        class="self-start"
        :loading="isFireAasmStateEventPending"
        @click="() => passApplicationToReviewConfirmationModalOpen = true"
      />
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'application_in_review'"
        text="Pasar a KYC"
        class="self-start"
        :loading="isFireAasmStateEventPending"
        @click="() => fireAasmStateEvent('start_kyc')"
      />
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'kyc_in_review'"
        text="Aprobar KYC"
        :loading="isFireAasmStateEventPending"
        class="self-start"
        @click="() => fireAasmStateEvent('accept_kyc')"
      />
    </div>
    <div class="mt-8 px-4">
      <group-loan-application-summary
        :group-loan-application="groupLoanApplication"
        :group-members="group.members"
      />
    </div>
  </div>
  <loan-application-pre-approval-slide-over
    v-if="groupLoanApplication"
    :group-loan-application="groupLoanApplication"
    :open="preApprovalSlideOverOpen"
    @close="preApprovalSlideOverOpen = false"
    @pre-approved="handlePreApproval"
  />
  <base-modal
    :show="passApplicationToReviewConfirmationModalOpen"
    title="¿Estás seguro?"
    accept-button-text="Sí, pasar a revisión"
    cancel-button-text="Cancelar"
    :body="group.pendingInvitations.length > 0
      ? `Hay ${group.pendingInvitations.length} invitaciones pendientes. ¿Estás seguro que deseas pasar a revisión?`
      : null"
    @accept="passApplicationToReview"
    @close="passApplicationToReviewConfirmationModalOpen = false"
  />
  <base-modal
    :show="rejectApplicationConfirmationModalOpen"
    title="Rechazar solicitud"
    accept-button-text="Rechazar"
    accept-button-variant="red"
    cancel-button-text="Cancelar"
    @accept="rejectApplication"
    @close="rejectApplicationConfirmationModalOpen = false"
  />
</template>
