<template>
  <div class="relative z-0 flex h-screen w-full flex-col items-center overflow-hidden px-10">
    <h1 class="mt-20 text-center text-lg font-bold text-gray-700">
      Para continuar, descarga la app de Grupalia
    </h1>
    <a
      href="https://play.google.com/store/apps/details?id=com.creditogrupalia.app&hl=es"
      class="mt-10 flex flex-row items-center gap-x-4 rounded-lg bg-primary-600 px-6 py-4 text-white"
    >
      <base-image
        img-name="play-store"
        ext="png"
        class="h-5 w-5"
      />
      <span class="font-semibold">
        Descargar app
      </span>
    </a>
    <div class="absolute left-1/2 w-[300px] -translate-x-1/2" style="top: 16rem;">
      <base-image
        img-name="app-preview"
        ext="png"
      />
    </div>
  </div>
</template>
