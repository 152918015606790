<script setup>
import { ref } from 'vue';
import { object, bool } from 'yup';
import { DocumentTextIcon } from '@heroicons/vue/24/outline';
import CreateElectronicSignatureModal from '@/components/create-electronic-signature-modal.vue';
import BasePdfViewer from '@/components/base-pdf-viewer.vue';

defineProps({
  groupMemberLoanApplication: { type: Object, required: true },
  groupLoanApplication: { type: Object, required: true },
  group: { type: Object, required: true },
});

const signatureModalOpen = ref(false);

const validationSchema = object({
  'group_member_loan_application[contract_terms_accepted]': bool().oneOf([true], 'Debes aceptar los terminos del contrato.'),
  'group_member_loan_application[payment_agreement_accepted]': bool().oneOf([true], 'Debes aceptar ser solidario del resto.'),
});
</script>

<template>
  <div class="flex w-full flex-col">
    <base-back-button
      href="/"
    />
    <h1>
      <div class="flex flex-row items-center gap-x-4">
        <base-icon-box
          :src="DocumentTextIcon"
          color="light-blue"
          size="xs"
        />
        <span class="text-lg font-bold">
          Lee tu contrato
        </span>
      </div>
    </h1>
    <div class="mt-6 flex w-full max-w-full flex-col sm:mt-10">
      <base-pdf-viewer
        :url="groupMemberLoanApplication.unsignedContractUrl"
      />
    </div>
    <div class="mt-10 flex flex-col gap-y-6 rounded-lg border border-gray-300 bg-white px-4 py-6">
      <span class="text-sm font-bold text-primary-600">
        Información de tu crédito
      </span>
      <div class="flex flex-col gap-y-6">
        <div class="flex flex-col gap-y-1">
          <span class="text-sm font-medium text-gray-700">
            💰 Monto otorgado
          </span>
          <span class="font-semibold">
            {{ $filters.toCurrency(groupMemberLoanApplication.acceptedAmount) }}
          </span>
        </div>
        <div class="flex flex-col gap-y-1">
          <span class="text-sm font-medium text-gray-700">
            📌 Pago semanal
          </span>
          <span class="text-lg font-medium">
            {{ $filters.toCurrency(groupMemberLoanApplication.repaymentAmount) }}
          </span>
        </div>
        <div class="flex flex-col gap-y-1">
          <span class="text-sm font-medium text-gray-700">
            📤 Total a pagar
          </span>
          <span class="text-lg font-medium">
            {{ $filters.toCurrency(groupMemberLoanApplication.totalRepaymentAmount) }}
          </span>
        </div>
        <div class="flex flex-col gap-y-1">
          <span class="text-sm font-medium text-gray-700">
            🫰 Costo por mil
          </span>
          <span class="text-lg font-medium">
            {{ $filters.toCurrency(groupMemberLoanApplication.costPerThousand) }}
          </span>
        </div>
        <div class="flex flex-col gap-y-1">
          <span class="text-sm font-medium text-gray-700">
            🚨  Multas grupales por atraso
          </span>
          <span class="font-semibold">
            {{ $filters.toCurrency(groupLoanApplication.delinquentFees.firstDay) }} el primer día,
            luego {{ $filters.toCurrency(groupLoanApplication.delinquentFees.daily) }}
            por cada día adicional de atraso.
          </span>
        </div>
      </div>
      <v-form
        v-slot="{ meta }"
        :validation-schema="validationSchema"
        class="flex flex-col gap-y-2"
        @submit="signatureModalOpen = true"
      >
        <base-checkbox
          name="group_member_loan_application[contract_terms_accepted]"
          label="He leído y Acepto todos los términos de la Carátula del Contrato
                de Adhesión de Crédito, el Acta de Instalación de Grupo Solidario,
                Reglamento Interno del Grupo Solidario, el Contrato de Adhesión de
                Crédito y el Pagaré."
        />
        <base-checkbox
          name="group_member_loan_application[payment_agreement_accepted]"
          label="Me comprometo a pagar a tiempo, ser solidaria con mi grupo y ser
                aval de cada integrante."
        />
        <base-button
          type="submit"
          class="mt-10 w-full"
          text="Ir a firma"
          :disabled="!meta.valid"
        />
      </v-form>
    </div>
  </div>
  <create-electronic-signature-modal
    :show="signatureModalOpen"
    :group="group"
    @close="signatureModalOpen = false"
  />
</template>
