<script setup>
import { ref, computed, inject } from 'vue';
import { useEventBus } from '@vueuse/core';
import { mobileAppStoreUrlKey } from '@/utils/keys.js';

const mobileAppStoreUrl = inject(mobileAppStoreUrlKey);

const scrollEventBus = useEventBus('scroll');

function scrollToFeatures() {
  scrollEventBus.emit('features');
}

const menuOpen = ref(false);
</script>

<template>
  <nav class="container relative flex w-full items-center  justify-between border-gray-200 p-4 sm:py-8">
    <div
      v-if="menuOpen"
      class="absolute inset-x-0 top-20 z-50 mx-4 flex flex-col items-center gap-y-8 rounded-xl bg-primary-600 py-12 text-white"
    >
      <ul>
        <li>
          <button
            class="text-xl font-bold text-white"
            @click="scrollToFeatures"
          >
            Cómo funciona
          </button>
        </li>
      </ul>
      <base-button
        text="Empezar solicitud"
        variant="white"
        :href="mobileAppStoreUrl"
      />
    </div>
    <base-image
      img-name="logo"
      alt="Logo de grupalia"
      class="h-10 w-auto fill-current text-black"
    />
    <div class="flex flex-row items-center gap-x-8">
      <div class="hidden flex-row md:flex">
        <ul class="self-start">
          <li>
            <button
              class="text-xl font-bold text-primary-600"
              @click="scrollToFeatures"
            >
              Cómo funciona
            </button>
          </li>
        </ul>
      </div>
      <button
        data-collapse-toggle="navbar-default"
        type="button"
        class="inline-flex h-10 w-10 items-center justify-center rounded-lg p-2 text-sm text-primary-600  focus:outline-none focus:ring-2 focus:ring-primary-600 md:hidden"
        aria-controls="navbar-default"
        aria-expanded="false"
        @click="menuOpen = !menuOpen"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="h-5 w-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
      <base-button
        text="Empezar solicitud"
        class="hidden md:block"
        :href="mobileAppStoreUrl"
      />
    </div>
  </nav>
</template>
