<script setup>
import { ref, computed, inject } from 'vue';
import { currentUserKey } from '@/utils/keys.js';
import BaseModal from '@/components/base-modal.vue';

defineEmits(['close']);
defineProps({
  show: { type: Boolean, default: false },
  group: { type: Object, required: true },
});

const currentUser = inject(currentUserKey);
const fullName = ref(null);

function removeAccents(str) {
  return str ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : null;
}

const isFullNameMatch = computed(() => {
  const normalizedFullName = removeAccents(fullName.value?.replace(/\s+/g, '').toLowerCase());
  const normalizedCurrentUserName = removeAccents(currentUser.value.fullName.replace(/\s+/g, '').toLowerCase());

  return normalizedFullName === normalizedCurrentUserName;
});
</script>

<template>
  <base-modal
    hide-actions
    :show="show"
    :close-on-outside-click="false"
    @close="$emit('close')"
  >
    <template #title>
      <h1 class="mx-10">
        Firma el contrato escribiendo tu nombre completo
      </h1>
    </template>
    <template #body>
      <v-form-native-wrapper
        class="mt-4 flex w-full flex-col items-center gap-y-8 text-left"
        action="/group_member_loan_application/signature"
        method="POST"
      >
        <input
          name="group_member_loan_application[contract_terms_accepted]"
          class="hidden"
          :value="true"
        >
        <input
          name="group_member_loan_application[payment_agreement_accepted]"
          class="hidden"
          :value="true"
        >
        <p class="text-xs text-gray-600">
          A través de mi nombre <b>{{ currentUser.fullName }}</b> manifiesto que he leído y doy mi consentimiento
          absoluto a los términos del Contrato de Adhesión de Crédito de <b>{{ group.name }}</b> el Pagaré
          y sus anexos.
          <br>
          <br>
          Estoy consciente que de acuerdo con El Código Civil Federal, el
          consentimiento expresado por medios electrónicos, como la firma digital a través de
          MI NOMBRE COMPLETO y la verificación de mi identidad, es expreso, por lo que tiene la
          misma validez legal que una firma manuscrita y permite realizar acciones
          legales vinculantes en línea.
        </p>
        <base-short-text-input
          v-model="fullName"
          name="full_name"
          :description="`Escribe tu nombre completo: <b>${currentUser.fullName}</b>`"
        />

        <base-button
          v-tooltip="{
            content: isFullNameMatch ? '' : 'El nombre que ingresaste no coincide con tu nombre completo.',
            triggers: ['hover'],
            placement: 'bottom',
            autoHide: true,
          }"
          text="Firmar contrato"
          size="sm"
          type="submit"
          :disabled="!isFullNameMatch"
        />
      </v-form-native-wrapper>
    </template>
  </base-modal>
</template>
