import { reactive, onBeforeMount, onBeforeUnmount } from 'vue';

const SM_SCREEN = 640;
const MD_SCREEN = 768;
const LG_SCREEN = 1024;
const XL_SCREEN = 1280;

export default function useScreen() {
  const screen = reactive({
    isMobile: window.innerWidth < SM_SCREEN,
    isTablet: window.innerWidth >= SM_SCREEN && window.innerWidth < MD_SCREEN,
    isMdScreen: window.innerWidth >= MD_SCREEN && window.innerWidth < LG_SCREEN,
    isLgScreen: window.innerWidth >= LG_SCREEN && window.innerWidth < XL_SCREEN,
    isXlScreen: window.innerWidth >= XL_SCREEN,
    width: window.innerWidth,
  });

  function setScreenSizes() {
    screen.isMobile = window.innerWidth < SM_SCREEN;
    screen.isTablet = window.innerWidth >= SM_SCREEN && window.innerWidth < MD_SCREEN;
    screen.isMdScreen = window.innerWidth >= MD_SCREEN && window.innerWidth < LG_SCREEN;
    screen.isLgScreen = window.innerWidth >= LG_SCREEN && window.innerWidth < XL_SCREEN;
    screen.isXlScreen = window.innerWidth >= XL_SCREEN;
    screen.width = window.innerWidth;
  }

  onBeforeMount(() => {
    setScreenSizes();
    window.addEventListener('resize', setScreenSizes);
  });

  onBeforeUnmount(() => window.removeEventListener('resize'));

  return {
    screen,
  };
}
