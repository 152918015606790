<script setup>
import BaseFileInput from '@/components/base-file-input.vue';
import useFilteredAttrs from '@/composables/useFilteredAttrs.ts';

defineProps({
  previewImgName: { type: String, required: true },
  previewImgExt: { type: String, default: 'jpg' },
});

const filteredAttrs = useFilteredAttrs({ excluded: ['preview-img-name', 'preview-img-ext'] });
</script>

<template>
  <div class="flex flex-col gap-y-4 bg-white p-4">
    <div class="flex w-full flex-col gap-y-2">
      <span>
        Así debe verse la foto:
      </span>
      <div class="overflow-hidden rounded-md sm:rounded-lg">
        <base-image
          :img-name="previewImgName"
          :ext="previewImgExt"
        />
      </div>
    </div>
    <div class="flex flex-col gap-y-0">
      <span>
        Sube tu foto:
      </span>
      <base-file-input
        v-bind="filteredAttrs"
      />
    </div>
  </div>
</template>
