<script setup>
import { ref } from 'vue';
import { UserIcon } from '@heroicons/vue/24/outline';
import { object, string } from 'yup';
import usePhoneNumberValidation from '@/composables/usePhoneNumberValidation';

const props = defineProps({
  groupMemberLoanApplication: { type: Object, required: true },
  references: { type: Array, default: () => [] },
});

const MINIMUM_REFERENCES = 2;

const addReferenceEnabled = ref(props.references.length < MINIMUM_REFERENCES);

const textFieldSchema = string().required('Este campo es obligatorio');
const phoneNumberSchema = string()
  .mobilePhone(['es-MX', 'es-CL'])
  .required('Número de teléfono es obligatorio');

const validationSchema = object().shape({
  'reference[name]': textFieldSchema,
  'reference[phone_number]': phoneNumberSchema,
  'reference[relationship]': textFieldSchema,
});

const {
  state: phoneNumberValidationState,
  validate: validatePhoneNumber,
} = usePhoneNumberValidation();

const formRef = ref(null);
function performPhoneValidation() {
  formRef.value.vFormRef.innerRef.validateField('reference[phone_number]').then(({ valid }) => {
    if (valid) {
      const fields = formRef.value.vFormRef.innerRef.getValues();
      validatePhoneNumber(fields['reference[phone_number]']);
    }
  });
}
</script>

<template>
  <div class="flex w-full flex-col">
    <base-back-button
      href="/"
    />
    <rails-flash-alerts />
    <p class="mt-2 text-sm text-gray-600">
      Te pediremos <b>al menos 2 referencias</b> personales. Puede ser algún familiar, amigo cercano
      o vecino, pero no debe ser alguien del grupo. Tampoco pueden repetirse referencias
      dentro del grupo.
      <br>
      <br>
      Solo usaremos sus datos para
      contactarlos en caso de que no podamos comunicarnos contigo.
    </p>
    <div
      v-if="references.length > 0"
      class="-mb-6 mt-8 grid grid-cols-1 gap-4 sm:-mb-4 sm:grid-cols-2"
    >
      <div
        v-for="reference in references"
        :key="reference.id"
        class="flex flex-row gap-x-2"
      >
        <user-icon
          class="h-8 w-auto text-gray-400"
        />
        <div class="flex flex-col">
          <span class="text-sm font-medium">
            {{ reference.name }}
          </span>
          <span class="mt-1 text-sm text-gray-600">
            {{ reference.phoneNumber }}
          </span>
          <v-form-native-wrapper
            :action="`/group_member_loan_application/kyc_references/${reference.id}`"
            method="DELETE"
          >
            <button
              type="submit"
              class="mt-1 text-left text-xs text-primary-500 hover:text-red-600"
            >
              Eliminar
            </button>
          </v-form-native-wrapper>
        </div>
      </div>
    </div>
    <v-form-native-wrapper
      v-if="addReferenceEnabled"
      v-slot="{ values }"
      ref="formRef"
      class="mt-4 flex flex-col gap-y-2"
      :validation-schema="validationSchema"
      action="/group_member_loan_application/kyc_references"
    >
      <base-short-text-input
        label="Nombre de la referencia"
        name="reference[name]"
        :class="{ 'mt-8': references.length > 0 }"
        :validate-on-blur="false"
      />
      <base-phone-input
        label="Teléfono de la referencia"
        name="reference[phone_number]"
        :validating="phoneNumberValidationState.loading"
        :validated="phoneNumberValidationState.validated"
        @blur="performPhoneValidation"
        @input="phoneNumberValidationState.loading = false"
      />
      <base-native-select
        name="reference[relationship]"
        :label="`¿Qué relación tienes con ${values['reference[name]'] || 'esta persona'}?`"
        :options="[
          { label: 'Familiar cercano (hermana/a,  madre, hija/o, padre) ', value: 'close_relative' },
          { label: 'Familiar segundo (prima/o, tía, abuela/o)', value: 'distant_relative' },
          { label: 'Familiar político (cuñada/o, suegra/o, yerna/o)', value: 'in_law_relative' },
          { label: 'Pareja (esposo/a, novio/a)', value: 'partner' },
          { label: 'Amistad', value: 'friend' },
          { label: 'Vecino/a', value: 'neighbor' },
          { label: 'Conocido/a', value: 'acquaintance' },
        ]"
      />
      <base-button
        type="submit"
        :text="phoneNumberValidationState.loading ? 'Validando número...' : 'Guardar referencia'"
        variant="white"
        class="mt-8 w-full"
        :disabled="!phoneNumberValidationState.validated"
      />
    </v-form-native-wrapper>
    <base-button
      v-if="!addReferenceEnabled"
      type="button"
      variant="white"
      text="Quiero agregar otra referencia"
      class="mt-12 self-start"
      @click="addReferenceEnabled = true"
    />
    <v-form-native-wrapper
      v-if="!addReferenceEnabled"
      action="/group_member_loan_application/kyc_submission"
      class="mt-10"
    >
      <base-button
        type="submit"
        text="Enviar"
        class="w-full"
      />
    </v-form-native-wrapper>
  </div>
</template>
