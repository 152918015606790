<script setup>
import { watch, inject } from 'vue';
import { object, string, bool } from 'yup';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useMutation } from '@tanstack/vue-query';
import useUIStore from '@/stores/ui';
import { currentUserKey, landingOriginKey } from '@/utils/keys.js';
import groupMemberLoanApplicationApi from '@/api/groupMemberLoanApplication.ts';

const emit = defineEmits(['resend-code', 'submit']);
const props = defineProps({
  show: { type: Boolean, required: true },
  groupMemberLoanApplication: { type: Object, required: true },
});

const currentUser = inject(currentUserKey);
const landingUrl = inject(landingOriginKey);
const uiStore = useUIStore();

const {
  mutate: sendCode,
  isPending: isSendCodePending,
} = useMutation({
  mutationFn: () => groupMemberLoanApplicationApi.createAuthorizationCode(),
  onSuccess: () => {
    uiStore.toast({
      id: 'code-authorization-code-sent',
      message: `Código enviado a ${currentUser.value.phoneNumber}`,
      type: 'success',
      duration: 5000,
      position: 'top-right',
    });
  },
});

const {
  mutate: verify,
  isPending: isVerifyPending,
  isSuccess: isVerifySuccess,
  isError: isVerifyError,
} = useMutation({
  mutationFn: (code) => groupMemberLoanApplicationApi.createAuthorizationCodeValidation(code),
  onSuccess: () => emit('submit'),
});

watch(() => props.show, (newValue) => {
  if (newValue) {
    sendCode();
  }
}, { immediate: true });

function handleSubmit(values) {
  if (isVerifySuccess.value) {
    emit('submit');
  } else {
    verify(values.code);
  }
}

const validationSchema = object({
  'code': string().required('Debes ingresar el código que te enviamos a tu Whatsapp.'),
  'operationsAffirmation': bool().oneOf([true], 'Debes aceptar la afirmación de operaciones.'),
  'creditConsultationConsent': bool().oneOf([true], 'Debes aceptar el consentimiento de consulta de crédito.'),
});

</script>

<template>
  <base-modal
    hide-actions
    :show="show"
  >
    <template #title>
      <h1 class="font-bold">
        Autorización de consulta de historial crediticio
      </h1>
    </template>
    <template #body>
      <v-form
        class="mt-10 flex w-full flex-col"
        :validation-schema="validationSchema"
        @submit="handleSubmit"
      >
        <h2 class="text-left text-sm font-semibold">
          1. Acepta los términos y condiciones
        </h2>
        <base-checkbox
          name="operationsAffirmation"
          class="mt-4 text-left"
        >
          <template #label>
            <label for="operationsAffirmation">
              Acepto los
              <a
                :href="`${landingUrl}/terminos`"
                class="text-blue-500 hover:opacity-75"
                target="_blank"
              >
                Términos y Condiciones
              </a> y cláusula de medios electrónicos tales como NIP.
            </label>
          </template>
        </base-checkbox>
        <h2 class="mt-10 text-left text-sm font-semibold">
          2. Autoriza la consulta de tu historial crediticio
        </h2>
        <p class="mt-2 h-40 overflow-y-scroll rounded-lg border border-gray-300 bg-gray-50 px-3 py-5 text-left text-xs text-gray-700 sm:text-sm">
          Por este conducto autorizo expresamente a XYZ PAGOS SA DE CV, para que por conducto de sus funcionarios
          facultados lleve a cabo Investigaciones, sobre mi comportamiento Crediticio en las Sociedades de Información Crediticia que estime conveniente. Así mismo, declaro
          que conozco la naturaleza y alcance de la información que se solicitará, del uso que XYZ PAGOS SA DE CV hará de tal información y de que ésta podrá realizar
          consultas periódicas de mi historial crediticio, consintiendo que esta autorización se encuentre vigente por un período de 3 años contados a partir de la fecha
          de su expedición y en todo caso durante el tiempo que mantengamos relación jurídica. Estoy consciente y acepto que este documento quede bajo propiedad de
          XYZ PAGOS SA DE CV y/o Sociedad de Información Crediticia Consultada para efectos de control y cumplimiento del artículo 28 de la Ley para Regular a
          Las Sociedades de Información Crediticia.
        </p>
        <base-checkbox
          name="creditConsultationConsent"
          label="Afirmo que para los efectos de realización de las operaciones con
                GRUPALIA declaro que estoy actuando por cuenta propia."
          class="mt-2"
        />
        <h2 class="mt-10 text-left text-sm font-semibold">
          3. Ingresa el NIP
        </h2>
        <span class="mt-2 text-left text-sm">
          Te enviamos el NIP por Whatsapp a <b>{{ currentUser.phoneNumber }}</b>, ingrésalo para continuar.
        </span>
        <base-short-text-input
          label="Ingresa tu código de autorización"
          name="code"
          :error="isVerifyError ? 'El código es incorrecto.' : null"
          class="mt-4 text-left"
        />
        <div class="mt-2 flex flex-row items-center gap-x-4">
          <span
            class="text-xs text-gray-700 sm:text-sm"
          >
            ¿No te ha llegado el NIP?
          </span>
          <base-button
            :left-icon="ArrowPathIcon"
            text="Reenviar"
            size="sm"
            variant="white"
            type="button"
            :loading="isSendCodePending"
            @click="sendCode"
          />
        </div>
        <base-button
          text="Enviar"
          type="submit"
          class="mt-10"
          :loading="isVerifyPending"
        />
      </v-form>
    </template>
  </base-modal>
</template>
