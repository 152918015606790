<script setup>
import { computed } from 'vue';
import useUIStore from '@/stores/ui.js';

const { banner } = useUIStore();

const containerClasses = computed(() => {
  const colorClasses = {
    white: 'bg-white',
  };

  const positionClasses = {
    top: 'top-12',
    bottom: 'bottom-0',
  };

  return `${colorClasses[banner.color]} ${positionClasses[banner.position]}`;
});

const messageClasses = computed(() => {
  const colorClasses = {
    white: 'text-black',
  };

  return colorClasses[banner.color];
});
</script>
<template>
  <div
    class="absolute z-50 mt-1 flex w-full flex-col items-center px-12 py-4"
    :class="containerClasses"
  >
    <component
      :is="banner.component"
      v-if="banner.component"
    />
    <slot v-else>
      <span
        class="font-medium"
        :class="messageClasses"
      >
        {{ banner.message }}
      </span>
    </slot>
  </div>
</template>
