<script setup>
import { computed } from 'vue';

const props = defineProps({
  color: {
    type: String,
    default: 'green',
    validator: (value) => ['blue', 'cyan', 'light-blue', 'green', 'red', 'yellow'].includes(value),
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['xs', 'sm', 'md', 'lg'].includes(value),
  },
  src: { type: [String, Function], required: true },
  alt: { type: String, default: null },
});

const boxColorClasses = computed(() => {
  switch (props.color) {
  case 'blue':
    return 'bg-blue-50';
  case 'cyan':
    return 'bg-cyan-600';
  case 'light-blue':
    return 'bg-cyan-300';
  case 'green':
    return 'bg-green-200';
  case 'red':
    return 'bg-red-50';
  case 'yellow':
  default:
    return 'bg-yellow-50';
  }
});

const boxSizeClasses = computed(() => {
  switch (props.size) {
  case 'xs':
    return 'w-8 h-8 p-[6px] rounded-md';
  case 'sm':
    return 'w-10 h-10 p-2.5 rounded-lg';
  case 'lg':
    return 'w-16 h-16 p-4 rounded-xl';
  case 'md':
  default:
    return 'w-12 h-12 p-3 rounded-lg';
  }
});

const iconColor = computed(() => {
  switch (props.color) {
  case 'blue':
    return 'text-blue-500';
  case 'cyan':
    return 'text-white';
  case 'light-blue':
    return 'text-cyan-700';
  case 'green':
    return 'text-green-700';
  case 'red':
    return 'text-red-700';
  case 'yellow':
  default:
    return 'text-yellow-700';
  }
});

</script>

<template>
  <div :class="[boxColorClasses, boxSizeClasses, 'flex shrink-0 items-center justify-center']">
    <base-image
      v-if="typeof src === 'string'"
      class="h-full w-full"
      :class="iconColor"
      aria-hidden="true"
      :src="src"
      :alt="alt"
    />
    <component
      :is="src"
      v-else
      class="h-full w-full"
      :class="iconColor"
      :alt="alt"
      aria-hidden="true"
    />
  </div>
</template>
