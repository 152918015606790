<script setup>
import { object, string } from 'yup';
import { textFieldSchema, numberFieldSchema } from '@/utils/yupRules';

defineEmits([
  'submit',
  'update:employee',
  'update:business-type',
  'update:dependents',
  'update:how-long-ago-business-started',
  'update:income',
  'update:expenses',
  'update:payment-abilities',
]);

defineProps({
  employee: { type: String, default: '' },
  businessType: { type: String, default: '' },
  dependents: { type: Number, default: null },
  howLongAgoBusinessStarted: { type: String, default: '' },
  income: { type: Number, default: null },
  expenses: { type: Number, default: null },
  paymentAbilities: { type: Number, default: null },
});

const validationSchema = object().shape({
  employee: textFieldSchema,
  businessType: string(),
  dependents: numberFieldSchema,
  howLongAgoBusinessStarted: textFieldSchema,
  income: numberFieldSchema,
  expenses: numberFieldSchema,
  paymentAbilities: numberFieldSchema,
});
</script>

<template>
  <v-form
    :validation-schema="validationSchema"
    @submit="$emit('submit')"
  >
    <base-form-section title="Información de tu negocio">
      <base-native-select
        :model-value="employee"
        name="employee"
        label="¿Actualmente tienes un trabajo donde seas empleado?"
        :options="[
          { label: 'Sí', value: 'yes' },
          { label: 'No', value: 'no' },
        ]"
        @update:model-value="$emit('update:employee', $event)"
      />
      <base-short-text-input
        :model-value="businessType"
        name="businessType"
        label="Si es que tienes un negocio propio, ¿de qué es tu negocio?"
        @update:model-value="$emit('update:business-type', $event)"
      />
      <base-native-select
        :model-value="dependents"
        name="dependents"
        label="¿Tienes dependientes económicos?, ¿cuántos?"
        :options="[
          { label: 'No', value: 0 },
          { label: 'Sí, 1', value: 1 },
          { label: 'Sí, 2', value: 2 },
          { label: 'Sí, 3', value: 3 },
          { label: 'Sí, 4 o más', value: 4 },
        ]"
        description="Personas que dependen económicamente de ti como hijos, familiares u otros."
        @update:model-value="$emit('update:dependents', $event)"
      />
      <base-native-select
        :model-value="howLongAgoBusinessStarted"
        name="howLongAgoBusinessStarted"
        label="¿Cuánto tiempo llevas con el negocio?"
        :options="[
          { label: 'Apenas voy empezando', value: 'just_starting' },
          { label: 'Menos de 6 meses', value: 'less_than_six_months' },
          { label: 'Menos de 1 año', value: 'less_than_one_year' },
          { label: '1 a 2 años', value: 'one_to_two_years' },
          { label: '2 a 3 años', value: 'two_to_three_years' },
          { label: '3 a 5 años', value: 'three_to_five_years' },
          { label: '5 a 10 años', value: 'five_to_ten_years' },
          { label: 'Más de 10 años', value: 'ten_years' },
        ]"
        description="Personas que dependen económicamente de ti como hijos, familiares u otros."
        @update:model-value="$emit('update:how-long-ago-business-started', $event)"
      />
      <base-short-text-input
        :model-value="income"
        name="income"
        label="¿Approx. cuánto son tus ingresos al mes?"
        type="number"
        @update:model-value="$emit('update:income', $event)"
      />
      <base-short-text-input
        :model-value="expenses"
        name="expenses"
        label="¿Approx. cuánto son tus gastos al mes?"
        description="Incluye renta, comida, transporte, etc."
        type="number"
        @update:model-value="$emit('update:expenses', $event)"
      />
      <base-short-text-input
        :model-value="paymentAbilities"
        name="paymentAbilities"
        label="¿Cuánto es el pago que podrías hacer a la semana para un crédito?"
        type="number"
        @update:model-value="$emit('update:payment-abilities', $event)"
      />
    </base-form-section>
    <base-button
      type="submit"
      text="Siguiente"
      class="mt-12 w-full"
    />
  </v-form>
</template>
