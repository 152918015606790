<script setup>
import { ref } from 'vue';

const props = defineProps({
  tabTitles: { type: Array, required: true },
  selectedTabIndex: { type: Number, required: true },
});

const selectedTabIndex = ref(props.selectedTabIndex || 0);
</script>

<template>
  <div>
    <div class="sm:hidden">
      <select
        id="tabs"
        v-model="selectedTabIndex"
        name="tabs"
        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
      >
        <option
          v-for="(tab, index) in tabTitles"
          :key="index"
          :value="index"
        >
          {{ tab }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="mb-4 border-b border-gray-200">
        <nav
          class="-mb-px flex space-x-8"
          aria-label="Tabs"
        >
          <a
            v-for="(tab, index) in tabTitles"
            :key="index"
            :class="[
              selectedTabIndex === index ?
                'border-primary-500 text-primary-600' :
                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
            ]"
            @click.prevent="selectedTabIndex = index"
          >
            {{ tab }}
          </a>
        </nav>
      </div>
    </div>
    <div class="tab-content">
      <slot :name="`tab-${selectedTabIndex}`" />
    </div>
  </div>
</template>
