import { v4 as uuidv4 } from 'uuid';
import { ref, reactive, readonly, markRaw } from 'vue';
import { defineStore } from 'pinia';

const DEFAULT_BANNER = {
  isVisible: false,
  message: '',
  color: 'white',
  position: 'top',
  component: null,
};

const DEFAULT_TOAST = {
  message: '',
  type: 'info',
  position: 'top-right',
  component: null,
  duration: 5000,
  icon: null,
};

const REPEATED_TOAST_DELAY_MS = 250;

const DEFAULT_SLIDEOVER_WIDTH = 400;

export default defineStore('ui', () => {
  const banner = reactive({ ...DEFAULT_BANNER });
  function setBanner(options) {
    Object.assign(banner, {
      component: options.component && markRaw(options.component),
      ...DEFAULT_BANNER,
      ...options,
    });
  }

  const toasts = ref([]);
  function addToast(options) {
    toasts.value = toasts.value.filter(t => t.id !== options.id);
    const newToast = { key: uuidv4(), id: options.id, ...DEFAULT_TOAST, ...options };
    toasts.value.unshift(newToast);
  }
  function toast(options = {}) {
    const id = options.id || uuidv4();

    if (toasts.value.some(t => t.id === options.id)) {
      toasts.value = toasts.value.filter(t => t.id !== options.id);
      setTimeout(() => addToast({ id, ...options }), REPEATED_TOAST_DELAY_MS);
    } else {
      addToast({ id, ...options });
    }

    if (!options.persist) {
      setTimeout(
        () => (toasts.value = toasts.value.filter((t) => t.id !== id)),
        options.duration || DEFAULT_TOAST.duration,
      );
    }
  }

  function closeToast(id) {
    toasts.value = toasts.value.filter(t => t.id !== id);
  }

  // const isSlideOverOpen = useLocalStorage('ui/slide-over-open', false);
  // const slideOverOpenWidth = useLocalStorage('ui/slide-over-open-width', DEFAULT_SLIDEOVER_WIDTH);

  return {
    banner: readonly(banner),
    setBanner,
    toast,
    toasts,
    closeToast,
    // isSlideOverOpen,
    // slideOverOpenWidth,
  };
});
