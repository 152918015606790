import { ref, watch, computed } from 'vue';
import { useLocalStorage } from '@vueuse/core';

// eslint-disable-next-line import/no-default-export
export default function useMultiStepForm(schema, storageKey, stepsSections) {
  const currentStep = useLocalStorage(`${storageKey}-currentStep`, 0);
  const storedForm = useLocalStorage(`${storageKey}-form`, JSON.parse(JSON.stringify(schema)));
  const higherCurrentStep = useLocalStorage(`${storageKey}-higherCurrentStep`, 0);

  const currentSections = ref([]);

  watch(currentStep, (step) => {
    currentSections.value = stepsSections[step];

    // Save the current step as the higher current step if it's
    // higher than the current one
    if (step > higherCurrentStep.value) {
      higherCurrentStep.value = step;
    }
  }, { immediate: true });

  function moveToNextStep() {
    if (currentStep.value < stepsSections.length - 1) {
      currentStep.value += 1;
    }
  }

  function moveToPreviousStep() {
    if (currentStep.value > 0) {
      currentStep.value -= 1;
    }
  }

  function moveToStep(idx) {
    currentStep.value = idx;
  }

  return {
    currentStep,
    isLastStep: computed(() => currentStep.value === stepsSections.length - 1),
    storedForm,
    currentSections,
    moveToNextStep,
    moveToPreviousStep,
    moveToStep,
    higherCurrentStep,
  };
}

