<script setup>
import { ref } from 'vue';
import { object, string } from 'yup';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import BaseModal from '@/components/base-modal.vue';

defineProps({
  groupMemberLoanApplication: { type: Object, required: true },
});

const showModal = ref(false);
const formRef = ref(null);

function handleSubmit() {
  formRef.value.vFormRef.innerRef.validate().then(({ valid }) => {
    if (!valid) return;

    const { owner } = formRef.value.vFormRef.innerRef.getValues();

    if (owner) {
      formRef.value.innerFormRef.submit();
    } else {
      showModal.value = true;
    }
  });
}

const CLABE_LENGTH = 18;

const validationSchema = object({
  'group_member_loan_application[bank_account_number]': string()
    .required('Requerido')
    .matches(/^\d+$/, 'La CLABE debe contener solo números.')
    .test(
      'not-start-with-646',
      `No aceptamos cuentas STP, ya que suelen fallar y no
nos permite rastrear el depósito en caso de que algo salga mal. Asegúrate de que tu CLABE
no comience con "646".`,
      value => !value.startsWith('646'),
    )
    .length(CLABE_LENGTH, `Debe tener ${CLABE_LENGTH} dígitos`),
});
</script>

<template>
  <div class="flex w-full flex-col">
    <base-back-button
      href="/"
    />
    <rails-flash-alerts />
    <div class="flex flex-row gap-x-4 rounded-xl bg-white p-4 sm:gap-x-6 sm:p-6">
      <base-icon-box
        :src="CheckCircleIcon"
        color="green"
        size="sm"
      />
      <div class="flex flex-col gap-y-4">
        <span class="text-xs font-bold sm:text-sm">
          Listo!
        </span>
        <p class="text-xs text-gray-700 sm:text-sm">
          Ahora debes ingresar el numbero de tu cuenta bancaria para que te podamos transferir el dinero.
          <br>
          <br>
          Te depositaremos el monto completo de tu crédito a esta cuenta. Asegúrate de
          que es tu cuenta personal.
        </p>
      </div>
    </div>
    <h1 class="font-bold" />
    <v-form-native-wrapper
      ref="formRef"
      class="mt-4 flex w-full flex-col gap-y-4"
      :validation-schema="validationSchema"
      action="/group_member_loan_application/bank_account"
      method="PATCH"
    >
      <base-short-text-input
        name="group_member_loan_application[bank_account_number]"
        type="text"
        inputmode="numeric"
        label="Número de cuenta interbancaria (CLABE)"
        :value="groupMemberLoanApplication.bankAccountNumber || groupMemberLoanApplication.previousCycleBankAccountNumber"
        description="La CLABE es un número de 18 dígitos que podrás encontrar
                    en tu información de cuenta desde tu app de banca electrónica
                    o tu estado de cuenta de tu cuenta de débito o ahorros."
      />
      <base-checkbox
        name="owner"
        label="La cuenta está a mi nombre"
      />
      <base-button
        text="Guardar"
        class="mt-4 sm:self-start"
        type="button"
        @click="handleSubmit"
      />
    </v-form-native-wrapper>
  </div>
  <base-modal
    :show="showModal"
    title="Ingresar una cuenta bancaria que no está a tu nombre es bajo tu propia responsabilidad."
    body="¿Aceptas la responsabilidad de ingresar una cuenta bancaria que no está a tu nombre?"
    accept-button-text="Si, acepto"
    cancel-button-text="No"
    @accept="() => {
      formRef.innerFormRef.submit();
      showModal = false;
    }"
    @close="showModal = false"
  />
</template>
