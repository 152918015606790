<script setup>
import { computed, inject } from 'vue';
import { currentUserKey } from '@/utils/keys.js';

const props = defineProps({
  comment: { type: Object, required: true },
});

const currentUser = inject(currentUserKey);
const isAuthor = computed(() => currentUser.value.id === props.comment.author.id);
</script>

<template>
  <div class="flex flex-col gap-y-2 rounded-lg border bg-white px-4 py-3 shadow">
    <div class="mb-1 flex flex-row items-center gap-x-2 text-xs font-light text-gray-700">
      <span
        v-if="isAuthor"
        class="italic"
      >
        Tú
      </span>
      <span v-else>
        {{ comment.author.firstName }}
      </span>
      <span>-</span>
      <span>
        {{ $filters.formatRelativeDate(props.comment.createdAt) }}
      </span>
    </div>
    <div
      class="overflow-scroll text-sm text-gray-700"
      v-html="comment.body"
    />
  </div>
</template>
