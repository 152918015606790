<script setup>
import { computed } from 'vue';
import BaseTable from '@/components/base-table.vue';
import LoanUtils from '@/utils/loanUtils.ts';
import { toCurrency } from '@/utils/filters.js';

const props = defineProps({
  groupLoanApplication: { type: Object, required: true },
  groupMembers: { type: Array, required: true },
});

const tableItems = computed(() => props.groupLoanApplication.memberLoanApplications
  .filter(mla => mla.preApproved)
  .map((mla) => {
    const periodPayment = Math.ceil(LoanUtils.calculateLoanPayment(
      mla.acceptedAmount,
      mla.interestRate,
      props.groupLoanApplication.repaymentPeriods,
    ));

    const costPerThousand = LoanUtils.costPerThousand(
      mla.interestRate,
      props.groupLoanApplication.repaymentPeriods,
    );

    const groupMember = props.groupMembers.find(member => member.id === mla.groupMemberId);

    return [
      groupMember.user.fullName,
      toCurrency(mla.acceptedAmount),
      toCurrency(periodPayment),
      toCurrency(costPerThousand),
      groupMember.humanRole,
    ];
  }));
</script>

<template>
  <base-table
    v-if="tableItems.length > 0"
    title="Resumen de miembros pre-aprobados"
    :headers="[
      'Nombre',
      'Monto',
      'Pago semanal',
      'Pago / mil',
      'Rol'
    ]"
    :items="tableItems"
  />
</template>
