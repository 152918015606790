<script setup>
import { ref, onMounted } from 'vue';

const csrfToken = ref(null);

onMounted(() => {
  csrfToken.value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
});
</script>

<template>
  <input
    type="hidden"
    name="authenticity_token"
    :value="csrfToken"
  >
</template>
