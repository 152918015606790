<script setup>
import { ref, watch } from 'vue';
import { useField } from 'vee-validate';
import useFilteredAttrs from '@/composables/useFilteredAttrs.ts';
import useErrorBounceOnFormSubmit from '@/composables/useErrorBounceOnFormSubmit.js';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  label: { type: String, default: null },
  description: { type: String, default: '' },
  name: { type: String, required: true },
  error: { type: String, default: '' },
  value: { type: [String, Number], default: '' },
  modelValue: { type: [String, Number], default: '' },
  type: { type: String, default: 'text' },
  helperIcon: { type: String, default: '' },
  helperTooltip: { type: String, default: '' },
  validateOnBlur: { type: Boolean, default: true },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md'].includes(value),
  },
  loading: { type: Boolean, default: false },
  statusText: { type: String, default: '' },
});

const {
  value: inputValue,
  validate,
  errorMessage,
  handleChange,
} = useField(() => props.name, undefined, { initialValue: props.modelValue || props.value });

watch(inputValue, (newVal) => {
  emit('update:modelValue', newVal);
}, { immediate: true });

watch(() => props.modelValue, (newVal) => {
  if (newVal !== inputValue.value) {
    inputValue.value = newVal;
  }
});

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

const errorRef = ref(null);
useErrorBounceOnFormSubmit(errorRef);
</script>

<template>
  <div :class="$attrs.class || 'flex flex-col items-start w-full'">
    <label
      v-if="label"
      :for="name"
      :class="[
        'block text-sm font-medium leading-6 text-gray-900',
        {
          'text-xs': size === 'sm',
          'text-sm': size === 'md',
        },
      ]"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
      v-html="description"
    />
    <div
      :class="[
        `relative box-border flex w-full flex-row items-center overflow-hidden rounded-md border
            border-transparent bg-white px-1.5 py-1 shadow-sm ring-1 ring-inset transition duration-300
            ease-in-out focus-within:ring-2 focus-within:ring-primary-600`,
        (errorMessage || error) ?
          'border-red-400'
          : 'ring-gray-300 hover:border-primary-500',
        {
          'mt-2': Boolean(label) || Boolean(description),
          'px-1 py-0.5': size === 'sm',
          'px-1.5 py-1': size === 'md'
        },
      ]"
    >
      <input
        v-bind="filteredAttrs"
        class="w-full border-none bg-transparent p-1 text-left text-gray-900 outline-none placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
        :value="inputValue"
        :name="name"
        :type="type"
        @input="handleChange"
        @blur="validateOnBlur && validate()"
      >
      <base-icon
        v-if="helperIcon"
        v-tooltip="helperTooltip"
        :icon="helperIcon"
        class="mx-2 h-5 w-auto fill-current text-gray-400"
      />
      <span
        v-else-if="loading"
        class="absolute right-4 top-1/2 -translate-y-1/2"
      >
        <base-spinner
          :size="4"
        />
      </span>
    </div>
    <span
      v-if="errorMessage || error"
      ref="errorRef"
      class="mt-1 text-xs text-red-500 sm:text-sm"
    >
      {{ errorMessage || error }}
    </span>
    <span
      v-else-if="statusText"
      class="mt-1 text-xs text-gray-600 sm:text-sm"
    >
      {{ statusText }}
    </span>
  </div>
</template>

<script>
/*
  * Adding type="number" gives a weird UI to divs, so we
  * need to prevent it
*/
// eslint-disable-next-line import/no-default-export
export default {
  inheritAttrs: false,
};
</script>
