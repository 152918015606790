import { api } from './index';

function login({ phoneNumber, magicCode }: { phoneNumber: string, magicCode: string }) {
  const path = '/api/internal/sessions';

  return api({
    method: 'post',
    url: path,
    data: { phoneNumber, magicCode },
  });
}

export default { login };
