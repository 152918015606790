import { addMethod, mixed, string } from 'yup';
import isMobilePhone from './isMobilePhone';

addMethod(string, 'mobilePhone', function (validLocales, message) {
  return this.test('mobilePhone', message, function (value) {
    const { path, createError } = this;

    const isValidPhone = isMobilePhone(value, validLocales, { strictMode: true });

    return (
      isValidPhone ||
      createError({
        path,
        message: message || 'Número de celular debe estar en formato Whatsapp, sin el código país (ej: 5512345678)',
      })
    );
  });
});

addMethod(mixed, 'file', function (message) {
  return this.test('file', message, (value) =>
    value && value.size > 0,
  );
});

// Define a custom Yup method for specific file type validation
addMethod(mixed, 'fileType', function (types, message) {
  return this.test('fileType', message, (value) =>
    value && types.includes(value.type),
  );
});

