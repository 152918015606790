<script setup>
import { computed, inject } from 'vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import BaseSlider from '@/components/base-slider.vue';
import PMT from '@/utils/pmt.js';
import { screenKey } from '@/utils/keys.js';

const screen = inject(screenKey);

defineEmits(['update:loan', 'update:payments']);
const props = defineProps({
  loan: { type: Number, required: true },
  payments: { type: Number, required: true },
  showPaymentsOptions: { type: Boolean, default: false },
});

const LOAN_MID_MONTHLY_IR = (0.85 / 52) * 1.16;
const MIN_LOAN_MXN = 3000;
const MAX_LOAN_MXN = 30000;
const COST_PER_THOUSANDS_MXN = 73.05;

const paymentsOptions = [16];

const totalCost = computed(
  () => PMT(LOAN_MID_MONTHLY_IR, props.payments, props.loan) *
    props.payments,
);
const midMonthPayment = computed(
  () => PMT(LOAN_MID_MONTHLY_IR, props.payments, props.loan),
);
</script>

<template>
  <div class="flex flex-col border-primary-600 bg-white px-8 py-6 sm:rounded-lg sm:border">
    <h2 class="text-2xl font-bold">
      💸 Cotiza cómo quedarían tus pagos
    </h2>
    <div
      v-if="showPaymentsOptions"
      class="mt-8 flex flex-col space-y-2"
    >
      <label
        for="payments"
        class="text-sm font-semibold"
      >
        Selecciona el número de pagos semanales
      </label>
      <select
        v-if="screen.isMobile"
        id="payments"
        name="payments"
        :value="payments"
        class="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500"
        @input="$emit('update:payments', $event.target.value)"
      >
        <option
          v-for="paymentOption in paymentsOptions"
          :key="paymentOption"
          :selected="paymentOption === payments"
          :value="paymentOption"
        >
          {{ paymentOption }} pagos semanales
        </option>
      </select>
      <div
        v-else
        class="flex flex-col gap-y-4"
      >
        <div
          class="flex space-x-4"
          aria-label="Tabs"
        >
          <button
            v-for="paymentOption in paymentsOptions"
            :key="paymentOption"
            type="button"
            :class="[
              paymentOption === payments
                ? 'bg-primary-100 text-primary-700'
                : 'text-gray-500 hover:text-gray-700',
              'rounded-md px-3 py-2 text-sm font-medium'
            ]"
            @click="$emit('update:payments', paymentOption)"
          >
            {{ paymentOption }} semanas
          </button>
        </div>
      </div>
    </div>
    <div class="mt-12 flex flex-col gap-y-4">
      <div class="flex flex-row justify-between gap-x-4">
        <label
          for="monto"
          class="text-sm font-semibold"
        >
          Monto del crédito
        </label>
        <span class="font-semibold">
          ${{ $filters.formatNumber(loan) }} pesos
        </span>
      </div>
      <base-slider
        :value="loan"
        :min="MIN_LOAN_MXN"
        :max="MAX_LOAN_MXN"
        show-tooltip="false"
        :step="1000"
        @input="$emit('update:loan', $event)"
      />
      <input
        class="hidden"
        type="number"
        :value="loan"
      >
    </div>
    <div class="mt-12 flex flex-col gap-y-2">
      <span>
        <b>Pago semanal</b>: ${{ $filters.formatNumber(midMonthPayment, { maximumFractionDigits: 0 }) }} pesos
      </span>
      <span>
        <b>Pago por cada mil</b>: ${{ COST_PER_THOUSANDS_MXN }} pesos
      </span>
      <span>
        <b>Total a pagar</b>: ${{ $filters.formatNumber(totalCost, { maximumFractionDigits: 0 }) }} pesos
      </span>
    </div>
    <div class="mt-8 flex flex-row gap-x-2">
      <InformationCircleIcon class="h-4 w-4 shrink-0 text-blue-400" />
      <span class="text-xs italic text-gray-600">
        Esta es una simulación con términos promedio para grupos consolidados de más de 8 integrantes.
        El monto y costo del crédito podría variar para cada integrante dependiendo de la
        evaluación del historial crediticio y el grupo.
      </span>
    </div>
    <slot name="cta" />
  </div>
</template>
