<script setup>
import { computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import GroupMemberLoanApplicationsStatus from '@/components/group-member-loan-applications-status.vue';
import GroupMemberKycsStatus from '@/components/group-member-kycs-status.vue';
import GroupLoanContractSigningStatus from '@/components/group-loan-contract-signing-status.vue';
import useGroupStore from '@/stores/group.js';
import groupLoanApplicationApi from '@/api/application/groupLoanApplication.ts';
import BaseBanner from '@/components/base-banner.vue';

const props = defineProps({
  group: { type: Object, required: true },
  groupLoanApplicationId: { type: Number, required: true },
});

const groupStore = useGroupStore();

const {
  data: groupLoanApplication,
  isLoading: isFetchingGroupLoanApplication,
  refetch: refetchGroupLoanApplication,
} = useQuery({
  queryKey: ['groupLoanApplications', props.groupLoanApplicationId],
  queryFn: () => groupLoanApplicationApi.get(props.groupLoanApplicationId),
});

watch(groupLoanApplication, () => {
  const group = groupLoanApplication.value.group;
  groupStore.setGroup(group);
  groupStore.setGroupMembers(group.members);
  groupStore.setGroupLoanApplication(groupLoanApplication.value);
  groupStore.setGroupPendingInvitations(group.pendingInvitations);
});

let refetchInterval;
const REFRESH_INTERVAL = 5000;
onMounted(() => {
  refetchInterval = setInterval(() => {
    refetchGroupLoanApplication();
  }, REFRESH_INTERVAL);
});

onBeforeUnmount(() => {
  clearInterval(refetchInterval);
});

const currentUserLoanApplication = computed(() => groupStore.currentUserMember?.loanApplication);

const isLoanApplicationFormStepOpen = computed(
  () => ['draft', 'application_in_review', 'application_rejected'].includes(groupLoanApplication.value?.aasmState),
);

const isKycStepOpen = computed(
  () => ['in_kyc', 'kyc_in_review'].includes(groupLoanApplication.value?.aasmState),
);

const isLoanContractSigningStepOpen = computed(
  () => ['contracts_signature_pending', 'contracts_signed']
    .includes(groupLoanApplication.value?.aasmState),
);
</script>

<template>
  <div class="flex min-h-screen w-full flex-col">
    <rails-flash-alerts />
    <span class="text-lg font-bold">
      {{ group.name }}
    </span>
    <base-spinner
      v-if="isFetchingGroupLoanApplication"
      :size="20"
      class="mt-16 self-center"
    />
    <div
      v-else
      class="mt-6 flex flex-col gap-y-6"
    >
      <base-banner
        v-if="groupLoanApplication.cycle > 1"
        left-icon="⌛"
        text="Al ser una renovación, les va a tomar
          menos tiempo completar su solicitud y el resto del proceso a quienes fueron
          parte del ciclo anterior."
        class="mt-4"
      />
      <group-member-loan-applications-status
        :is-open="isLoanApplicationFormStepOpen || currentUserLoanApplication.aasmState === 'rejected'"
      />
      <div class="h-8 w-0.5 self-center rounded-md bg-gray-300" />
      <group-member-kycs-status
        :is-open="isKycStepOpen && currentUserLoanApplication.preApproved"
        :is-locked="isLoanApplicationFormStepOpen || currentUserLoanApplication.aasmState === 'rejected'"
      />
      <div class="h-8 w-0.5 self-center rounded-md bg-gray-300" />
      <group-loan-contract-signing-status
        :is-open="isLoanContractSigningStepOpen && currentUserLoanApplication.preApproved"
        :is-locked="isKycStepOpen ||
          isLoanApplicationFormStepOpen ||
          currentUserLoanApplication.aasmState === 'rejected'"
      />
    </div>
  </div>
</template>
