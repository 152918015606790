<template>
  <div class="flex flex-col items-center py-20 sm:py-32">
    <span class="text-2xl font-bold text-primary-600">
      Confían en nosotros
    </span>
    <div class="mt-12 flex flex-col flex-wrap items-center justify-center gap-10 sm:flex-row">
      <a
        href="https://ventures.platan.us"
        target="_blank"
      >
        <base-image
          img-name="pv"
          alt="Platanus Ventures"
          ext="png"
          class="h-8 w-auto sm:h-10"
        />
      </a>
      <a
        href="https://www.burodecredito.com.mx/"
        target="_blank"
      >
        <base-image
          img-name="buro-credito"
          alt="Buro de Credito"
          ext="png"
          class="h-32 w-auto"
        />
      </a>
      <a
        href="https://www.circulodecredito.com.mx/"
        target="_blank"
      >
        <base-image
          img-name="circulo-credito"
          alt="Circulo de Credito"
          ext="png"
          class="h-24 w-auto"
        />
      </a>
      <a
        href="https://www.gob.mx/profeco"
        target="_blank"
      >
        <base-image
          img-name="profeco"
          alt="Profeco"
          ext="png"
          class="h-16 w-auto"
        />
      </a>
    </div>
  </div>
</template>
