<script setup>
import { watch } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { useField } from 'vee-validate';
import useFilteredAttrs from '../composables/useFilteredAttrs.ts';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  label: { type: String, required: true },
  description: { type: String, default: '' },
  name: { type: String, required: true },
  value: { type: [Object, String], default: null },
  flow: { type: Array, default: () => [] },
  modelValue: { type: [Object, String], default: null },
  error: { type: String, default: '' },
});

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

const {
  value: inputValue,
  validate,
  errorMessage,
  handleChange,
} = useField(() => props.name, undefined, { initialValue: props.modelValue || props.value });

watch(inputValue, (newVal) => {
  emit('update:modelValue', newVal);
});
</script>

<template>
  <div :class="$attrs.class">
    <label
      :for="name"
      class="block text-sm font-medium leading-6 text-gray-900"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
    >
      {{ description }}
    </p>
    <div class="mt-2 flex flex-col">
      <VueDatePicker
        :model-value="modelValue"
        :value="value"
        :name="name"
        v-bind="filteredAttrs"
        format="dd/MM/yyyy"
        :week-start="1"
        :flow="flow"
        :month-change-on-scroll="false"
        @update:model-value="handleChange"
        @blur="validate"
      />
      <span
        v-if="errorMessage || error"
        class="mt-1 text-xs text-red-500 sm:text-sm"
      >
        {{ errorMessage || error }}
      </span>
    </div>
  </div>
</template>
