<script setup>
import { computed, inject } from 'vue';
import { ClockIcon, CheckIcon } from '@heroicons/vue/24/outline';
import useGroupStore from '../stores/group.js';
import { currentUserKey } from '../utils/keys.js';
import LoanApplicationStep from './loan-application-step.vue';
import BasePendingActionNotification from './base-pending-action-notification.vue';

defineProps({
  isOpen: { type: Boolean, default: false },
});

const currentUser = inject(currentUserKey);
const groupStore = useGroupStore();

const approvedMembers = computed(
  () => groupStore.groupMembers.filter(
    (member) => member.loanApplication?.preApproved,
  ),
);
</script>

<template>
  <loan-application-step
    :step="3"
    step-title="Aprobación del crédito"
    :is-open="isOpen"
  >
    <template #badge>
      <span
        v-if="groupStore.groupLoanApplication?.aasmState === 'loan_approved'"
        class="flex w-5 items-center justify-center rounded-full bg-green-400 p-1"
      >
        <check-icon class="h-full w-full text-white" />
      </span>
      <span
        v-else
        class="flex flex-row items-center gap-x-2 rounded-md border border-white px-2 py-1 text-xs text-white"
      >
        <ClockIcon class="h-4 w-4" />
        Pendiente
      </span>
    </template>
    <template #default>
      <base-pending-action-notification
        v-if="!groupStore.currentUserMember?.loanApplication?.contractSigned"
        title="No has firmado tu contrato de crédito."
        description="Para que el monto del crédito sea entregado, cada miembro
              del grupo debe haber firmado su contrato. El proceso
              es 100% digital, solo te tomará unos minutos revisarlo e ingresar
              tu firma."
        action="/group_member_loan_application/new_members_approval/new"
        cta="Revisar y firmar contrato"
      />
      <base-pending-action-notification
        v-else-if="!groupStore.currentUserMember?.loanApplication?.bankAccountNumber"
        title="Faltan tus datos bancarios"
        description="Ya firmaste el contrato, ahora solo falta que nos digas dónde
              quieres que te depositemos el dinero."
        action="/group_member_loan_application/bank_account/edit"
        cta="Ingresar datos bancarios"
      />
      <div class="mt-6 flex flex-col">
        <span class="text-white">
          Integrantes del grupo
        </span>
        <div class="mt-4 flex flex-col gap-y-6">
          <div
            v-for="member in approvedMembers"
            :key="member.id"
            class="flex w-full flex-row gap-x-2"
          >
            <base-avatar
              :user="member.user"
            />
            <div class="flex flex-col">
              <div class="flex flex-row items-center gap-x-4">
                <span class="text-sm font-bold text-white">
                  {{ member.user.firstName }}
                </span>
                <span
                  v-if="member.president"
                  class="text-xs italic text-gray-200"
                >
                  (Presidente)
                </span>
                <span
                  v-else-if="member.userId === currentUser.id"
                  class="text-xs italic text-gray-200"
                >
                  (Tú)
                </span>
              </div>
              <span
                v-if="!member.loanApplication.contractSigned"
                class="mt-1 text-sm text-gray-100"
              >
                🕓 Aún no firma el contrato.
              </span>
              <span
                v-else-if="!member.loanApplication.bankAccountNumber"
                class="mt-1 text-sm text-gray-100"
              >
                🕓 Aún no ingresa sus datos bancarios.
              </span>
              <span
                v-else
                class="mt-2 text-sm text-white"
              >
                ✅ Contrato firmado.
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </loan-application-step>
</template>
