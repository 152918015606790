<script setup>
import { ref, onMounted, inject } from 'vue';
import { useEventBus } from '@vueuse/core';
import { mobileAppStoreUrlKey } from '../utils/keys.js';

const mobileAppStoreUrl = inject(mobileAppStoreUrlKey);

const features = [
  {
    title: '📱 Tramite 100% en línea',
    bullets: [
      'Haz tu grupo en línea',
      'Llena tu solicitud desde tu celular',
      'Firma tu contrato en línea',
      'Tasas fijas y justas',
    ],
  },
  {
    title: '💵 Cobra tu crédito de inmediato',
    bullets: [
      'Cóbralo en tu cuenta bancaria y retiralo en cajeros',
      'Sin hacer filas o ir a una sucursal',
      'Sin enganche, ni ahorros',
    ],
  },
  {
    title: '💰 Paga en efectivo o transferencia',
    bullets: [
      'Paga en un OXXO, farmacia y más de 10,000 puntos',
      'Pago por transferencia bancaria',
    ],
  },
  {
    title: '📈 Completa tu ciclo y obtén beneficios',
    bullets: [
      'Servicio al cliente por Whatsapp o teléfono 24/7',
      'Renueva tu crédito de inmediato',
      'Cada ciclo nuevo baja tu tasa',
    ],
  },
];

const elementToScrollTo = ref(null);
const scrollEventBus = useEventBus('scroll');

onMounted(() => {
  scrollEventBus.on((event) => {
    if (elementToScrollTo.value && event === 'features') {
      elementToScrollTo.value.scrollIntoView({ behavior: 'smooth' });
    }
  });
});
</script>

<template>
  <div class="container flex max-w-7xl flex-col items-center self-center px-4 py-16 sm:py-32 md:px-12 lg:items-start">
    <h2
      ref="elementToScrollTo"
      class="title"
    >
      Invierte en tu negocio desde tu celular
    </h2>
    <div class="mt-12 flex flex-col gap-x-12 gap-y-8 lg:flex-row">
      <div class="flex grow flex-col lg:w-[80%]">
        <div class="w-full grow overflow-hidden border-primary-600 sm:rounded-xl sm:border">
          <base-image
            img-name="landing-features-woman"
            alt="Mujer emprendedora dueña de un negocio"
            ext="png"
            class="h-full w-full object-cover"
          />
        </div>
        <span class="self-center text-center text-xs text-primary-600">
          Carmen, pidió su crédito y fue aprobado y entregado el mismo día.
        </span>
      </div>
      <div class="grid w-full grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-8">
        <div
          v-for="feature in features"
          :key="feature.title"
          class="flex flex-col rounded-lg border border-primary-600 bg-white p-4"
        >
          <h3 class="text-sm font-bold text-primary-600 sm:text-base">
            {{ feature.title }}
          </h3>
          <ul class="ml-4 mt-4 flex list-disc flex-col gap-y-2 marker:text-primary-600 sm:mt-10">
            <li
              v-for="bullet in feature.bullets"
              :key="bullet"
              class="text-sm text-gray-900"
            >
              {{ bullet }}
            </li>
          </ul>
        </div>
        <base-button
          text="Pide tu crédito desde tu celular"
          class="m-2 sm:col-span-2"
          :href="mobileAppStoreUrl"
        />
      </div>
    </div>
  </div>
</template>
