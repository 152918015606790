import { api } from '@/api/index';

export default {
  update(id, user) {
    const path = `/api/internal/crm/whatsapp/users/${id}`;

    return api({
      method: 'PUT',
      url: path,
      data: { user },
    });
  },
};
