<script setup>
import { computed } from 'vue';
import { string, object, bool } from 'yup';
import { UserGroupIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  groupMemberLoanApplication: { type: Object, required: true },
  newGroupMembers: { type: Array, required: true },
});

const validationSchema = computed(() => {
  const memberValidations = {};

  props.newGroupMembers.forEach((member) => {
    memberValidations[`${member.id}`] = object().shape({
      approved: bool().required('Approval is required'),
      relationship: string().required('Relationship must be specified'),
    });
  });

  // Use object() for the outer layer to match the expected structure.
  return object().shape(memberValidations);
});
</script>

<template>
  <div class="flex w-full flex-col">
    <base-back-button
      href="/"
    />
    <h1>
      <div class="flex flex-row items-center gap-x-4">
        <base-icon-box
          :src="UserGroupIcon"
          color="light-blue"
          size="xs"
        />
        <span class="text-lg font-bold">
          Nuevos integrantes
        </span>
      </div>
    </h1>
    <v-form-native-wrapper
      v-slot="{ values }"
      class="mt-6 flex flex-col gap-y-8"
      :validation-schema="validationSchema"
      action="/group_member_loan_application/new_members_approval"
      method="POST"
    >
      <p class="text-sm text-gray-600">
        Te pedimos que revises y apruebes a los nuevos integrantes del grupo.
      </p>
      <div class="flex flex-col gap-y-4">
        <div
          v-for="newMember in newGroupMembers"
          :key="newMember.id"
          class="flex flex-col gap-y-4 rounded-lg bg-white p-4"
        >
          <input
            type="hidden"
            :name="`group_member_loan_application[${newMember.id}][approved]`"
            :value="values[newMember.id]?.approved"
          >
          <input
            type="hidden"
            :name="`group_member_loan_application[${newMember.id}][relationship]`"
            :value="values[newMember.id]?.relationship"
          >
          <div class="flex flex-row gap-x-4">
            <base-avatar
              :user="newMember.user"
              size="sm"
            />
            <div class="flex flex-col gap-y-4">
              <span class="text-sm font-medium">
                {{ newMember.user.fullName }}
              </span>
              <div class="grid grid-cols-2 gap-x-4">
                <div class="flex flex-col gap-y-2">
                  <span class="text-sm text-gray-700">
                    💸 Monto aceptado
                  </span>
                  <span class="text-sm font-semibold">
                    {{ $filters.toCurrency(newMember.loanApplication.acceptedAmount) }}
                  </span>
                </div>
                <div class="flex flex-col gap-y-2">
                  <span class="text-sm text-gray-700">
                    💲 Pago semanal
                  </span>
                  <span class="text-sm font-semibold">
                    {{ $filters.toCurrency(newMember.loanApplication.repaymentAmount) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-y-2">
            <base-checkbox
              :name="`${newMember.id}.approved`"
              label="Estoy de acuerdo con el monto y el pago semanal"
              :value="groupMemberLoanApplication.relationshipWithNewMembers.find(
                (member) => member.id === newMember.id
              )?.approved"
            />
            <base-native-select
              :name="`${newMember.id}.relationship`"
              label="Cómo conoces a esta persona"
              :options="[
                { label: 'Familiar', value: 'family' },
                { label: 'Amigo', value: 'friend' },
                { label: 'Vecino', value: 'neighbor' },
                { label: 'Compañero de trabajo', value: 'coworker' },
                { label: 'No la conozco', value: 'unknown' },
              ]"
              :value="groupMemberLoanApplication.relationshipWithNewMembers.find(
                (member) => member.id === newMember.id
              )?.relationship"
            />
          </div>
        </div>
      </div>
      <base-button
        type="submit"
        text="Continuar"
      />
    </v-form-native-wrapper>
  </div>
</template>
