<script setup>
import { ref, computed, toRefs } from 'vue';
import { XMarkIcon, ArrowUpTrayIcon } from '@heroicons/vue/24/outline';
import useFileHandler from '@/composables/useFileHandler';
import useErrorBounceOnFormSubmit from '@/composables/useErrorBounceOnFormSubmit.js';

const props = defineProps({
  name: { type: String, required: true },
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  extensions: { type: String, default: 'JPG, PNG, JPEG' },
  sizeLimit: { type: Number, default: null },
  value: { type: String, default: null },
  error: { type: String, default: '' },
});

const inputRef = ref(null);
const { name, rules } = toRefs(props);
const {
  inputValue,
  errorMessage,
  handleChange,
  handleDrop,
  reset,
  fileName,
} = useFileHandler({ inputRef, name, rules, initialValue: props.value });

const errorRef = ref(null);
useErrorBounceOnFormSubmit(errorRef);

const isImageFile = computed(() => {
  if (!inputValue.value) return false;

  const imageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

  return imageTypes.includes(inputValue.value.type);
});

const imageBlobMemoryUrl = computed(() => URL.createObjectURL(inputValue.value));
</script>
<template>
  <div :class="$attrs.class || 'flex w-full flex-col items-start'">
    <label
      :for="name"
      class="block text-sm font-medium leading-6"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
    >
      {{ description }}
    </p>
    <label
      class="relative mt-2 flex w-full cursor-pointer items-center justify-center rounded-md border-2 border-dashed border-primary-600 p-4"
      @dragenter.prevent
      @dragover.prevent
      @drop.prevent="handleDrop"
    >
      <button
        v-if="inputValue"
        class="absolute -right-3 -top-3 items-center justify-center rounded-full bg-primary-600 p-1 focus:outline-none"
        @click="reset"
      >
        <x-mark-icon class="h-auto w-4 text-white" />
      </button>
      <input
        v-bind="$attrs"
        ref="inputRef"
        type="file"
        :name="name"
        class="hidden"
        @change="handleChange"
      >
      <div
        class="relative flex flex-col items-center justify-center space-y-3"
      >
        <img
          v-if="isImageFile"
          :src="imageBlobMemoryUrl"
          class="h-full w-full rounded-md object-cover"
        >
        <template v-else>
          <ArrowUpTrayIcon
            class="h-8 w-auto text-gray-400"
          />
          <span
            v-if="inputValue"
            class="flex h-8 w-full items-center justify-center break-all text-center sm:h-12"
          >
            {{ fileName }}
          </span>
          <div
            v-else
            class="flex h-8 flex-col items-center justify-center sm:h-12"
          >
            <p class="text-sm font-medium text-gray-700">
              Sube una imagen
            </p>
            <div v-if="extensions || sizeLimit">
              <span
                v-if="extensions"
                class="text-xs uppercase text-gray-600"
              >
                {{ extensions }}
              </span>
              <span v-if="sizeLimit">
                Hasta {{ sizeLimit }} MB
              </span>
            </div>
          </div>
        </template>
      </div>
    </label>
    <span
      v-if="errorMessage || error"
      ref="errorRef"
      class="mt-1 text-xs text-red-500 sm:text-sm"
    >
      {{ errorMessage || error }}
    </span>
  </div>
</template>
