<script setup>
import { ref, watch } from 'vue';
import { object } from 'yup';
import { textFieldSchema } from '@/utils/yupRules';
import { useMutation } from '@tanstack/vue-query';
import copomexApi from '@/api/copomex';
import useUIStore from '@/stores/ui';

const emit = defineEmits([
  'submit',
  'update:street',
  'update:external-number',
  'update:internal-number',
  'update:colony',
  'update:municipality',
  'update:postal-code',
  'update:state',
  'update:civil-status',
  'update:education',
]);

const props = defineProps({
  street: { type: String, default: '' },
  externalNumber: { type: String, default: '' },
  internalNumber: { type: String, default: '' },
  colony: { type: String, default: '' },
  municipality: { type: String, default: '' },
  postalCode: { type: String, default: '' },
  state: { type: String, default: '' },
  civilStatus: { type: String, default: '' },
  education: { type: String, default: '' },
});

const uiStore = useUIStore();

const validationSchema = object().shape({
  street: textFieldSchema,
  externalNumber: textFieldSchema,
  internalNumber: textFieldSchema,
  colony: textFieldSchema,
  municipality: textFieldSchema,
  postalCode: textFieldSchema,
  state: textFieldSchema,
  civilStatus: textFieldSchema,
  education: textFieldSchema,
});

const colonies = ref([]);

const {
  mutate: fetchPostalCodeData,
  isPending: isFetchingPostalCodeData,
  isSuccess: isPostalCodeValid,
  reset: resetPostalCodeMutation,
} = useMutation({
  mutationFn: () => copomexApi.getCpInfo(props.postalCode),
  onSuccess: (data) => {
    emit('update:municipality', data.municipality);
    emit('update:state', data.state);
    colonies.value = data.colonies;
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Ocurrió un error al obtener los datos del código postal',
      type: 'error',
      position: 'bottom',
    });
  },
});

watch(() => props.postalCode, resetPostalCodeMutation);

function handleSubmit() {
  if (isPostalCodeValid.value) {
    emit('submit');
  } else {
    fetchPostalCodeData();
  }
}
</script>

<template>
  <v-form
    :validation-schema="validationSchema"
    @submit="handleSubmit"
  >
    <div class="flex flex-col gap-y-10">
      <base-form-section title="Domicilio">
        <div class="flex flex-col gap-y-2">
          <base-short-text-input
            :model-value="street"
            name="street"
            label="Calle"
            @update:model-value="$emit('update:street', $event)"
          />
          <base-short-text-input
            :model-value="externalNumber"
            name="externalNumber"
            label="Número exterior"
            placeholder="Ej: 568"
            @update:model-value="$emit('update:external-number', $event)"
          />
          <base-short-text-input
            :model-value="internalNumber"
            name="internalNumber"
            label="Número interior"
            description="El número de tu departamento, oficina, etc.
                              puedes dejarlo en blanco si no lo sabes"
            placeholder="Ej: 7"
            @update:model-value="$emit('update:internal-number', $event)"
          />
          <base-short-text-input
            :model-value="postalCode"
            name="postalCode"
            label="Código postal"
            placeholder="Ej: 55650"
            :loading="isFetchingPostalCodeData"
            @update:model-value="$emit('update:postal-code', $event)"
            @blur="fetchPostalCodeData"
          />
          <base-native-select
            v-if="isPostalCodeValid"
            :model-value="colony"
            name="colony"
            label="Colonia"
            placeholder="Ej: Anzures"
            :options="colonies.map(colony => ({ label: colony, value: colony }))"
            @update:model-value="$emit('update:colony', $event)"
          />
          <base-short-text-input
            v-if="isPostalCodeValid"
            :model-value="municipality"
            name="municipality"
            label="Municipio"
            placeholder="Ej: Miguel Hidalgo"
            disabled
            @update:model-value="$emit('update:municipality', $event)"
          />
          <base-short-text-input
            v-if="isPostalCodeValid"
            :model-value="state"
            name="state"
            label="Estado"
            disabled
            @update:model-value="$emit('update:state', $event)"
          />
        </div>
      </base-form-section>
      <base-form-section title="Estado civil y estudios">
        <base-native-select
          :model-value="civilStatus"
          name="civilStatus"
          label="Estado civil"
          :options="[
            { label: 'Soltero/a', value: 'single' },
            { label: 'Casado/a', value: 'married' },
            { label: 'Divorciado/a', value: 'divorced' },
            { label: 'Viudo/a', value: 'widowed' },
          ]"
          @update:model-value="$emit('update:civil-status', $event)"
        />
        <base-native-select
          :model-value="education"
          name="education"
          label="Nivel máximo de estudios"
          :options="[
            { label: 'Primaria', value: 'elementary' },
            { label: 'Secundaria', value: 'junior_high' },
            { label: 'Preparatoria', value: 'high_school' },
            { label: 'Licenciatura', value: 'bachelor' },
            { label: 'Maestría', value: 'master' },
            { label: 'Doctorado', value: 'doctorate' },
            { label: 'Ninguno', value: 'none' },
          ]"
          @update:model-value="$emit('update:education', $event)"
        />
      </base-form-section>
    </div>
    <base-button
      :type="isPostalCodeValid ? 'submit' : 'button'"
      text="Siguiente"
      class="mt-12 w-full"
      :loading="isFetchingPostalCodeData"
      @click="() => !isPostalCodeValid && handleSubmit()"
    />
  </v-form>
</template>
