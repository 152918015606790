import { api } from './index';

function submit(body: object) {
  const path = '/api/internal/group_member_loan_application/submission';

  return api({
    method: 'post',
    url: path,
    data: body,
  });
}

function submitKyc(form: FormData) {
  const path = '/api/internal/group_member_loan_application/kyc_submission';

  return api({
    method: 'post',
    url: path,
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

function createAuthorizationCode() {
  const path = `/api/internal/group_member_loan_application/authorization_code`;

  return api({
    method: 'post',
    url: path,
  });
}

function createAuthorizationCodeValidation(code: string) {
  const path = `/api/internal/group_member_loan_application/authorization_code_validation`;

  return api({
    method: 'post',
    url: path,
    data: { code },
  });
}

function updateCurpAndAddress(formId: number, body: object) {
  const path = `/api/internal/group_member_loan_applications/curp_and_address_forms/${formId}`;

  return api({
    method: 'put',
    url: path,
    data: body,
  });
}

export default { submit, submitKyc, createAuthorizationCode, createAuthorizationCodeValidation, updateCurpAndAddress };
