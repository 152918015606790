import { api } from './index';

export default {
  getAll(resourceType, resourceId = null) {
    const path = '/api/internal/comments';

    return api({
      params: { 'resource_id': resourceId, 'resource_type': resourceType },
      url: path,
    });
  },
  create(body) {
    const path = '/api/internal/comments';

    return api({
      method: 'post',
      data: body,
      url: path,
    });
  },
};
