<script setup>
import { ref, computed, inject } from 'vue';
import {
  ClockIcon,
  ArrowLongRightIcon,
  CheckCircleIcon,
  ReceiptPercentIcon,
  BanknotesIcon,
  CheckIcon,
  InformationCircleIcon,
  XMarkIcon,
  CalendarDaysIcon,
} from '@heroicons/vue/24/outline';
import { useMutation } from '@tanstack/vue-query';
import BaseModal from '@/components/base-modal.vue';
import BaseAlert from '@/components/base-alert.vue';
import useGroupStore from '@/stores/group.js';
import { currentUserKey } from '@/utils/keys.js';
import groupInvitationApi from '@/api/groupInvitation';
import useUIStore from '@/stores/ui';
import LoanApplicationStep from './loan-application-step.vue';

defineProps({
  isOpen: { type: Boolean, default: false },
});

const currentUser = inject(currentUserKey);
const groupStore = useGroupStore();
const uiStore = useUIStore();

const currentUserLoanApplication = computed(() => groupStore.currentUserMember?.loanApplication);

const badgeClasses = computed(() => {
  const classes = {
    draft: 'border-white text-white',
    'application_in_review': 'bg-gray-50 text-primary-600 border-primary-200',
    'application_rejected': 'bg-red-500 text-white',
  };

  return classes[groupStore.groupLoanApplication?.aasmState];
});

const applicationStatusText = computed(() => {
  const aasmState = groupStore.groupLoanApplication?.aasmState;
  if (aasmState === 'draft') return 'Pendiente';
  else if (aasmState === 'application_in_review') return 'En revisión';
  else if (aasmState === 'application_rejected') return 'Rechazada';

  return null;
});

const groupInvitations = computed(
  () => [
    ...(groupStore.groupMembers || []).map(member => member.invitation),
    ...(groupStore.groupPendingInvitations || []),
  ].filter(Boolean),
);

function getMemberInvitation(member) {
  return groupInvitations.value
    .find(invitation => invitation.inviteeId === member.userId);
}

const isUserPresident = computed(
  () => currentUser.value.id === groupStore.groupMembers?.find(
    member => member.role === 'president',
  )?.userId,
);
const memberToDeleteId = ref(null);
const memberToDelete = computed(() => groupInvitations.value.find(inv => inv.id === memberToDeleteId.value));
const askDeleteConfirmationModalOpen = ref(false);
function onDelete(id) {
  askDeleteConfirmationModalOpen.value = true;
  memberToDeleteId.value = id;
}

const { mutate: deleteMember, isPending: isDeleteMemberPending } = useMutation({
  mutationFn: (id) => groupInvitationApi.destroy(id),
  onSuccess: (_, id) => {
    if (groupStore.groupMembers?.find(member => member.groupInvitationId === id)) {
      groupStore.setGroupMembers(
        groupStore.groupMembers.filter(member => member.groupInvitationId !== id),
      );
    } else {
      groupStore.setGroupPendingInvitations(
        groupStore.groupPendingInvitations.filter(invitation => invitation.id !== id),
      );
    }

    uiStore.toast({
      message: 'Miembro eliminado',
      type: 'success',
      duration: 5000,
      position: 'bottom',
    });

    askDeleteConfirmationModalOpen.value = false;
  },
});
</script>

<template>
  <loan-application-step
    :step="1"
    step-title="Solicitud"
    :is-open="isOpen"
  >
    <template #badge>
      <span
        v-if="applicationStatusText"
        :class="['flex flex-row items-center gap-x-2 rounded-md border px-2 py-1 text-xs', badgeClasses]"
      >
        <ClockIcon
          v-if="groupStore.groupLoanApplication.aasmState === 'draft'"
          class="h-4 w-4"
        />
        {{ applicationStatusText }}
      </span>
      <span
        v-else
        class="flex w-5 items-center justify-center rounded-full bg-green-400 p-1"
      >
        <check-icon class="h-full w-full text-white" />
      </span>
    </template>
    <template #default>
      <div
        v-if="currentUserLoanApplication?.aasmState === 'draft'"
        class="flex flex-col gap-y-6 rounded-lg bg-gray-50 px-4 py-3"
      >
        <div class="flex flex-row gap-x-4">
          <base-icon-box
            color="cyan"
            size="sm"
            :src="InformationCircleIcon"
          />
          <div class="flex flex-col gap-y-3">
            <span class="text-xs font-bold text-black">
              No has enviado tu solicitud de crédito.
            </span>
            <p class="text-xs text-black">
              Para que el crédito sea otorgado, cada miembro
              del grupo debe haber completado su solicitud.
            </p>
          </div>
        </div>
        <base-button
          :right-icon="ArrowLongRightIcon"
          href="/group_member_loan_application/form/edit"
          text="Empezar solicitud de crédito"
          class="text-xs font-semibold text-white"
        />
      </div>

      <div
        v-else-if="currentUserLoanApplication?.preApproved"
        class="flex flex-col gap-y-6 rounded-lg bg-gray-50 px-4 py-3"
      >
        <div class="flex flex-row gap-x-4">
          <base-icon-box
            color="green"
            size="sm"
            :src="CheckCircleIcon"
          />
          <div class="flex flex-col gap-y-3">
            <span class="text-xs font-bold text-black">
              Tu solicitud ha sido pre-aprobada 🎉
            </span>
            <div class="flex flex-col gap-y-1">
              <div class="flex flex-row items-start gap-x-1.5 text-gray-700">
                <BanknotesIcon class="mt-0.5 h-4 w-4" />
                <span class="text-sm">
                  Monto aprobado: <b>{{ $filters.toCurrency(currentUserLoanApplication.acceptedAmount) }} pesos</b>.
                </span>
              </div>
              <div class="flex flex-row items-start gap-x-1.5 text-gray-700">
                <ReceiptPercentIcon class="mt-0.5 h-4 w-4" />
                <span class="text-sm">
                  Pago por cada $1,000:
                  <b>{{ $filters.toCurrency(currentUserLoanApplication.costPerThousand) }}
                    pesos</b>.
                </span>
              </div>
              <div class="flex flex-row items-start gap-x-1.5 text-gray-700">
                <CalendarDaysIcon class="mt-0.5 h-4 w-4" />
                <span class="text-sm">
                  Pago semanal: <b>{{ $filters.toCurrency(currentUserLoanApplication.repaymentAmount) }} pesos</b>.
                </span>
              </div>
            </div>
          </div>
        </div>

        <p
          v-if="groupStore.groupLoanApplication.cycle === 1"
          class="text-sm font-semibold text-gray-700"
        >
          Ahora el asesor se pondrá de acuerdo con el grupo para realizar la reunión de
          autorización.
        </p>
      </div>

      <div class="mt-6 flex flex-col">
        <span class="text-white">
          Integrantes del grupo
        </span>
        <div class="mt-4 flex flex-col gap-y-6">
          <div
            v-for="member in groupStore.groupMembers"
            :key="member.id"
            class="flex w-full flex-row gap-x-2"
          >
            <base-avatar
              :user="member.user"
            />
            <div class="flex flex-col">
              <div class="flex flex-row items-center gap-x-4">
                <span class="text-sm font-bold text-white">
                  {{ member.user.firstName }}
                </span>
                <span
                  v-if="member.userId === currentUser.id"
                  class="text-xs italic text-gray-200"
                >
                  (Tú)
                </span>
                <span
                  v-else-if="member.president"
                  class="text-xs italic text-gray-200"
                >
                  (Presidente)
                </span>
              </div>
              <div
                v-if="member.loanApplication.preApproved"
                class="mt-2 flex flex-col gap-y-2"
              >
                <span class="text-sm text-gray-100">
                  ✅ Solicitud pre aprobada.
                </span>
                <template
                  v-if="isUserPresident && member.userId !== currentUser.id"
                >
                  <div class="flex flex-row items-start gap-x-1.5 text-gray-100">
                    <BanknotesIcon class="mt-0.5 h-4 w-4" />
                    <span class="text-sm text-gray-100">
                      Monto aprobado: <b>{{ $filters.toCurrency(member.loanApplication.acceptedAmount) }} pesos</b>.
                    </span>
                  </div>
                  <div class="flex flex-row items-start gap-x-1.5 text-gray-100">
                    <ReceiptPercentIcon class="mt-0.5 h-4 w-4" />
                    <span class="text-sm">
                      Pago por cada $1,000:
                      <b>{{ $filters.toCurrency(member.loanApplication.costPerThousand) }}
                        pesos</b>.
                    </span>
                  </div>
                  <div class="flex flex-row items-start gap-x-1.5 text-gray-100">
                    <CalendarDaysIcon class="mt-0.5 h-4 w-4" />
                    <span class="text-sm text-gray-100">
                      Pago semanal: <b>{{ $filters.toCurrency(member.loanApplication.repaymentAmount) }} pesos</b>.
                    </span>
                  </div>
                </template>
              </div>
              <div
                v-else-if="member.loanApplication.aasmState === 'submitted'"
                class="mt-2 flex flex-col gap-y-2"
              >
                <span class="text-sm text-white">
                  💸 Ha solicitado ${{ $filters.formatNumber(member.loanApplication.amount) }} pesos.
                </span>
                <span class="text-sm text-white">
                  📤 Solicitud enviada.
                </span>
              </div>
              <span
                v-else-if="member.loanApplication.aasmState === 'rejected'"
                class="mt-1 text-sm text-gray-100"
              >
                ❌ Solicitud rechazada por no cumplir con políticas de crédito.
              </span>
              <span
                v-else-if="member.loanApplication.aasmState === 'draft'"
                class="mt-1 text-sm text-gray-100"
              >
                📝 Aún no ha enviado su solicitud.
              </span>
            </div>
            <button
              v-if="isUserPresident && member.userId !== currentUser.id"
              type="button"
              class="ml-auto shrink-0 self-center rounded-full bg-red-500 p-1 text-white
                    ring-inset ring-white focus:ring-1"
              @click="() => onDelete(getMemberInvitation(member).id)"
            >
              <XMarkIcon class="h-3 w-3" />
            </button>
          </div>
          <div
            v-for="invitation in groupStore.groupPendingInvitations"
            :key="invitation.id"
            class="flex flex-row gap-x-2"
          >
            <base-avatar
              :user="{ firstName: invitation.invitee.firstName }"
            />
            <div class="flex flex-col gap-y-2">
              <span class="text-sm font-bold text-white">
                {{ invitation.invitee.firstName }}
              </span>
              <span class="text-sm text-gray-100">
                🕓 Aún no acepta su invitación
              </span>
            </div>
            <button
              v-if="isUserPresident"
              type="button"
              class="ml-auto shrink-0 self-center rounded-full bg-red-500 p-1 text-white
                    ring-inset ring-white focus:ring-1"
              @click="onDelete(invitation.id)"
            >
              <XMarkIcon class="h-3 w-3" />
            </button>
          </div>
          <base-button
            v-if="isUserPresident && ['inviting', 'draft'].includes(groupStore.groupLoanApplication.aasmState)"
            text="Agregar otro miembro al grupo"
            variant="white"
            class="self-start hover:ring-white"
            href="/group_invitations/new"
          />
        </div>
      </div>
    </template>
  </loan-application-step>
  <base-modal
    :show="askDeleteConfirmationModalOpen"
    :title="`Eliminar a ${memberToDelete?.invitee?.firstName} del grupo`"
    accept-button-text="Si, eliminar"
    cancel-button-text="Cancelar"
    :loading="isDeleteMemberPending"
    @accept="() => deleteMember(memberToDeleteId)"
    @close="askDeleteConfirmationModalOpen = false"
  />
</template>
