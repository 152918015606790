import { api } from './index';

const path = '/api/internal/valida_curp';

function validateCurp(curp: string) {
  return api({
    method: 'post',
    url: `${path}/curp_validations`,
    data: { curp },
  });
}

export default { validateCurp }
