<script setup>
import { ref, computed } from 'vue';
import { startOfWeek, endOfWeek, parseISO } from 'date-fns';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';

const props = defineProps({
  schedule: { type: Array, required: true },
});

const REPAYMENT_STATUSES = {
  late: 'LATE',
  pending: 'PENDING',
};

const now = new Date();
const startOfThisWeek = startOfWeek(now, { weekStartsOn: 1 }); // Adjust weekStartsOn based on your locale
const endOfThisWeek = endOfWeek(now, { weekStartsOn: 1 });

function isThisWeek(date) {
  const repaymentDate = parseISO(date);

  return repaymentDate >= startOfThisWeek && repaymentDate <= endOfThisWeek;
}

function repaymentStatus(repayment) {
  if (repayment.late) return REPAYMENT_STATUSES.late;
  if (isThisWeek(repayment.date) && !repayment.paid) return REPAYMENT_STATUSES.pending;

  return null;
}

const alreadyPaid = computed(() => props.schedule.filter((item) => item.paid));
const itemsToShow = ref(Math.max(3, alreadyPaid.value.length));
const visibleScheduleItems = computed(() => props.schedule.slice(0, itemsToShow.value));
</script>

<template>
  <div>
    <ul
      role="list"
      class="space-y-4"
    >
      <li
        v-for="(repayment, repaymentIdx) in visibleScheduleItems"
        :key="repaymentIdx.id"
        class="relative flex gap-x-4"
      >
        <div
          :class="[
            repaymentIdx === visibleScheduleItems.length - 1
              ? 'h-6'
              : '-bottom-6', 'absolute left-0 top-0 flex w-6 justify-center'
          ]"
        >
          <div class="w-px bg-gray-200" />
        </div>
        <div class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
          <CheckCircleIcon
            v-if="repayment.paid"
            class="h-6 w-6 text-primary-600"
            aria-hidden="true"
          />
          <div
            v-else
            class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"
          />
        </div>
        <div class="flex flex-col">
          <time
            :datetime="repayment.date"
            class="flex-none py-0.5 text-xs font-medium leading-5 text-gray-700"
          >
            {{ $filters.formatDate(repayment.date, "EEEE, dd MMMM yyyy") }}
          </time>
          <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
            {{ $filters.toCurrency(repayment.paid ? repayment.paidAmount : repayment.amount) }}
          </p>
        </div>
        <base-badge
          v-if="repaymentStatus(repayment)"
          :color="repaymentStatus(repayment) === REPAYMENT_STATUSES.late ? 'red' : 'gray'"
          :label="repaymentStatus(repayment) === REPAYMENT_STATUSES.late ? 'Tarde' : 'Pendiente'"
          size="xs"
          class="ml-auto"
        />
      </li>
    </ul>
    <button
      v-if="itemsToShow < schedule.length"
      type="button"
      class="mt-2 flex w-full items-center justify-center py-2 text-xs font-medium text-gray-500 hover:text-gray-600"
      @click="itemsToShow += 3"
    >
      Ver más
    </button>
  </div>
</template>

