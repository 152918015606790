<script setup>
import { inject } from 'vue';
import { object, string } from 'yup';
import { currentUserKey } from '@/utils/keys.js';
import UserAvatarInput from '@/components/user-avatar-input.vue';

const currentUser = inject(currentUserKey);

const userValidationSchema = object({
  'user[first_name]': string().required('El nombre es obligatorio'),
  'user[paternal_surname]': string().required('El apellido paterno es obligatorio'),
  'user[maternal_surname]': string().required('El apellido materno es obligatorio'),
  'user[phone_number]': string().mobilePhone(['es-MX', 'es-CL']).required(
    'El número de teléfono es obligatorio',
  ),
  'user[email]': string(),
});
</script>

<template>
  <div class="flex w-full flex-col">
    <base-back-button
      href="/"
    />
    <rails-flash-alerts />
    <div
      class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 px-4 sm:gap-y-10 sm:px-6 md:grid-cols-3 lg:px-8"
    >
      <h2 class="col-span-1 text-base font-semibold leading-7 text-gray-700">
        Tus datos
      </h2>
      <v-form-native-wrapper
        ref="formRef"
        class="col-span-2 flex w-full flex-col gap-y-4"
        :validation-schema="userValidationSchema"
        action="/profile"
        method="POST"
      >
        <user-avatar-input
          :user="currentUser"
          class="my-4"
          size="lg"
        />

        <base-short-text-input
          name="user[first_name]"
          :model-value="currentUser.firstName"
          label="Primer nombre"
        />

        <base-short-text-input
          name="user[paternal_surname]"
          :model-value="currentUser.paternalSurname"
          label="Primer apellido"
        />

        <base-short-text-input
          name="user[maternal_surname]"
          :model-value="currentUser.maternalSurname"
          label="Segundo apellido"
        />

        <base-short-text-input
          name="user[phone_number]"
          :model-value="currentUser.phoneNumber"
          label="Número de teléfono"
        />
        <base-short-text-input
          name="user[email]"
          :model-value="currentUser.email"
          label="Correo electrónico"
        />

        <base-button
          text="Guardar"
          type="submit"
          class="mt-4"
        />
      </v-form-native-wrapper>
      <v-form-native-wrapper
        method="delete"
        action="/users/sign_out"
        class="mt-10"
      >
        <base-button
          type="submit"
          variant="red"
          text="Salir de mi cuenta"
        />
      </v-form-native-wrapper>
    </div>
  </div>
</template>
