import { api } from '../index';

const path = '/api/internal/reviews/group_loan_applications';


function get(id: number) {
  return api({
    method: 'get',
    url: `${path}/${id}`,
  }).then((response: any) => response.groupLoanApplication);
}

function update(id: number, groupLoanApplication: object) {
  return api({
    method: 'put',
    url: `${path}/${id}`,
    data: { groupLoanApplication },
  });
}

export default { update, get };
