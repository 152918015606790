<script setup>
import { ref, watch, computed } from 'vue';
import { useQuery, useMutation } from '@tanstack/vue-query';
import { format } from 'date-fns';
import useUiStore from '@/stores/ui';
import groupInvitationApi from '@/api/reviews/groupInvitation.ts';
import groupLoanApplicationApi from '@/api/reviews/groupLoanApplication.ts';
import GroupLoanApplicationsMemberLoanInfo from
  '@/components/reviews/group-loan-applications-member-loan-info.vue';
import Comments from '@/components/comments.vue';
import GroupLoanApplicationExternalConnections from
  '@/components/reviews/group-loan-application-external-connections.vue';
import GroupLoanApplicationHeader from '@/components/reviews/group-loan-application-header.vue';
import GroupLoanApplicationCreditReportConsolidatedLoans from
  '@/components/reviews/group-loan-application-credit-report-consolidated-loans.vue';

const props = defineProps({
  groupLoanApplicationId: { type: Number, required: true },
  previousGroupLoanApplications: { type: Array, required: true },
  groupConnections: { type: Object, default: () => ({}) },
});

const uiStore = useUiStore();

const currentMemberLoanApplication = ref(null);
function setCurrentMemberLoanApplication(application) {
  currentMemberLoanApplication.value = application;
}

const {
  data: groupLoanApplication,
  isLoading: isFetchingGroupLoanApplication,
  refetch: refetchGroupLoanApplication,
} = useQuery({
  queryKey: ['groupLoanApplications', props.groupLoanApplicationId],
  queryFn: () => groupLoanApplicationApi.get(props.groupLoanApplicationId),
});

const group = computed(() => groupLoanApplication.value?.group);
const currentMember = computed(
  () => group.value?.members.find(
    member => member.id === currentMemberLoanApplication.value.groupMemberId,
  ),
);

watch(groupLoanApplication, (newGroupLoanApplication) => {
  if (newGroupLoanApplication) {
    setCurrentMemberLoanApplication(newGroupLoanApplication.memberLoanApplications[0]);
  }
});

const {
  mutate: resendInvitation,
  isPending: isSendinginvitation,
  variables: invitationId,
} = useMutation({
  mutationFn: (id) => groupInvitationApi.resend(id),
  onSuccess: () => {
    uiStore.toast({
      message: 'Invitación reenviada',
      type: 'success',
      position: 'top',
    });

    refetchGroupLoanApplication();
  },
  onError: () => {
    uiStore.toast({
      message: 'Hubo un error al reenviar la invitación',
      type: 'error',
      position: 'top',
    });
  },
});
</script>

<template>
  <div class="flex w-full flex-col gap-y-4">
    <div class="flex flex-row gap-x-4 rounded-lg bg-white p-4">
      <template v-if="isFetchingGroupLoanApplication">
        cargando...
      </template>
      <div
        v-else
        class="flex w-full flex-row gap-x-10"
      >
        <group-loan-application-header
          :group="group"
          :group-loan-application="groupLoanApplication"
          :previous-group-loan-applications="previousGroupLoanApplications"
          @refetch-group-loan-application="refetchGroupLoanApplication"
        />
        <div class="ml-auto w-[350px]">
          <comments
            title="Comentarios sobre el grupo"
            name="comment[body]"
            resource-type="GroupLoanApplication"
            :resource-id="props.groupLoanApplicationId"
          />
        </div>
      </div>
    </div>
    <group-loan-application-external-connections
      v-if="groupLoanApplication"
      :group-connections="groupConnections"
      :group="group"
    />
    <group-loan-application-credit-report-consolidated-loans
      v-if="groupLoanApplication"
      :group-loan-application="groupLoanApplication"
      :group-members="group.members"
    />
    <base-spinner
      v-else
      class="mt-5 h-3 w-3 self-center"
    />
    <div class="flex flex-row gap-x-4">
      <div class="flex w-56 shrink-0 flex-col gap-y-4">
        <div
          v-if="group?.pendingInvitations?.length > 0"
          class="flex flex-col rounded-lg bg-white p-2"
        >
          <template
            v-if="isFetchingGroupLoanApplication"
          >
            cargando...
          </template>
          <template v-else>
            <span class="mb-4 text-xs text-gray-600">
              Invitaciones pendientes
            </span>
            <div class="flex flex-col gap-y-4">
              <div
                v-for="invitation in group.pendingInvitations"
                :key="invitation.id"
                class="flex flex-row justify-between gap-x-2"
              >
                <div class="flex flex-col gap-y-2">
                  <span>
                    {{ invitation.invitee.firstName }}
                  </span>
                  <div class="flex flex-row items-center gap-x-2">
                    <span class="text-xs text-gray-700">
                      <b>ID:</b> {{ invitation.id }}
                    </span>
                    <span class="text-xs text-gray-700">
                      <b>Enviada:</b> {{ format(new Date(invitation.lastSentAt), 'dd/MM/yyyy HH:mm') }}
                    </span>
                  </div>
                  <base-button
                    size="xs"
                    text="reenviar"
                    class="self-start"
                    :loading="isSendinginvitation && invitationId === invitation.id"
                    @click="resendInvitation(invitation.id)"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="flex flex-col rounded-lg bg-white p-2">
          <template
            v-if="isFetchingGroupLoanApplication"
          >
            cargando...
          </template>
          <template v-else>
            <span class="mb-4 text-xs text-gray-600">
              Miembros del grupo
            </span>
            <button
              v-for="memberLoanApplication in groupLoanApplication.memberLoanApplications"
              :key="memberLoanApplication.id"
              :class="[
                '-mx-2 flex flex-col gap-y-1 px-2 py-4',
                memberLoanApplication.id === currentMemberLoanApplication.id
                  ? 'bg-primary-600 text-white'
                  : 'hover:bg-gray-100'
              ]"
              @click="setCurrentMemberLoanApplication(memberLoanApplication)"
            >
              <span class="text-left text-sm font-bold">
                {{ group.members.find(member => member.id === memberLoanApplication.groupMemberId).user.fullName }}
              </span>
              <div class="flex flex-row items-center gap-x-2">
                <span
                  :class="[
                    'text-xs',
                    memberLoanApplication.id === currentMemberLoanApplication.id ? 'text-gray-50' : 'text-gray-700'
                  ]"
                >
                  ID: {{ memberLoanApplication.publicId }}
                </span>
                <base-badge
                  v-if="!group.members.find(member => member.id === memberLoanApplication.groupMemberId).active"
                  color="red"
                  label="Inactivo"
                  size="xs"
                />
              </div>
              <span
                :class="[
                  'text-xs',
                  { 'text-gray-50': memberLoanApplication.id === currentMemberLoanApplication.id }
                ]"
              >
                {{ group.members.find(member => member.id === memberLoanApplication.groupMemberId).humanRole }}
              </span>
            </button>
          </template>
        </div>
      </div>
      <div class="flex w-full flex-col rounded-lg bg-white p-4">
        <template v-if="isFetchingGroupLoanApplication">
          cargando...
        </template>
        <template v-else>
          <group-loan-applications-member-loan-info
            :group-member="currentMember"
            :group-member-loan-application="currentMemberLoanApplication"
          />
        </template>
      </div>
    </div>
  </div>
</template>
