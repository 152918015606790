<script setup>
import { computed } from 'vue';
import { object, string } from 'yup';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import WspHelp from '@/components/wsp-help.vue';

const props = defineProps({
  promoter: { type: Object, default: null },
  referrer: { type: Object, default: null },
});

const validationSchema = object({
  name: string().required('El grupo debe tener un nombre'),
});

const formAction = computed(() => {
  if (props.promoter) {
    return `/promoters/${props.promoter.id}/groups`;
  } else if (props.referrer) {
    return `/referrals/groups/new?referrer_id=${props.referrer.id}`;
  }

  return '/groups';
});
</script>

<template>
  <div class="container flex w-full max-w-xl flex-col items-center">
    <rails-flash-alerts />
    <div class="flex flex-row gap-x-4 rounded-xl bg-white p-4 sm:gap-x-6 sm:p-6">
      <base-icon-box
        :src="ExclamationCircleIcon"
        color="light-blue"
        size="sm"
      />
      <div class="flex flex-col gap-y-4">
        <p class="text-xs text-gray-700 sm:text-sm">
          Si estas buscando unirte a un grupo, <b>NO crees un grupo nuevo</b>.
          El presidente del grupo debe agregarte como integrante y te llegará un
          mensaje a tu celular para que aceptes la invitación.
        </p>
        <span
          v-if="promoter"
          class="text-sm text-gray-600"
        >
          El asesor encargado del grupo es <b>{{ promoter.firstName }}</b>. Puedes comunicarte con él si
          tienes alguna duda, su número es <b>{{ promoter.phoneNumber }}</b>.
        </span>
      </div>
    </div>
    <v-form-native-wrapper
      class="mt-8 flex w-full flex-col gap-y-6 sm:mt-12 sm:gap-y-10"
      :validation-schema="validationSchema"
      :action="formAction"
      method="POST"
    >
      <h2 class="text-center text-xl font-bold">
        Crear grupo
      </h2>
      <base-short-text-input
        name="name"
        label="Nombre del grupo"
        placeholder="Ej: Grupo de vecinos de la calle 1"
        class="mt-8"
      />
      <base-button
        text="Crear grupo"
        type="submit"
      />
      <wsp-help />
    </v-form-native-wrapper>
  </div>
</template>
