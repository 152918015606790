<script setup>
import { reactive } from 'vue';
import { IdentificationIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { object, mixed } from 'yup';
import BaseDisclosure from '@/components/base-disclosure.vue';
import BaseImageInputWithExample from '@/components/base-image-input-with-example.vue';

defineProps({
  groupMemberLoanApplication: { type: Object, required: true },
});

const imageFileValidationSchema = mixed()
  .required('Debes subir una imagen')
  .file('Debes subir una imagen')
  .fileType(
    ['image/jpeg', 'image/jpg', 'image/png'],
    'El archivo debe ser una imagen JPEG, JPG o PNG',
  );

const kycForm = reactive({
  idFront: null,
  idBack: null,
  idSelfie: null,
  addressProof: null,
  validationSchema: object().shape({
    'group_member_loan_application[id_front]': imageFileValidationSchema,
    'group_member_loan_application[id_back]': imageFileValidationSchema,
    'group_member_loan_application[id_selfie]': imageFileValidationSchema,
    'group_member_loan_application[address_proof]': imageFileValidationSchema,
  }),
});
</script>

<template>
  <div class="flex w-full flex-col">
    <base-back-button
      href="/"
    />
    <div class="mt-12 flex flex-row gap-x-4 rounded-xl bg-white p-4 sm:gap-x-6 sm:p-6">
      <base-icon-box
        :src="IdentificationIcon"
        color="light-blue"
        size="xs"
      />
      <div class="flex flex-col">
        <h1 class="text-sm font-bold">
          Validación de identidad
        </h1>
        <p class="mt-4 text-sm text-gray-700">
          Ahora debes subir algunas imagenes para que podamos validar tu identidad.
        </p>
      </div>
    </div>
    <v-form-native-wrapper
      :validation-schema="kycForm.validationSchema"
      class="mt-6 flex flex-col gap-y-8"
      action="/group_member_loan_application/kyc_images"
      method="PATCH"
      enctype="multipart/form-data"
    >
      <base-disclosure
        :unmount="false"
        class="bg-white"
        button-text="Foto de tu INE por delante"
      >
        <template
          v-if="kycForm.idFront"
          #button
        >
          <div class="flex flex-row items-center gap-x-4">
            <div class="h-5 w-5 rounded-full bg-green-400 p-1">
              <CheckIcon
                class="h-full w-full text-white"
              />
            </div>
            <span>
              Foto de tu INE por delante
            </span>
          </div>
        </template>
        <template #default>
          <base-image-input-with-example
            name="group_member_loan_application[id_front]"
            preview-img-name="id-front-blurred"
            :value="groupMemberLoanApplication.idFrontUrl"
            compressed
            @update:model-value="value => kycForm.idFront = value"
          />
        </template>
      </base-disclosure>
      <base-disclosure
        :unmount="false"
        class="bg-white"
        button-text="Foto de tu INE por atrás"
      >
        <template
          v-if="kycForm.idBack"
          #button
        >
          <div class="flex flex-row items-center gap-x-4">
            <div class="h-5 w-5 rounded-full bg-green-400 p-1">
              <CheckIcon
                class="h-full w-full text-white"
              />
            </div>
            <span>
              Foto de tu INE por atrás
            </span>
          </div>
        </template>
        <template #default>
          <base-image-input-with-example
            name="group_member_loan_application[id_back]"
            preview-img-name="id-back-blurred"
            :value="groupMemberLoanApplication.idBackUrl"
            compressed
            @update:model-value="value => kycForm.idBack = value"
          />
        </template>
      </base-disclosure>
      <base-disclosure
        :unmount="false"
        class="bg-white"
        button-text="Selfie con tu INE"
      >
        <template
          v-if="kycForm.idSelfie"
          #button
        >
          <div class="flex flex-row items-center gap-x-4">
            <div class="h-5 w-5 rounded-full bg-green-400 p-1">
              <CheckIcon
                class="h-full w-full text-white"
              />
            </div>
            <span>
              Selfie con tu INE
            </span>
          </div>
        </template>
        <template #default>
          <base-image-input-with-example
            description="Toma una foto de tu rostro mostrando tu INE"
            name="group_member_loan_application[id_selfie]"
            preview-img-name="id-selfie-blurred"
            :value="groupMemberLoanApplication.idSelfieUrl"
            compressed
            @update:model-value="value => kycForm.idSelfie = value"
          />
        </template>
      </base-disclosure>
      <base-disclosure
        :unmount="false"
        class="bg-white"
        button-text="Foto de tu comprobante de domicilio"
      >
        <template
          v-if="kycForm.addressProof"
          #button
        >
          <div class="flex flex-row items-center gap-x-4">
            <div class="h-5 w-5 rounded-full bg-green-400 p-1">
              <CheckIcon
                class="h-full w-full text-white"
              />
            </div>
            <span>
              Foto de tu comprobante de domicilio
            </span>
          </div>
        </template>
        <template #default>
          <base-image-input-with-example
            name="group_member_loan_application[address_proof]"
            preview-img-name="address-proof-blurred"
            :value="groupMemberLoanApplication.addressProofUrl"
            compressed
            @update:model-value="value => kycForm.addressProof = value"
          />
        </template>
      </base-disclosure>
      <base-button
        type="submit"
        class="mt-10"
        text="Siguiente"
      />
    </v-form-native-wrapper>
  </div>
</template>
