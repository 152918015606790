<script setup>
import { computed, inject } from 'vue';
import {
  ClockIcon,
  CheckCircleIcon,
  CalendarDaysIcon,
  CheckIcon,
  ArrowLongRightIcon,
  InformationCircleIcon,
  ReceiptPercentIcon,
  BanknotesIcon,
} from '@heroicons/vue/24/outline';
import LoanApplicationStep from './loan-application-step.vue';
import { currentUserKey } from '../utils/keys.js';
import useGroupStore from '../stores/group.js';

defineProps({
  isOpen: { type: Boolean, default: false },
});

const currentUser = inject(currentUserKey);
const groupStore = useGroupStore();

const currentUserLoanApplication = computed(() => groupStore.currentUserMember?.loanApplication);
const approvedMembers = computed(
  () => groupStore.groupMembers.filter(
    (member) => member.loanApplication?.preApproved,
  ),
);

const badgeClasses = computed(() => {
  const classes = {
    'in_kyc': 'border-white text-white',
    'kyc_in_review': 'bg-gray-50 text-primary-600 border-primary-200',
    'kyc_rejected': 'bg-red-500 text-white',
  };

  return classes[groupStore.groupLoanApplication?.aasmState];
});

const kycStatusText = computed(() => {
  const aasmState = groupStore.groupLoanApplication?.aasmState;
  if (aasmState === 'in_kyc') return 'Pendiente';
  else if (aasmState === 'kyc_in_review') return 'En revisión';
  else if (aasmState === 'kyc_rejected') return 'Rechazada';

  return null;
});
</script>

<template>
  <loan-application-step
    :step="2"
    step-title="Sube tus documentos"
    :is-open="isOpen"
  >
    <template #badge>
      <span
        v-if="kycStatusText"
        :class="['flex flex-row items-center gap-x-2 rounded-md border px-2 py-1 text-xs', badgeClasses]"
      >
        <ClockIcon
          v-if="groupStore.groupLoanApplication.aasmState === 'in_kyc'"
          class="h-4 w-4"
        />
        {{ kycStatusText }}
      </span>
      <span
        v-else
        class="flex w-5 items-center justify-center rounded-full bg-green-400 p-1"
      >
        <check-icon class="h-full w-full text-white" />
      </span>
    </template>
    <template #default>
      <div class="mt-4 flex flex-col gap-y-10 rounded-lg bg-gray-50 px-4 py-3">
        <div class="flex flex-row gap-x-4">
          <base-icon-box
            color="green"
            size="sm"
            :src="CheckCircleIcon"
          />
          <div class="flex flex-col gap-y-3">
            <span class="text-xs font-bold text-black">
              Tu solicitud ha sido pre-aprobada 🎉
            </span>
            <div class="flex flex-col gap-y-1">
              <div class="flex flex-row items-start gap-x-1.5 text-gray-700">
                <BanknotesIcon class="mt-0.5 h-4 w-4" />
                <span class="text-sm">
                  Monto aprobado: <b>{{ $filters.toCurrency(currentUserLoanApplication.acceptedAmount) }} pesos</b>.
                </span>
              </div>
              <div class="flex flex-row items-start gap-x-1.5 text-gray-700">
                <ReceiptPercentIcon class="mt-0.5 h-4 w-4" />
                <span class="text-sm">
                  Pago por cada $1,000:
                  <b>{{ $filters.toCurrency(currentUserLoanApplication.costPerThousand) }}
                    pesos</b>.
                </span>
              </div>
              <div class="flex flex-row items-start gap-x-1.5 text-gray-700">
                <CalendarDaysIcon class="mt-0.5 h-4 w-4" />
                <span class="text-sm">
                  Pago semanal: <b>{{ $filters.toCurrency(currentUserLoanApplication.repaymentAmount) }} pesos</b>.
                </span>
              </div>
            </div>
          </div>
        </div>
        <p
          v-if="currentUserLoanApplication.kycSubmittedAt && groupStore.groupLoanApplication.cycle === 1"
          class="text-sm font-semibold text-gray-700"
        >
          Ahora el asesor se pondrá de acuerdo con el grupo para realizar la reunión de
          autorización.
        </p>
        <div
          v-else-if="currentUserLoanApplication.aasmState === 'in_kyc'"
          class="flex flex-col gap-y-6 rounded-lg bg-gray-50"
        >
          <div class="flex flex-row gap-x-4">
            <base-icon-box
              color="cyan"
              size="sm"
              :src="InformationCircleIcon"
            />
            <div class="flex flex-col gap-y-3">
              <span class="text-xs font-bold text-black">
                Aún no subes tus documentos.
              </span>
              <p class="text-xs text-black">
                Para autorizar su crédito, todo el grupo debe subir
                fotos de su INE y comprobante de domicilio.
              </p>
            </div>
          </div>
          <base-button
            :right-icon="ArrowLongRightIcon"
            href="/group_member_loan_application/kyc_images/edit"
            text="Subir documentos"
            class="text-xs font-semibold text-white"
          />
        </div>
      </div>
      <div class="mt-6 flex flex-col">
        <span class="text-white">
          Integrantes del grupo
        </span>
        <div class="mt-4 flex flex-col gap-y-6">
          <div
            v-for="member in approvedMembers"
            :key="member.id"
            class="flex w-full flex-row gap-x-2"
          >
            <base-avatar
              :user="member.user"
            />
            <div class="flex flex-col">
              <div class="flex flex-row items-center gap-x-4">
                <span class="text-sm font-bold text-white">
                  {{ member.user.firstName }}
                </span>
                <span
                  v-if="member.president"
                  class="text-xs italic text-gray-200"
                >
                  (Presidente)
                </span>
                <span
                  v-else-if="member.userId === currentUser.id"
                  class="text-xs italic text-gray-200"
                >
                  (Tú)
                </span>
              </div>
              <div
                v-if="member.loanApplication.kycSubmittedAt"
                class="mt-2 flex flex-col gap-y-2"
              >
                <span class="text-sm text-white">
                  ✅ Ya subió sus documentos.
                </span>
              </div>
              <span
                v-else
                class="mt-1 text-sm text-gray-100"
              >
                📥 Aún no sube sus documentos.
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </loan-application-step>
</template>
