import { api } from '../index';

function resend(id: number) {
  return api({
    method: 'post',
    url: `/api/internal/reviews/group_invitations/${id}/resends`,
  });
}

export default { resend };
