<script setup>
import { computed, inject } from 'vue';
import { LinkIcon, TrashIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import BaseRoleBadge from '@/components/base-role-badge.vue';
import BaseKebabMenu from '@/components/base-kebab-menu.vue';
import { screenKey } from '@/utils/keys.js';

const emit = defineEmits(['delete']);
const props = defineProps({
  pendingInvitations: { type: Array, default: () => [] },
  groupMembers: { type: Array, required: true },
  groupPresident: { type: Object, required: true },
  canDelete: { type: Boolean, default: false },
});

const screen = inject(screenKey);

const nonPresidentMembers = computed(() => props.groupMembers.filter((member) => member.role !== 'president'));

function buildInvitationUrlMessage(invitation) {
  const baseWhatsAppUrl = `https://wa.me/${invitation.invitee.phoneNumber}`;
  const message = encodeURIComponent('Hola, te invito a unirte a mi grupo en *Grupalia*,' +
    ` una plataforma para solicitar créditos grupales. Aquí va el link: ${invitation.invitationUrl}`,
  );

  return `${baseWhatsAppUrl}?text=${message}`;
}

const pendingInvitationsKebabMenuItems = computed(() => props.pendingInvitations.map(invitation => {
  const items = [
    {
      name: 'copy-link-invite',
      icon: LinkIcon,
      label: 'Enviar link de invitación',
      onClick: () => {
        const message = buildInvitationUrlMessage(invitation);
        window.open(message, '_blank');
      },
    },
  ];

  if (props.canDelete) {
    items.push({
      name: 'destroy',
      icon: TrashIcon,
      label: 'Eliminar invitación',
      onClick: () => {
        emit('delete', invitation.id);
      },
    });
  }

  return items;
}));

const groupMembersKebabMenuItems = computed(
  () => nonPresidentMembers.value.map(member => [
    {
      name: 'destroy',
      icon: TrashIcon,
      label: 'Eliminar miembro',
      onClick: () => {
        emit('delete', member.invitation.id);
      },
    },
  ],
  ));

</script>

<template>
  <div class="flex flex-col items-center gap-y-16">
    <div class="flex flex-row gap-x-2 text-primary-600">
      <base-image
        img-name="user-key"
        alt="Icono de usuario presidente"
        class="h-6 w-6 shrink-0 fill-current"
      />
      <div class="flex flex-col gap-y-2">
        <div class="flex flex-row items-center gap-x-2">
          <span class="text-xs font-semibold sm:text-sm">
            {{ groupPresident.firstName }} {{ groupPresident.lastName }}
          </span>
          <base-role-badge
            role="president"
            size="xxs"
            color="primary"
          />
        </div>
        <span class="text-xs sm:text-sm">
          {{ groupPresident.phoneNumber }}
        </span>
      </div>
    </div>
    <div
      v-if="pendingInvitations.length > 0"
      class="flex flex-col items-center gap-y-10"
    >
      <span class="text-xs font-semibold">
        Falta que acepten su invitación
      </span>
      <ul class="grid grid-cols-1 items-center gap-8 text-gray-900 sm:grid-cols-2 sm:gap-12">
        <li
          v-for="(invitation, index) in pendingInvitations"
          :key="invitation.id"
          class="flex w-full flex-row justify-between gap-x-20 sm:gap-x-12"
        >
          <div class="flex flex-col gap-y-1">
            <div class="flex flex-row gap-x-1">
              <base-image
                img-name="user-timer"
                alt="Icono de usuario"
                class="h-6 w-6 shrink-0 fill-current"
              />
              <div class="flex flex-col gap-y-1">
                <div class="flex flex-row items-center gap-x-3">
                  <span class="text-xs font-semibold sm:text-sm">
                    {{ invitation.invitee.firstName }}
                  </span>
                  <base-role-badge
                    v-if="invitation.role === 'vice_president'"
                    role="vice_president"
                    size="xxs"
                  />
                </div>
                <span
                  :class="[
                    'text-xs sm:text-sm',
                    { 'text-blue-400': !invitation.invitee.phoneNumberValidated }
                  ]"
                >
                  {{ invitation.invitee.phoneNumber }}
                </span>
              </div>
            </div>
            <div
              v-if="!invitation.invitee.phoneNumberValidated"
              v-tooltip="`Asegúrate de que el número de teléfono sea
                            correcto y la persona tenga Whatsapp`"
              class="flex flex-row gap-x-2"
            >
              <InformationCircleIcon
                class="h-4 w-4 shrink-0 text-blue-400"
              />
              <span
                class="text-xs text-blue-400"
              >
                No hemos podido validar el número de teléfono.
              </span>
            </div>
          </div>
          <base-kebab-menu
            :items="pendingInvitationsKebabMenuItems[index]"
            :floating-menu-width-class="screen.isMobile ? 'w-48': 'w-56'"
          />
        </li>
      </ul>
    </div>
    <div
      v-if="nonPresidentMembers.length > 0"
      class="flex flex-col gap-y-4"
    >
      <span class="text-xs font-semibold text-green-400">
        Ya aceptaron su invitación
      </span>
      <ul class="grid grid-cols-2 gap-8 text-green-400 sm:grid-cols-3 sm:gap-12">
        <li
          v-for="(member, index) in nonPresidentMembers"
          :key="member.id"
          class="flex flex-row gap-x-2"
        >
          <base-image
            img-name="user-check"
            alt="Icono de usuario"
            class="h-6 w-6 shrink-0 fill-current"
          />
          <div class="flex flex-col">
            <div class="flex flex-row items-center gap-x-3">
              <span class="text-xs font-semibold sm:text-sm">
                {{ member.user.firstName }}
              </span>
              <base-role-badge
                v-if="member.role === 'vice_president'"
                role="vice_president"
                size="xxs"
                color="green"
              />
            </div>
            <span class="text-xs sm:text-sm">
              {{ member.user.phoneNumber }}
            </span>
          </div>
          <base-kebab-menu
            v-if="canDelete"
            :items="groupMembersKebabMenuItems[index]"
            :floating-menu-width-class="screen.isMobile ? 'w-40': 'w-44'"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
