<script setup>
import { ref, watch } from 'vue';
import { useField } from 'vee-validate';
import useFilteredAttrs from '@/composables/useFilteredAttrs.ts';
import useErrorBounceOnFormSubmit from '@/composables/useErrorBounceOnFormSubmit.js';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  name: { type: String, required: true },
  options: { type: Array, required: true },
  selected: { type: [String, Number], default: '' },
  value: { type: [String, Number], default: '' },
  modelValue: { type: [String, Number], default: '' },
  error: { type: String, default: '' },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md'].includes(value),
  },
});

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

const {
  value: inputValue,
  validate,
  errorMessage,
  handleChange,
} = useField(() => props.name, undefined, { initialValue: props.modelValue || props.value });

watch(inputValue, (newVal) => {
  handleChange(newVal);
  emit('update:modelValue', newVal);
});

const errorRef = ref(null);
useErrorBounceOnFormSubmit(errorRef);
</script>

<template>
  <div :class="$attrs.class">
    <label
      v-if="label"
      :for="name"
      :class="[
        'block text-left font-medium leading-6 text-gray-900',
        {
          'text-xs': size === 'sm',
          'text-sm': size === 'md',
        },
      ]"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
    >
      {{ description }}
    </p>
    <div class="mt-2 flex flex-col">
      <select
        v-bind="filteredAttrs"
        :name="name"
        :value="inputValue"
        :class="[
          `block w-full rounded-md border border-transparent
            pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 transition duration-300 ease-in-out
            hover:border-purple-500 focus:ring-2 focus:ring-primary-600 sm:leading-6`,
          {
            'py-1 pl-1.5 text-xs': size === 'sm',
            'py-1.5 pl-3 text-sm': size === 'md',
          }
        ]"
        @input="handleChange"
        @blur="validate"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <span
        v-if="errorMessage || error"
        ref="errorRef"
        class="mt-1 text-xs text-red-500 sm:text-sm"
      >
        {{ errorMessage || error }}
      </span>
    </div>
  </div>
</template>
