import { api } from '@/api/index';

export default {
  get({ phoneNumber, account }) {
    const path = `/api/internal/crm/whatsapp/conversations/${phoneNumber}?account=${account}`;

    return api({
      url: path,
    });
  },
  sendReply({ recipientPhone, text, account }) {
    const path = `/api/internal/crm/whatsapp/replies?account=${account}`;

    return api({
      method: 'POST',
      url: path,
      data: { recipientPhone, text },
    });
  },
  markAsRead({ phoneNumber, account }) {
    const path = `/api/internal/crm/whatsapp/conversations/${phoneNumber}/read?account=${account}`;

    return api({
      method: 'POST',
      url: path,
    });
  },
};
