<script setup>
import { object, string } from 'yup';
import {
  UserPlusIcon,
  UsersIcon,
  GiftIcon,
  InformationCircleIcon,
  DevicePhoneMobileIcon,
} from '@heroicons/vue/24/outline';

const referralSteps = [
  {
    text: 'Ingresa el número de WhatsApp de la presidenta',
    icon: DevicePhoneMobileIcon,
  },
  {
    text: 'La presidenta recibe un mensaje de WhatsApp con una liga para formar su grupo',
    icon: UserPlusIcon,
  },
  {
    text: 'La presidenta forma su grupo',
    icon: UsersIcon,
  },
  {
    text: 'Si aprobamos su crédito grupal, <b>¡Recibe tu premio de $500 pesos!</b>',
    icon: GiftIcon,
  },
];

const phoneNumberSchema = string()
  .mobilePhone(['es-MX', 'es-CL'])
  .required('Número de teléfono es obligatorio');

const schema = object({
  'phone_number': phoneNumberSchema,
});
</script>

<template>
  <div class="flex flex-col">
    <base-back-button
      href="/"
    />
    <rails-flash-alerts />
    <h2 class="col-span-1 text-base font-semibold leading-7 text-gray-700">
      Invita conocidos a <b class="text-primary-600">Grupalia</b> y recibe $500 de regalo
    </h2>
    <p class="mt-2 text-sm text-gray-700">
      Si aprobamos su crédito grupal, te regalamos $500 pesos!
    </p>
    <div class="mt-10 flex w-full flex-col gap-y-12">
      <ul class="flex flex-col gap-y-6">
        <li
          v-for="(step, index) in referralSteps"
          :key="index"
          class="flex flex-row items-center gap-x-4"
        >
          <div class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-primary-100 p-2.5">
            <component
              :is="step.icon"
              class="h-full w-full text-primary-600"
            />
          </div>
          <span
            class="text-sm text-gray-700"
            v-html="step.text"
          />
        </li>
      </ul>
      <div class="flex flex-row items-start gap-x-2">
        <InformationCircleIcon
          class="h-5 w-5 shrink-0 text-blue-600"
        />
        <span class="mt-0.5 text-xs italic text-blue-400">
          para recibir el premio debes contar con un crédito en Grupalia y estar al día
          con todos los pagos de tu grupo.
        </span>
      </div>
    </div>

    <v-form-native-wrapper
      method="post"
      action="/referrals"
      :validation-schema="schema"
      class="mt-10 flex flex-col gap-y-4"
    >
      <base-phone-input
        name="phone_number"
        label="Número de WhatsApp"
        :validate-on-blur="false"
      />
      <base-button
        type="submit"
        text="Enviar invitación"
      />
    </v-form-native-wrapper>
  </div>
</template>
