<script setup>
import { ref, inject, watch, computed, onMounted, onUnmounted } from 'vue';
import { object, string } from 'yup';
import { useIntersectionObserver } from '@vueuse/core';
import * as Sentry from '@sentry/vue';
import { useMutation } from '@tanstack/vue-query';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import GroupInvitationsGrid from '@/components/group-invitations-grid.vue';
import WspHelp from '@/components/wsp-help.vue';
import BaseModal from '@/components/base-modal.vue';
import AppDownloadOverlay from '@/components/app-download-overlay.vue';
import groupInvitationApi from '@/api/groupInvitation';
import { currentUserKey } from '@/utils/keys.js';
import useUIStore from '@/stores/ui';
import usePhoneNumberValidation from '@/composables/usePhoneNumberValidation';

const props = defineProps({
  groupInvitations: { type: Array, default: () => [] },
  groupMembers: { type: Array, required: true },
  group: { type: Object, default: null },
  groupPresident: { type: Object, required: true },
  minGroupInvitations: { type: Number, required: true },
});

const uiStore = useUIStore();

const formRef = ref(null);
const isContactPickerAvailable = 'contacts' in navigator;

// eslint-disable-next-line max-statements
async function pickContact() {
  if (!isContactPickerAvailable) {
    return;
  }

  try {
    const contacts = await navigator.contacts.select(['name', 'tel'], { multiple: false });

    if (contacts.length > 0) {
      const contact = contacts[0];
      const name = contact.name[0];
      const phoneNumber = contact.tel[0];

      formRef.value.vFormRef.innerRef.setFieldValue('group_invitation[first_name]', name);
      formRef.value.vFormRef.innerRef.setFieldValue('group_invitation[phone_number]', phoneNumber);
    }
  } catch (error) {
    Sentry.captureException(error);

    uiStore.toast({
      message: 'Hubo un error al seleccionar el contacto',
      type: 'error',
      position: 'top',
    });
  }
}

const showGroupCreatedMessage = ref(false);
onMounted(() => {
  const currentUrl = new URL(window.location.href);
  const queryParams = currentUrl.searchParams;

  if (queryParams.get('show_group_created_message') === 'true') {
    showGroupCreatedMessage.value = true;

    queryParams.delete('show_group_created_message');
    const newUrl = `${currentUrl.pathname}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;

    window.history.pushState({}, '', newUrl);
  }
});

const dynamicGroupInvitations = ref([...props.groupInvitations]);
const dynamicGroupMembers = ref([...props.groupMembers]);

const validationSchema = object({
  'group_invitation[first_name]': string().required('El nombre es obligatorio'),
  'group_invitation[phone_number]': string().mobilePhone(['es-MX', 'es-CL']).required(
    'Necesitamos un número de teléfono de Whatsapp para enviar la invitación',
  ),
  'group_invitation[relationship]': string().required('La relación es obligatoria'),
  'group_invitation[years_known]': string().required('Los años de conocerse son obligatorios'),
});

const currentUser = inject(currentUserKey);
const isGroupPresident = computed(() => currentUser.value.id === props.groupPresident.id);

const invitationToEditId = ref(null);
const invitationToEdit = computed(() => dynamicGroupInvitations.value.find(inv => inv.id === invitationToEditId.value));
const askDeleteConfirmationModalOpen = ref(false);
function onDelete(id) {
  askDeleteConfirmationModalOpen.value = true;
  invitationToEditId.value = id;
}

const isUserPresident = computed(() => currentUser.value.id === props.groupPresident.id);

const { mutate: deleteInvitation, isPending: isDeleteInvitationPending } = useMutation({
  mutationFn: (id) => groupInvitationApi.destroy(id),
  onSuccess: (_, id) => {
    dynamicGroupInvitations.value = dynamicGroupInvitations.value.filter(
      (invitation) => invitation.id !== id,
    );

    dynamicGroupMembers.value = dynamicGroupMembers.value.filter(
      (member) => member.groupInvitationId !== id,
    );

    uiStore.toast({
      message: 'Miembro eliminado',
      type: 'success',
      duration: 5000,
      position: 'bottom',
    });

    askDeleteConfirmationModalOpen.value = false;
  },
});

const {
  state: phoneNumberValidationState,
  validate: validatePhoneNumber,
} = usePhoneNumberValidation();

function performPhoneValidation() {
  formRef.value.vFormRef.innerRef.validateField('group_invitation[phone_number]').then(({ valid }) => {
    if (valid) {
      const fields = formRef.value.vFormRef.innerRef.getValues();
      validatePhoneNumber(fields['group_invitation[phone_number]']);
    }
  });
}

const hasMinimumInvitations = computed(
  // in cases where the group was created through a reestructure, so there's no
  // group invitations, we check if it has enough group members
  () => props.groupMembers.length >= props.minGroupInvitations ||
    dynamicGroupInvitations.value.length >= props.minGroupInvitations - 1,
);

const finishInvitationStepFormRef = ref(null);
const bannerVisible = ref(true);

// Method to handle scroll events
function handleScroll() {
  const threshold = 100;
  bannerVisible.value = window.scrollY < threshold;
}

onMounted(() => {
  // Initially set the banner based on the button state
  if (!hasMinimumInvitations.value) {
    window.addEventListener('scroll', handleScroll);
  }

  // Reactively add/remove the scroll listener based on the button state
  watch(hasMinimumInvitations, (newValue, oldValue) => {
    if (newValue === oldValue) return;

    if (newValue) {
      // Button is enabled, remove the scroll listener
      window.removeEventListener('scroll', handleScroll);
      // Make the banner visible again when the button is enabled
      bannerVisible.value = true;
    } else {
      // Button is disabled, add the scroll listener
      window.addEventListener('scroll', handleScroll);
      // Check immediately upon change to adjust the banner's visibility
      handleScroll();
    }
  });
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

useIntersectionObserver(
  finishInvitationStepFormRef,
  ([{ isIntersecting }], _observerElement) => {
    if (hasMinimumInvitations.value) {
      bannerVisible.value = !isIntersecting;
    }
  },
  {
    threshold: 0.1,
  },
);
</script>

<template>
  <div class="container flex w-full max-w-xl flex-col items-center">
    <rails-flash-alerts />
    <div
      v-if="showGroupCreatedMessage"
      class="flex flex-row gap-x-4 rounded-xl bg-white p-4 sm:gap-x-6 sm:p-6"
    >
      <base-icon-box
        :src="CheckCircleIcon"
        color="green"
        size="sm"
      />
      <div class="flex flex-col gap-y-4">
        <span class="text-xs font-bold sm:text-sm">
          Grupo creado
        </span>
        <p
          v-if="isGroupPresident"
          class="text-xs text-gray-700 sm:text-sm"
        >
          Ahora debes invitar miembros a tu grupo. Recuerda que el grupo debe
          estar conformado por un <b>mínimo de 8 miembros</b>.
        </p>
        <p
          v-else
          class="text-xs text-gray-700 sm:text-sm"
        >
          Ahora debes esperar que <b>{{ groupPresident.firstName }}</b> termine de
          invitar miembros al grupo.
        </p>
      </div>
    </div>
    <span class="mt-10 text-lg font-bold sm:mt-16">
      {{ group.name }}
    </span>
    <group-invitations-grid
      v-if="dynamicGroupInvitations"
      :pending-invitations="dynamicGroupInvitations.filter(inv => inv.status === 'pending')"
      :group-members="dynamicGroupMembers"
      :group-president="groupPresident"
      :can-delete="isUserPresident"
      class="mt-12"
      @delete="onDelete"
    />
    <div
      v-if="isGroupPresident"
      class="mt-16 flex w-full flex-col items-center gap-y-8 sm:mt-12"
    >
      <v-form-native-wrapper
        v-slot="{ values }"
        ref="formRef"
        class="flex w-full flex-col"
        :validation-schema="validationSchema"
        action="/group_invitations"
        method="POST"
      >
        <h2 class="text-center text-xl font-bold">
          Invitar miembros al grupo
        </h2>
        <div class="mt-12 flex flex-col gap-y-4">
          <base-short-text-input
            name="group_invitation[first_name]"
            label="Primer nombre de la persona"
            placeholder="Ej: Sofía"
          />
          <div class="flex flex-col gap-y-2">
            <base-phone-input
              name="group_invitation[phone_number]"
              label="Número de Whatsapp"
              :validating="phoneNumberValidationState.loading"
              :validated="phoneNumberValidationState.validated"
              @blur="performPhoneValidation"
              @input="phoneNumberValidationState.loading = false"
            />
            <template v-if="isContactPickerAvailable">
              <div class="flex flex-row items-center gap-x-2">
                <div class="h-0.5 grow bg-gray-300" />
                <span class="text-sm text-gray-300">
                  ó
                </span>
                <div class="h-0.5 grow bg-gray-300" />
              </div>
              <base-button
                text="Seleccionar desde mis contactos"
                type="button"
                variant="white"
                @click="pickContact"
              />
            </template>
          </div>
          <base-native-select
            name="group_invitation[relationship]"
            :label="`¿Qué relación tienes con ${values['user[first_name]'] || 'esta persona'}?`"
            :options="[
              { label: 'Familiar cercano (hermana/a,  madre, hija/o, padre) ', value: 'close_relative' },
              { label: 'Familiar segundo (prima/o, tía, abuela/o)', value: 'distant_relative' },
              { label: 'Familiar político (cuñada/o, suegra/o, yerna/o)', value: 'in_law_relative' },
              { label: 'Pareja (esposo/a, novio/a)', value: 'partner' },
              { label: 'Amistad', value: 'friend' },
              { label: 'Vecino/a', value: 'neighbor' },
              { label: 'Conocido/a', value: 'acquaintance' },
            ]"
          />
          <base-native-select
            name="group_invitation[years_known]"
            :label="`¿Hace cuánto conoces a ${values['user[first_name]'] || 'esta persona'}?`"
            :options="[
              { label: 'Menos de 1 año', value: 'one_or_less' },
              { label: '1 - 2 años', value: 'one_to_two' },
              { label: '3 - 5 años', value: 'three_to_five' },
              { label: '5 - 10 años', value: 'five_to_ten' },
              { label: 'Más de 10 años', value: 'ten_or_more' },
            ]"
          />
        </div>
        <base-button
          text="Invitar"
          type="submit"
          class="mt-8"
        />
      </v-form-native-wrapper>
      <v-form-native-wrapper
        ref="finishInvitationStepFormRef"
        action="/group_member_loan_application/start_application"
        method="POST"
      >
        <base-button
          v-tooltip="!hasMinimumInvitations
            ? `Debes invitar al menos ${minGroupInvitations - (dynamicGroupInvitations.length + 1)} personas para poder continuar`
            : ''"
          type="submit"
          class="text-sm font-bold text-primary-600"
          text="Terminé de invitar"
          variant="text"
          :disabled="!hasMinimumInvitations"
        />
      </v-form-native-wrapper>
    </div>
    <wsp-help class="mt-12 sm:mt-16" />
  </div>
  <base-modal
    :show="askDeleteConfirmationModalOpen"
    :title="`Eliminar a ${invitationToEdit?.invitee?.firstName} del grupo`"
    accept-button-text="Si, eliminar"
    cancel-button-text="Cancelar"
    :loading="isDeleteInvitationPending"
    @accept="() => deleteInvitation(invitationToEditId)"
    @close="askDeleteConfirmationModalOpen = false"
  />
  <transition name="slide-up">
    <div
      v-show="isUserPresident && bannerVisible"
      class="fixed bottom-0 z-50 mt-1 flex w-full flex-col items-center bg-white px-4 py-8 shadow-lg transition-all"
    >
      <div class="flex flex-row items-center gap-x-4">
        <p
          v-if="!hasMinimumInvitations"
          class="text-xs leading-5 text-gray-800 sm:text-sm"
        >
          Debes invitar al menos {{ minGroupInvitations - (dynamicGroupInvitations.length + 1) }} personas más para poder continuar
        </p>
        <p
          v-else
          class="text-xs leading-5 text-gray-800 sm:text-sm"
        >
          Listo! ahora puedes continuar con el proceso, o seguir invitando
        </p>
        <v-form-native-wrapper
          action="/group_member_loan_application/start_application"
          method="POST"
        >
          <base-button
            type="submit"
            text="Continuar"
            variant="white"
            size="sm"
            :disabled="!hasMinimumInvitations"
          />
        </v-form-native-wrapper>
      </div>
    </div>
  </transition>
</template>

<style scoped>
/* Enter Active - when the element is entering, what transition to apply */
.slide-up-enter-active, .slide-up-leave-active {
  transition: transform 0.2s ease, opacity 0.2s ease;
}

/* From - initial state of entering element */
.slide-up-enter-from, .slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

/* Enter To - final state of the entering element (and initial state for leaving) */
.slide-up-enter-to, .slide-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}

</style>
