<script setup>
import { onMounted, computed } from 'vue';
import { Chart, registerables } from 'chart.js';
import { format } from 'date-fns';
import { toCurrency } from '@/utils/filters';
import ConsolidatedCreditReportLoansTable from './consolidated-credit-report-loans-table.vue';

Chart.register(...registerables);

const props = defineProps({
  creditReport: { type: Object, required: true },
});

const activeCredits = computed(() => props.creditReport.creditos.filter(credito => !credito.fechaCierreCuenta));
const totalVigente = computed(() => activeCredits.value.reduce((sum, credito) => sum + Number(credito.saldoActual), 0));
const totalEnAtraso = computed(() => activeCredits.value.reduce((sum, credito) => sum + Number(credito.saldoVencido), 0));
const totalApproved = computed(() => activeCredits.value.reduce((sum, credito) => sum + Number(credito.creditoMaximo), 0));

// Chart data
const creditStatusData = {
  labels: ['Total Vigente', 'Total en Atraso'],
  datasets: [
    {
      data: [totalVigente.value, totalEnAtraso.value],
      backgroundColor: ['#32CD32', '#FF0000'],
      hoverBackgroundColor: ['#32CD32', '#FF0000'],
    },
  ],
};

const debtAnalysisData = {
  labels: ['Monto Aprobado', 'Saldo Actual'],
  datasets: [
    {
      data: [totalApproved.value, totalVigente.value],
      backgroundColor: ['#0000FF', '#FFFF00'],
      hoverBackgroundColor: ['#0000FF', '#FFFF00'],
    },
  ],
};

function filterPaymentsByFrequency(loans, frequency) {
  return loans.filter(loan => loan.frecuenciaPagos === frequency);
}

function sumPayments(loans) {
  return loans.reduce((acc, loan) => acc + Number(loan.montoPagar), 0);
}

const groupedByType = computed(() => {
  const groups = props.creditReport.creditos.reduce((acc, credit) => {
    const type = credit.tipoCredito;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(credit);

    return acc;
  }, {});

  return groups;
});

const totalsByType = computed(() => {
  const totals = {};
  for (const type in groupedByType.value) {
    const credits = groupedByType.value[type];
    totals[type] = {
      count: credits.length,
      limiteCredito: credits.reduce((sum, { limiteCredito }) => sum + Number(limiteCredito), 0),
      creditoMaximo: credits.reduce((sum, { creditoMaximo }) => sum + Number(creditoMaximo), 0),
      saldoActual: credits.reduce((sum, { saldoActual }) => sum + Number(saldoActual), 0),
      saldoVencido: credits.reduce((sum, { saldoVencido }) => sum + Number(saldoVencido), 0),
      pagoSemanal: sumPayments(filterPaymentsByFrequency(credits, 'S')),
      pagoQuincenal: sumPayments(filterPaymentsByFrequency(credits, 'C')),
      pagoMensual: sumPayments(filterPaymentsByFrequency(credits, 'M')),
    };
  }

  return totals;
});

const grandTotals = computed(() => {
  const totals = {
    count: 0,
    limiteCredito: 0,
    creditoMaximo: 0,
    saldoActual: 0,
    saldoVencido: 0,
    pagoSemanal: 0,
    pagoQuincenal: 0,
    pagoMensual: 0,
  };

  for (const type in totalsByType.value) {
    const group = totalsByType.value[type];
    totals.count += group.count;
    totals.limiteCredito += group.limiteCredito;
    totals.creditoMaximo += group.creditoMaximo;
    totals.saldoActual += group.saldoActual;
    totals.saldoVencido += group.saldoVencido;
    totals.pagoSemanal += group.pagoSemanal;
    totals.pagoQuincenal += group.pagoQuincenal;
    totals.pagoMensual += group.pagoMensual;
  }

  return totals;
});

let creditStatusChart;
let debtAnalysisChart;

onMounted(() => {
  const ctx1 = document.getElementById('creditStatusChart').getContext('2d');
  const ctx2 = document.getElementById('debtAnalysisChart').getContext('2d');

  creditStatusChart = new Chart(ctx1, {
    type: 'pie',
    data: creditStatusData,
  });

  debtAnalysisChart = new Chart(ctx2, {
    type: 'pie',
    data: debtAnalysisData,
  });
});
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div class="flex flex-col gap-y-2">
      <span>
        <span class="font-semibold">Nombre(s):</span>
        {{ creditReport.persona.nombres }}
      </span>
      <span>
        <span class="font-semibold">Apellido paterno:</span>
        {{ creditReport.persona.apellidoPaterno }}
      </span>
      <span>
        <span class="font-semibold">Apellido materno:</span>
        {{ creditReport.persona.apellidoMaterno }}
      </span>
      <span>
        <span class="font-semibold">Fecha nacimiento:</span>
        {{ creditReport.persona.fechaNacimiento }}
      </span>
      <span>
        <span class="font-semibold">RFC:</span>
        {{ creditReport.persona.rFC }}
      </span>
    </div>
    <span class="font-bold">
      Domicilios
    </span>
    <table class="min-w-full bg-white">
      <thead>
        <tr>
          <th>Calle y numero</th>
          <th>Colonia</th>
          <th>Del/Mpio</th>
          <th>Ciudad</th>
          <th>Estado</th>
          <th>CP</th>
          <th>Telefono</th>
          <th>Fecha de</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="domicilio in creditReport.domicilios"
          :key="domicilio.id"
        >
          <td>{{ domicilio.direccion }}</td>
          <td>{{ domicilio.coloniaPoblacion }}</td>
          <td>{{ domicilio.delegacionMunicipio }}</td>
          <td>{{ domicilio.ciudad }}</td>
          <td>{{ domicilio.estado }}</td>
          <td>{{ domicilio.CP }}</td>
          <td>{{ domicilio.numeroTelefono }}</td>
          <td>{{ domicilio.fechaRegistroDomicilio }}</td>
        </tr>
      </tbody>
    </table>

    <span class="mt-12 font-bold">
      Resumen por producto
    </span>
    <table class="min-w-full border-collapse border border-gray-400 bg-white">
      <thead>
        <tr>
          <th class="border border-gray-300 px-4 py-2">
            Producto
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Cuentas
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Limite
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Aprobado
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Actual
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Vencido
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Pago semanal
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Pago Quincenal
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Pago Mensual
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(group, tipoCredito) in totalsByType"
          :key="tipoCredito"
        >
          <td class="border border-gray-300 px-4 py-2">
            {{ tipoCredito }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ group.count }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(group.limiteCredito) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(group.creditoMaximo) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(group.saldoActual) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(group.saldoVencido) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(group.pagoSemanal) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(group.pagoQuincenal) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(group.pagoMensual) }}
          </td>
        </tr>
        <tr class="font-bold">
          <td class="border border-gray-300 px-4 py-2">
            Totales
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ grandTotals.count }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(grandTotals.limiteCredito) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(grandTotals.creditoMaximo) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(grandTotals.saldoActual) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(grandTotals.saldoVencido) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(grandTotals.pagoSemanal) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(grandTotals.pagoQuincenal) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(grandTotals.pagoMensual) }}
          </td>
        </tr>
      </tbody>
    </table>

    <span class="mt-12 font-bold">
      Indicadores
    </span>
    <div class="grid grid-cols-3 gap-4">
      <div>
        <canvas id="creditStatusChart" />
      </div>
      <div>
        <canvas id="debtAnalysisChart" />
      </div>
      <div class="flex flex-col justify-center">
        <div>
          <p>
            Créditos Abiertos en el Presente Año =
            <span class="font-bold">
              {{ activeCredits.length }}
            </span>
          </p>
          <p>
            Crédito más antiguo =
            <span class="font-bold">
              {{ creditReport.creditos.length ?
                creditReport.creditos[0].fechaAperturaCuenta :
                'N/A' }}
            </span>
          </p>
          <p>
            Monto mayor aprobado =
            <span class="font-bold">
              {{ toCurrency(totalApproved) }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <span class="mt-12 font-bold">
      Detalles de Créditos
    </span>
    <consolidated-credit-report-loans-table
      :rows="creditReport.creditos"
    />
    <span class="mt-12 font-bold">
      Consultas Realizadas
    </span>
    <table class="min-w-full border-collapse border border-gray-400 bg-white">
      <thead>
        <tr>
          <th class="border border-gray-300 px-4 py-2">
            Fecha de Consulta
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Otorgante
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Tipo de Crédito
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Monto
          </th>
          <th class="border border-gray-300 px-4 py-2">
            Moneda
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="consulta in creditReport.consultas"
          :key="consulta.fechaConsulta"
        >
          <td class="border border-gray-300 px-4 py-2 uppercase">
            {{ format(new Date(consulta.fechaConsulta), 'dd / MMM / yy') }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ consulta.nombreOtorgante }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{
              $te(`circuloCreditReport.loanType.${consulta.tipoCredito}`) ?
                $t(`circuloCreditReport.loanType.${consulta.tipoCredito}`) :
                consulta.tipoCredito
            }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ toCurrency(consulta.importeCredito) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            {{ consulta.claveUnidadMonetaria }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

