<script setup>
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const WHATSAPP_MESSAGE = 'Hola, me ayudarian con: ';
const WHATSAPP_NUMBER = '+5215514781650';

const showChat = ref(true);
</script>

<template>
  <Teleport to="body">
    <div
      v-if="showChat"
      class="fixed bottom-6 right-6 z-40 sm:bottom-10 sm:right-10"
    >
      <div class="relative">
        <button
          class="absolute -right-2 -top-2 z-50 flex h-5 w-5 items-center justify-center rounded-full bg-gray-400 p-1 hover:bg-gray-500"
          type="button"
          @click="showChat = false"
        >
          <XMarkIcon class="h-full w-full text-gray-100" />
        </button>
        <a
          class="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-green-500 p-3 ring-gray-300 transition-all duration-300 hover:bg-green-400 hover:ring-2"
          :href="`https://wa.me/${WHATSAPP_NUMBER}?text=${encodeURIComponent(WHATSAPP_MESSAGE)}`"
          target="_blank"
        >
          <base-icon
            icon="whatsapp"
            class="h-full w-auto fill-current text-white"
          />
        </a>
      </div>
    </div>
  </Teleport>
</template>
