import { api } from '../index';

const path = '/api/internal/application/group_loan_applications';


function get(id: number) {
  return api({
    method: 'get',
    url: `${path}/${id}`,
  }).then((response: any) => response.groupLoanApplication);
}

export default { get };
