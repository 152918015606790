<script setup>
import { inject } from 'vue';
import { wspSupportNumberKey } from '../utils/keys.js';

const wspSupportNumber = inject(wspSupportNumberKey);
const WSP_TEXT = 'Hola%20necesito%20ayuda%20con%20la%20app%20de%20Grupalia';
</script>

<template>
  <p class="text-center text-xs sm:text-sm">
    Necesitas ayuda?
    puedes preguntarnos lo que necesites por
    <a
      :href="`https://wa.me/${wspSupportNumber}?text=${WSP_TEXT}`"
      target="_blank"
      class="text-blue-400 underline"
    >
      Whatsapp
    </a> y te responderemos en unos minutos.
  </p>
</template>
