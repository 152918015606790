import { api } from './index';

export default {
  destroy(id: number) {
    const path = `/api/internal/group_invitations/${id}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
  resendCode(id: number) {
    const path = `/api/internal/group_invitations/${id}/invitation_code_resend`;

    return api({
      method: 'post',
      url: path,
    });
  },
};
