<script setup>
import { computed } from 'vue';

const props = defineProps({
  title: { type: String, required: true },
  description: { type: String, default: '' },
  headers: { type: Array, required: true },
  rows: { type: Array, required: true },
  size: {
    type: String,
    default: 'base',
    validator: (value) => ['sm', 'base'].includes(value),
  },
});

const textSizeClasses = computed(() => {
  switch (props.size) {
  case 'sm': return 'text-xs';
  case 'base':
  default:
    return 'text-sm';
  }
});

const thSizeClasses = computed(() => {
  switch (props.size) {
  case 'sm': return 'px-3 py-1.5';
  case 'base':
  default:
    return 'px-4 py-2';
  }
});

const tdSizeClasses = computed(() => {
  switch (props.size) {
  case 'sm': return 'px-3 py-1.5';
  case 'base':
  default:
    return 'px-4 py-2';
  }
});
</script>

<template>
  <div>
    <div class="sm:flex-auto">
      <h1 class="text-base font-semibold leading-6 text-gray-900">
        {{ title }}
      </h1>
      <p
        v-if="description"
        class="mt-2 text-sm text-gray-700"
      >
        {{ description }}
      </p>
    </div>
    <div class="mt-6 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
            <table :class="['min-w-full divide-y divide-gray-300', textSizeClasses]">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header"
                    scope="col"
                    :class="['text-left font-semibold text-gray-900', thSizeClasses]"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                >
                  <td
                    v-for="(value, key) in row"
                    :key="key"
                    :class="['whitespace-nowrap text-gray-900', tdSizeClasses]"
                    v-html="value"
                  />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
