<script setup>
import { inject } from 'vue';
import { object } from 'yup';
import { useMutation } from '@tanstack/vue-query';
import { textFieldSchema, emailFieldSchema } from '@/utils/yupRules';
import UserAvatarInput from '@/components/user-avatar-input.vue';
import validaCurpApi from '@/api/validaCurp';
import useUIStore from '@/stores/ui';
import { currentUserKey } from '@/utils/keys.js';

const emit = defineEmits([
  'submit',
  'update:first-name',
  'update:paternal-surname',
  'update:maternal-surname',
  'update:email',
  'update:sex',
  'update:birth-date',
  'update:identification-number',
]);

const props = defineProps({
  firstName: { type: String, default: '' },
  paternalSurname: { type: String, default: '' },
  maternalSurname: { type: String, default: '' },
  email: { type: String, default: '' },
  identificationNumber: { type: String, default: '' },
});

const currentUser = inject(currentUserKey);
const uiStore = useUIStore();

const {
  mutate: validateCurp,
  isPending: isValidatingCurp,
} = useMutation({
  mutationFn: () => validaCurpApi.validateCurp(props.identificationNumber),
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Ocurrió un error al validar la CURP',
      type: 'error',
      position: 'bottom',
    });
  },
  onSuccess: (data) => {
    emit('update:first-name', data.firstName);
    emit('update:paternal-surname', data.paternalSurname);
    emit('update:maternal-surname', data.maternalSurname);
    emit('update:birth-date', new Date(data.birthDate));
    emit('update:sex', data.sex);
    emit('submit');
  },
});

const validationSchema = object().shape({
  identificationNumber: textFieldSchema,
});
</script>

<template>
  <v-form
    :validation-schema="validationSchema"
    @submit="validateCurp"
  >
    <base-form-section title="Información personal">
      <user-avatar-input
        :user="currentUser"
        class="my-4"
        size="lg"
      />
      <base-short-text-input
        :model-value="email"
        name="email"
        label="Correo electrónico"
        @update:model-value="$emit('update:email', $event)"
      />
      <base-short-text-input
        :model-value="identificationNumber"
        name="identificationNumber"
        type="text"
        label="Ingresa tu CURP"
        description="Puedes encontrar tu CURP en tu INE"
        @update:model-value="$emit('update:identification-number', $event)"
      />
    </base-form-section>
    <base-button
      type="submit"
      text="Siguiente"
      class="mt-12 w-full"
      :loading="isValidatingCurp"
    />
  </v-form>
</template>
