import { api } from '../index';

function create(phoneNumber: string) {
  const path = '/api/internal/users/magic_codes';

  return api({
    method: 'post',
    url: path,
    data: { phoneNumber },
  });
}

export default { create };
