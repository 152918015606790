<script setup>
import { computed } from 'vue';

const props = defineProps({
  role: {
    type: String,
    required: true,
    validator: (value) => ['guest', 'vice_president', 'president'].includes(value),
  },
  color: { type: String, default: null },
});

const badgeColor = computed(() => {
  if (props.color) {
    return props.color;
  }

  const colors = {
    guest: 'gray',
    'vice_president': 'blue',
    president: 'green',
  };

  return colors[props.role];
});
</script>

<template>
  <base-badge
    :color="badgeColor"
    :label="$t(`groupMember.roles.${props.role}`)"
  />
</template>
