import { string, date, number } from 'yup';

const phoneNumberSchema = string()
  .mobilePhone(['es-MX', 'es-CL'])
  .required('Número de teléfono es obligatorio');
const textFieldSchema = string().required('Este campo es obligatorio');
const numberFieldSchema = number()
  .typeError('Este campo debe ser un número')
  .min(0, 'El valor debe ser mayor o igual a 0')
  .required('Este campo es obligatorio');
const dateFieldSchema = date().typeError('Fecha invalida').required('Campo obligatorio');
const emailFieldSchema = string().notRequired().email('Correo electrónico inválido');

export { phoneNumberSchema, textFieldSchema, numberFieldSchema, dateFieldSchema, emailFieldSchema };
