<script setup>
import { computed } from 'vue';
import {
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  parseISO,
  format,
  compareAsc,
  isToday,
} from 'date-fns';
import BaseTable from '@/components/base-table.vue';
import { toCurrency } from '@/utils/filters';

const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
const endOfThisWeek = endOfWeek(new Date(), { weekStartsOn: 1 });

const props = defineProps({
  groupLoans: { type: Array, required: true },
});

function findThisWeekPayment(gl) {
  const sortedPayments = [
    ...gl.loanPayments.sort(
      (a, b) => compareAsc(parseISO(a.dueDate), parseISO(b.dueDate)),
    ),
  ];

  const weekPayment = gl.loanPayments.find(lp =>
    isWithinInterval(parseISO(lp.dueDate), {
      start: startOfThisWeek,
      end: endOfThisWeek,
    }),
  );

  const firstPendingPayment = sortedPayments.filter(p => p.aasmState !== 'paid');

  return weekPayment || firstPendingPayment || sortedPayments[sortedPayments.length - 1];
}

const sortedGroupLoans = computed(() =>
  [...props.groupLoans].sort((a, b) =>
    compareAsc(
      parseISO(findThisWeekPayment(a).dueDate),
      parseISO(findThisWeekPayment(b).dueDate),
    ),
  ));

function getBadgeProperties(groupLoan, payment) {
  if (groupLoan.delinquent) {
    return { label: 'mora', color: 'red' };
  } else if (payment && payment.aasmState === 'pending' && isToday(parseISO(payment.dueDate))) {
    return { label: 'pendiente', color: 'yellow' };
  }

  return { label: 'al día', color: 'green' };
}

const tableItems = computed(() => sortedGroupLoans.value
  .map((gl) => {
    const thisWeekPayment = findThisWeekPayment(gl);
    const badgeProps = getBadgeProperties(gl, thisWeekPayment);

    return [
      gl.id,
      gl.group.name,
      format(parseISO(thisWeekPayment.dueDate), 'EEEE d'),
      toCurrency(thisWeekPayment?.amountWithFees || gl.repaymentAmount),
      {
        component: 'base-badge',
        props: {
          label: badgeProps.label,
          size: 'xs',
          color: badgeProps.color,
        },
      },
      toCurrency(gl.delinquentAmount),
      gl.loanPayments.filter(payment => payment.aasmState === 'paid').length,
      gl.delinquentPayments.length,
      gl.loanPayments.filter(payment => payment.late).length,
      gl.group.promoter?.firstName || '-',
      {
        component: 'a',
        href: `/reviews/group_loan_applications/${gl.groupLoanApplicationId || ''}`,
        html: 'Ver',
        props: {
          'target': '_blank',
        },
      },
    ];
  }));
</script>

<template>
  <div class="flex flex-col">
    <base-back-button
      href="/"
    />
    <div>
      <base-table
        title="Estado de créditos grupales"
        :headers="[
          'ID',
          'Grupo',
          'Próximo pago',
          'Monto del pago',
          'Estado',
          'Deuda vencida',
          'Pagos realizados',
          'Pagos en mora',
          'Pagos tardes',
          'Asesor',
          'Solicitud',
        ]"
        :items="tableItems"
      />
    </div>
    <ul class="mx-4 mt-1 flex flex-row gap-x-4">
      <li class="flex flex-row items-center gap-x-2">
        <span class="text-sm text-gray-600">
          Grupos activos: <b>{{ sortedGroupLoans.length }}</b>
        </span>
      </li>
      <li class="flex flex-row items-center gap-x-2">
        <span class="text-sm text-gray-600">
          Grupos en mora: <b>{{ sortedGroupLoans.filter(g => g.delinquent).length }}</b>
        </span>
      </li>
    </ul>
  </div>
</template>
