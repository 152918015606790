import { ref, readonly } from 'vue';
import { defineStore } from 'pinia';

export default defineStore('user', () => {
  const currentUser = ref(null);

  function setCurrentUser(user) {
    currentUser.value = user;
  }

  return {
    currentUser: readonly(currentUser),
    setCurrentUser,
  };
});
