<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/utils/filters';

defineEmits([
  'submit',
  'update:loan',
  'update:payments',
]);

const props = defineProps({
  loan: { type: Number, required: true },
  payments: { type: Number, required: true },
  form: { type: Object, required: true },
  showPaymentsOptions: { type: Boolean, required: true },
});

const MIN_LOAN_MXN = 3000;
const MAX_LOAN_MXN = 100000;

const sections = computed(() => {
  const info = [
    {
      title: 'Información personal',
      fields: [
        {
          title: 'CURP',
          value: props.form.sections.personalInformation.fields.identificationNumber.value,
        },
      ],
    },
    {
      title: 'Domicilio',
      fields: [
        {
          title: 'Calle',
          value: props.form.sections.address.fields.street.value,
        },
        {
          title: 'Número exterior',
          value: props.form.sections.address.fields.externalNumber.value,
        },
        {
          title: 'Número interior',
          value: props.form.sections.address.fields.internalNumber.value,
        },
        {
          title: 'Colonia',
          value: props.form.sections.address.fields.colony.value,
        },
        {
          title: 'Municipio',
          value: props.form.sections.address.fields.municipality.value,
        },
        {
          title: 'Código postal',
          value: props.form.sections.address.fields.postalCode.value,
        },
        {
          title: 'Estado',
          value: props.form.sections.address.fields.state.value,
        },
      ],
    },
    {
      title: 'Datos de tu negocio',
      fields: [],
    },
  ];

  if (props.form.sections.economicActivity.fields.employee.value === 'yes') {
    info[2].fields.push({
      title: 'Empleado',
      value: 'Sí',
    });
  } else {
    info[2].fields.push({
      title: 'Tipo de negocio',
      value: props.form.sections.economicActivity.fields.businessType.value,
    });
  }

  info[2].fields.push({
    title: 'Dependientes económicos',
    value: props.form.sections.economicActivity.fields.dependents.value,
  });

  info[2].fields.push({
    title: 'Ingresos mensuales',
    value: toCurrency(props.form.sections.economicActivity.fields.income.value),
  });

  info[2].fields.push({
    title: 'Gastos mensuales',
    value: toCurrency(props.form.sections.economicActivity.fields.expenses.value),
  });

  info[2].fields.push({
    title: 'Cuánto podrías pagar semanalmente',
    value: toCurrency(props.form.sections.economicActivity.fields.paymentAbilities.value),
  });

  return info;
});
</script>

<template>
  <div class="flex w-full flex-col gap-y-6 overflow-hidden rounded-lg bg-white">
    <div class="mt-12 flex w-full max-w-xl flex-col gap-y-4 self-center px-8">
      <div class="flex flex-row justify-between gap-x-4">
        <label
          for="monto"
          class="text-sm font-semibold"
        >
          Monto del crédito
        </label>
        <span class="font-semibold">
          ${{ $filters.formatNumber(loan) }} pesos
        </span>
      </div>
      <base-slider
        :value="loan"
        :min="MIN_LOAN_MXN"
        :max="MAX_LOAN_MXN"
        show-tooltip="false"
        :step="1000"
        @input="$emit('update:loan', $event)"
      />
      <input
        class="hidden"
        type="number"
        :value="loan"
      >
    </div>
    <p class="mt-12 self-center text-lg font-bold">
      ⌛ Datos de tu solicitud anterior
    </p>
    <ul class="flex flex-col gap-y-8 p-4 sm:p-8">
      <li
        v-for="section in sections"
        :key="section.title"
        class="flex flex-col gap-y-2"
      >
        <h3 class="text-base font-bold">
          {{ section.title }}
        </h3>
        <ul class="flex flex-col gap-y-4">
          <li
            v-for="(field, index) in section.fields"
            :key="field.title"
            :class="[
              'grid grid-cols-2 gap-x-4 rounded p-2',
              index % 2 === 0 ? 'bg-gray-100' : '',
            ]"
          >
            <span class="text-sm font-medium">{{ field.title }}</span>
            <span class="break-words text-sm text-gray-600">{{ field.value }}</span>
          </li>
        </ul>
      </li>
    </ul>
    <div class="mx-4 mb-4 flex flex-row items-center gap-x-2 self-end">
      <base-button
        text="Editar datos"
        variant="white"
        @click="$emit('edit')"
      />
      <base-button
        text="Confirmar datos"
        @click="$emit('confirm')"
      />
    </div>
  </div>
</template>
