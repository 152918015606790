<script setup>
import { ref, onMounted, inject } from 'vue';
import { useEventBus } from '@vueuse/core';
import { mobileAppStoreUrlKey } from '../utils/keys.js';

const mobileAppStoreUrl = inject(mobileAppStoreUrlKey);

const requirements = [
  'Tener un negocio o querer empezar uno.',
  'Tener o hacer un grupo de al menos 8 personas (pueden ser hombres y mujeres).',
  'Credencial para votar (INE) vigente.',
  'Comprobante de domicilio original (vigencia de 3 meses).',
];

const elementToScrollTo = ref(null);
const scrollEventBus = useEventBus('scroll');

onMounted(() => {
  scrollEventBus.on((event) => {
    if (elementToScrollTo.value && event === 'requirements') {
      elementToScrollTo.value.scrollIntoView({ behavior: 'smooth' });
    }
  });
});
</script>

<template>
  <div
    ref="elementToScrollTo"
    class="container flex max-w-7xl flex-col items-center self-center px-4 py-16 sm:py-32"
  >
    <div class="flex flex-col items-center gap-y-12 sm:gap-y-20">
      <h2 class="title">
        Pocos requisitos y un trámite fácil
      </h2>
      <div class="flex flex-col gap-x-12 gap-y-6 px-4 md:flex-row md:items-center md:px-12">
        <div class="flex flex-col gap-y-6 md:gap-y-8 lg:gap-y-12">
          <ul class="ml-4 list-disc marker:text-primary-600">
            <li
              v-for="requirement in requirements"
              :key="requirement"
              class="mt-2 text-lg font-medium text-gray-700"
            >
              {{ requirement }}
            </li>
          </ul>
          <base-button
            text="Empezar mi solicitud"
            :href="mobileAppStoreUrl"
          />
        </div>
        <div class="w-full shrink-0 overflow-hidden border-primary-600 sm:w-[400px] md:w-[500px] md:rounded-xl md:border lg:w-[600px]">
          <base-image
            img-name="landing-loan-requirements-family"
            alt="Grupo de emprendedores dueños de un negocio"
            ext="png"
            class="h-full w-full object-cover"
          />
        </div>
      </div>
    </div>
  </div>
</template>
