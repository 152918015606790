<script setup>
import { ref, watch } from 'vue';
import { CalendarDaysIcon } from '@heroicons/vue/24/outline';
import { vMaska } from 'maska';
import { useField } from 'vee-validate';
import useFilteredAttrs from '@/composables/useFilteredAttrs.ts';
import useErrorBounceOnFormSubmit from '@/composables/useErrorBounceOnFormSubmit.js';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  label: { type: String, required: true },
  description: { type: String, default: '' },
  name: { type: String, required: true },
  modelValue: { type: [Object, String], default: null },
  error: { type: String, default: '' },
});

const pad = (num) => (num < 10 ? `0${num}` : num.toString());

const date = props.modelValue ? new Date(props.modelValue) : null;
const maskedValue = ref('');

if (date && !isNaN(date.getTime())) {
  maskedValue.value = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
}

const {
  value: inputValue,
  handleChange,
  errorMessage,
} = useField(() => props.name, undefined, { initialValue: props.modelValue });

watch(inputValue, (newVal) => {
  emit('update:modelValue', newVal);
}, { immediate: true });

watch(() => props.modelValue, (newVal) => {
  if (newVal !== inputValue.value) {
    inputValue.value = newVal;
  }
});

watch(maskedValue, () => {
  const [day, month, year] = maskedValue.value.toString().split('/');

  if (day && month && year) {
    const _date = new Date(year, month - 1, day); // month is 0-indexed
    if (_date instanceof Date && !Number.isNaN(_date)) {
      handleChange(_date);
    } else {
      handleChange(null);
    }
  } else {
    handleChange(null);
  }
});

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

const errorRef = ref(null);
useErrorBounceOnFormSubmit(errorRef);
</script>

<template>
  <div :class="$attrs.class || 'flex flex-col items-start'">
    <label
      :for="name"
      class="block text-sm font-medium leading-6 text-gray-900"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
    >
      {{ description }}
    </p>
    <div
      :class="[`mt-2 box-border flex w-40 flex-row items-center gap-x-1 overflow-hidden rounded-md border
            border-transparent bg-white px-3 py-1 shadow-sm ring-1 ring-inset transition duration-300
            ease-in-out focus-within:ring-2 focus-within:ring-primary-600`,
               (errorMessage || error) ?
                 'border-red-400'
                 : 'ring-gray-300 hover:border-primary-500'
      ]"
    >
      <CalendarDaysIcon class="h-5 w-5 text-gray-400" />
      <input
        v-bind="filteredAttrs"
        v-model="maskedValue"
        v-maska
        class="w-full border-none bg-transparent p-1 text-left text-gray-900 outline-none
              placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
        type="text"
        placeholder="DD/MM/YYYY"
        data-maska="##/##/####"
      >
    </div>
    <span
      v-if="errorMessage || error"
      ref="errorRef"
      class="mt-1 text-xs text-red-500 sm:text-sm"
    >
      {{ errorMessage || error }}
    </span>
  </div>
</template>
