import { api } from './index';

function preApprove(id: number, body: any) {
  const path = `/api/internal/group_loan_applications/${id}/pre_approvals`;

  return api({
    method: 'post',
    url: path,
    data: body,
  });
}

export default { preApprove };
