<script setup>
import { ref, watch } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

defineEmits(['close']);
const props = defineProps({
  open: Boolean,
});

const open = ref(props.open);

watch(() => props.open, (newVal) => {
  open.value = newVal;
});
</script>

<template>
  <TransitionRoot
    as="template"
    :show="open"
  >
    <Dialog
      as="div"
      class="relative z-10"
    >
      <div class="fixed inset-0">
        <div class="absolute inset-0">
          <div class="pointer-events-none fixed inset-y-0 right-0 z-50 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="relative h-full max-h-screen w-full grow rounded-md bg-white px-4 py-12 shadow-xl">
                  <button
                    type="button"
                    class="absolute left-2 top-2 text-gray-400 hover:text-gray-500"
                    @click="$emit('close')"
                  >
                    <span class="sr-only">Close panel</span>
                    <XMarkIcon
                      class="h-6 w-6"
                      aria-hidden="true"
                    />
                  </button>
                  <div class="h-full overflow-y-scroll">
                    <slot />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

