<script setup>
import { ref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import currentUserApi from '../api/currentUser.ts';
import useUserStore from '../stores/user.js';
import useUIStore from '../stores/ui.js';

defineProps({
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md', 'lg', 'xl'].includes(value),
  },
});

const userStore = useUserStore();
const uiStore = useUIStore();

const {
  isPending: isAddAvatarPending,
  mutate: addAvatar,
} = useMutation({
  mutationFn: (image) => {
    const formData = new FormData();
    formData.append('user[avatar]', image);

    return currentUserApi.updateAvatar(formData);
  },
  onSuccess: ({ user }) => {
    userStore.setCurrentUser(user);
  },
  onError: () => {
    uiStore.toast({
      message: 'Hubo un error al actualizar tu avatar',
      type: 'error',
      duration: 5000,
      position: 'bottom',
    });
  },
});

const {
  isPending: isRemoveAvatarPending,
  mutate: removeAvatar,
} = useMutation({
  mutationFn: () => currentUserApi.update({ removeAvatar: true }),
  onSuccess: ({ user }) => {
    userStore.setCurrentUser(user);
  },
  onError: () => {
    uiStore.toast({
      message: 'Hubo un error al actualizar tu avatar',
      type: 'error',
      duration: 5000,
      position: 'bottom',
    });
  },
});

const inputRef = ref(null);
</script>
<template>
  <div class="flex flex-col gap-y-4">
    <span class="text-sm font-medium">
      Foto de perfil
    </span>
    <div class="flex flex-row items-center gap-x-4">
      <base-avatar
        :user="userStore.currentUser"
        :size="size"
      />
      <input
        ref="inputRef"
        class="hidden"
        type="file"
        accept="image/*"
        @change="(e) => addAvatar(e.target.files[0])"
      >
      <div class="flex flex-col gap-y-2">
        <base-button
          type="button"
          size="sm"
          text="Cambiar foto"
          :loading="isAddAvatarPending"
          @click="$refs.inputRef.click()"
        />
        <base-button
          v-if="userStore.currentUser.avatarUrl"
          type="button"
          size="sm"
          variant="red"
          text="Eliminar foto"
          :loading="isRemoveAvatarPending"
          @click="removeAvatar"
        />
      </div>
    </div>
  </div>
</template>
