import { computed, onMounted, getCurrentInstance } from 'vue';
import { useField } from 'vee-validate';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import Compressor from 'compressorjs';

// eslint-disable-next-line max-statements
export default function useFileHandler({ inputRef, name, rules = null, initialValue = null, compressed = false }) {
  const {
    value: inputValue,
    errorMessage,
    validate,
  } = useField(name, rules, { type: 'file' });

  const { emit } = getCurrentInstance();

  // eslint-disable-next-line complexity
  function saveFile(data, isCompressed = false) {
    if (isCompressed && compressed) {
      inputValue.value = data;
    } else if (!isCompressed && !compressed) {
      inputValue.value = data;
    }

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(data);
    inputRef.value.files = dataTransfer.files;

    if (isCompressed && compressed) {
      emit('update:modelValue', dataTransfer.files[0]);
    } else if (!isCompressed && !compressed) {
      emit('update:modelValue', dataTransfer.files[0]);
    }

    validate();
  }

  function compressAndSaveFile(originalFile) {
    new Compressor(originalFile, {
      quality: 0.6,
      success(result) {
        const compressedFile = new File([result], result.name, {
          type: result.type,
          lastModified: Date.now(),
        });
        saveFile(compressedFile, true);
      },
      error(err) {
        Sentry.captureException(err);
      },
    });
  }

  function handleChange(event) {
    if (event.target.files.length === 0) return;
    const file = event.target.files[0];
    saveFile(file);
    compressAndSaveFile(file);
  }

  function handleDrop(event) {
    const file = event.dataTransfer.files[0];
    saveFile(file);
    compressAndSaveFile(file);
  }

  function reset() {
    inputValue.value = null;
    inputRef.value.value = '';
    validate();
    emit('update:modelValue', null);
  }

  onMounted(() => {
    if (initialValue) {
      axios.get(initialValue, { responseType: 'blob' }).then(response => {
        const data = new File(
          [response.data],
          response.config.url,
          { type: response.headers['content-type'] },
        );
        saveFile(data, compressed);
      });
    }
  });

  const fileName = computed(() => {
    if (!inputValue.value) return undefined;

    return inputValue.value.name.split('/').pop();
  });

  return {
    inputValue,
    errorMessage,
    handleChange,
    handleDrop,
    reset,
    fileName,
  };
}
