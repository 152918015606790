<script setup>
import useUIStore from '@/stores/ui.js';
import TheAppLayoutAvatar from './the-app-layout-avatar.vue';
import TheFooter from './the-footer.vue';
import ToastsContainer from './toasts-container.vue';
import BaseFixedBanner from './base-fixed-banner.vue';

defineProps({
  maxWidthClass: { type: String, default: 'max-w-3xl' },
});

const { banner } = useUIStore();
</script>

<template>
  <div class="flex min-h-screen w-full flex-col items-center bg-gray-100">
    <nav class="absolute inset-x-0 top-0 bg-primary-600 px-6 py-3">
      <div
        class="flex flex-row items-center"
      >
        <a href="/">
          <base-image
            img-name="logo"
            alt="Logo de grupalia"
            class="h-10 w-auto fill-current text-white"
          />
        </a>
        <TheAppLayoutAvatar class="ml-auto" />
      </div>
    </nav>

    <main
      :class="[
        'container mt-16 flex min-h-screen flex-col items-center px-4 py-12 sm:mt-24',
        maxWidthClass
      ]"
    >
      <toasts-container />
      <base-fixed-banner
        v-if="banner.isVisible"
      />

      <slot />
    </main>

    <the-footer />
  </div>
</template>
