<script setup>
import { ref } from 'vue';
import BaseBackButton from '@/components/base-back-button.vue';
import BaseBadge from '@/components/base-badge.vue';
import useUIStore from '@/stores/ui';

const props = defineProps({
  group: { type: Object, required: true },
  groupLoan: { type: Object, required: true },
  stores: { type: Array, required: true },
});

const uiStore = useUIStore();

const PAYMENT_METHODS = {
  wire: 1,
  cash: 2,
};
const selectedPaymentMethod = ref(null);
const selectedConvenienceStore = ref(null);

function setSelectedConvenienceStore(store) {
  selectedConvenienceStore.value = store;
  window.scrollTo(0, 0);
}

function setSelectedPaymentMethod(method) {
  selectedPaymentMethod.value = method;
  window.scrollTo(0, 0);
}

async function copyClabe() {
  try {
    await navigator.clipboard.writeText(props.group.tokuClabe);

    uiStore.toast({
      message: 'CLABE copiada al portapapeles',
      type: 'success',
      position: 'bottom',
    });
  } catch (e) {
    uiStore.toast({
      message: 'No se pudo copiar la CLABE al portapapeles',
      type: 'error',
      position: 'bottom',
    });
  }
}
</script>

<template>
  <div class="container flex w-full flex-col">
    <div
      v-if="!selectedPaymentMethod"
      class="flex flex-col"
    >
      <h1 class="mt-10 text-center text-lg font-semibold">
        Selecciona el método de pago
      </h1>
      <button
        class="relative mt-10 flex w-full flex-col items-center gap-y-8 rounded-lg border border-green-300 bg-white py-8 font-medium"
        @click="setSelectedPaymentMethod(PAYMENT_METHODS.wire)"
      >
        <base-badge
          class="absolute -top-2 left-1/2 -translate-x-1/2"
          label="Recomendado"
          color="green"
          size="xxs"
        />
        <span>
          📱 Transferencia bancaria
        </span>
        <span class="-mb-4 text-xs italic text-gray-500">
          Sin comisiones
        </span>
      </button>
      <button
        class="mt-6 flex w-full flex-col items-center gap-y-8 rounded-lg border border-gray-200 bg-white py-8 font-medium"
        @click="setSelectedPaymentMethod(PAYMENT_METHODS.cash)"
      >
        <span>
          💸 Efectivo en tiendas
        </span>
        <span class="-mb-4 text-xs italic text-gray-500">
          Pueden aplicar comisiones en algunas tiendas
        </span>
      </button>
    </div>
    <div v-else-if="selectedPaymentMethod === PAYMENT_METHODS.wire">
      <base-back-button
        tag="button"
        @click="setSelectedPaymentMethod(null)"
      />
      <div />
      <div class="mt-8 rounded-md bg-white p-6">
        <div class="flex flex-col gap-y-6 pb-10 sm:pb-0 sm:pr-10">
          <h1 class="text-lg font-bold">
            Datos de pago por transferencia
            <span class="text-primary-600">{{ group.name }}</span>
          </h1>
          <div class="flex w-full flex-col divide-y divide-gray-300 rounded-xl bg-gray-50 px-4 py-6">
            <div class="flex flex-row items-center justify-between gap-x-6 py-4">
              <span class="text-gray-700">CLABE</span>
              <span class="font-bold">{{ group.tokuClabe }}</span>
            </div>
            <div class="flex flex-row items-center justify-between gap-x-6 py-4">
              <span class="text-gray-700">Monto a pagar</span>
              <span class="font-bold">
                {{ $filters.toCurrency(groupLoan.pendingPaymentsAmount || groupLoan.repaymentAmount) }}
              </span>
            </div>
            <div class="flex flex-row items-center justify-between gap-x-4 py-4">
              <span class="text-gray-700">Banco</span>
              <span class="font-bold">STP</span>
            </div>
            <div class="flex flex-row justify-between gap-x-6 py-4">
              <span class="text-gray-700">Beneficiario</span>
              <span class="text-right font-bold">XYZ PAGOS SA DE CV</span>
            </div>
            <div class="flex flex-row items-center justify-between gap-x-4 py-4">
              <span class="text-gray-700">Estado</span>
              <template v-if="groupLoan.delinquentPayments.length > 0">
                <base-badge
                  label="Atrasado"
                  color="red"
                />
              </template>
              <template v-else>
                <base-badge
                  :label="groupLoan.pendingPaymentsAmount > 0 ? 'Pendiente' : 'Pagado'"
                  :color="groupLoan.pendingPaymentsAmount > 0 ? 'yellow' : 'green'"
                />
              </template>
            </div>
          </div>
          <base-button
            text="Copiar CLABE"
            variant="white"
            @click="copyClabe"
          />
        </div>
      </div>
    </div>
    <div v-else-if="selectedPaymentMethod === PAYMENT_METHODS.cash">
      <base-back-button
        v-if="selectedPaymentMethod"
        tag="button"
        @click="selectedConvenienceStore ? setSelectedConvenienceStore(null) : setSelectedPaymentMethod(null)"
      />
      <div v-if="selectedConvenienceStore">
        <div v-if="['oxxo', 'azteca', 'coppel'].includes(selectedConvenienceStore)">
          <div v-if="selectedConvenienceStore === 'oxxo'">
            <h2 class="font-bold">
              Instrucciones de pago en efectivo en OXXO
            </h2>
            <p class="mt-6">
              ⚠️ <b>Importante</b> ⚠️
              <br />
              toma precauciones revisando las tarjetas participantes
              <a href="https://www.oxxo.com/pagos-y-depositos" class="text-blue-400" target="_blank">aquí</a>,
              límites y horarios de depósito.
              Recuerda que OXXO puede cobrar comisiones ajenas a Grupalia.
            </p>
            <ul class="flex mt-6 list-inside list-disc flex-col gap-y-2 text-gray-700">
              <li>
                Solicita en caja un depósito a tu tarjeta. Consulta tarjetas participantes y horarios
                de depósito <a class="text-blue-400" href="https://www.oxxo.com/pagos-y-depositos" target="_blank">aquí</a>.
              </li>
              <li>Deposita a una tarjeta donde puedas hacer transferencias</li>
              <li>
                Una vez recibas el dinero, usa tu aplicación de banco para hacer una transferencia
              </li>
              <li>
                Transfiere el monto de la ficha completa a la cuenta CLABE que se indica a continuación
              </li>
              <li>
                ¡Listo! verás tu pago reflejado en nuestra plataforma <a
                  href="/group_loan"
                  class="text-blue-400"
                >
                  aquí</a>.
              </li>
            </ul>
          </div>
          <div v-else-if="selectedConvenienceStore === 'azteca'">
            <h2 class="font-bold">
              Instrucciones de pago en efectivo en Banco Azteca
            </h2>
            <p class="mt-6">
              ⚠️ <b>Importante</b> ⚠️
              <br />
              solo puedes ir a Banco Azteca si tienes cuenta de ahorros o Guardadito ahí.
            </p>
            <ul class="flex mt-6 list-inside list-disc flex-col gap-y-2 text-gray-700">
              <li>
                Solicita en caja un depósito a tu tarjeta
              </li>
              <li>
                Deposita a tu tarjeta Banco Azteca/cuenta bancaria donde puedas hacer transferencias
              </li>
              <li>
                Una vez recibas el dinero, usa tu aplicación de banco para hacer una transferencia
              </li>
              <li>
                Transfiere el monto de la ficha completa a la cuenta CLABE que se indica a continuación
              </li>
              <li>
                ¡Listo! verás tu pago reflejado en nuestra plataforma <a
                  href="/group_loan"
                  class="text-blue-400"
                >
                  aquí</a>.
              </li>
            </ul>
          </div>
          <div v-else-if="selectedConvenienceStore === 'coppel'">
            <h2 class="font-bold">
              Instrucciones de pago en efectivo en Banco Azteca
            </h2>
            <p class="mt-6">
              ⚠️ <b>Importante</b> ⚠️
              <br />
              solo puedes ir a Coppel si tienes cuenta bancaria, tarjeta de débito o ahorros ahí
            </p>
            <ul class="flex mt-6 list-inside list-disc flex-col gap-y-2 text-gray-700">
              <li>
                Solicita en caja un depósito a tu tarjeta
              </li>
              <li>
                Deposita a tu tarjeta Banco Azteca/cuenta bancaria donde puedas hacer transferencias
              </li>
              <li>
                Una vez recibas el dinero, usa tu aplicación de banco para hacer una transferencia
              </li>
              <li>
                Transfiere el monto de la ficha completa a la cuenta CLABE que se indica a continuación
              </li>
              <li>
                ¡Listo! verás tu pago reflejado en nuestra plataforma
                <a
                  href="/group_loan"
                  class="text-blue-400"
                >
                  aquí</a>.
              </li>
            </ul>
          </div>
          <div v-else class="mt-4 flex flex-col gap-y-4 px-4">
            <h2 class="font-bold">
              Instrucciones de pago en efectivo
            </h2>
            <ul class="flex list-inside list-disc flex-col gap-y-2 text-gray-700">
              <li>Deposita en efectivo a <b>tu tarjeta</b> en la cajera.</li>
              <li>Luego de eso, puedes hacer el pago por transferencia a la cuenta CLABE indicada abajo.</li>
              <li>
                ¡Listo! podrás ver tu pago reflejado en la plataforma, haciendo
                click
                <a
                  href="/group_loan"
                  class="text-blue-400"
                >
                  aquí</a>.
              </li>
            </ul>
          </div>
          <div
            class="mt-8 rounded-md bg-white p-6"
          >
            <div class="flex grow flex-col gap-y-6 pb-10 sm:pb-0 sm:pr-10">
              <h1 class="text-lg font-bold">
                Datos de pago por transferencia
                <span class="text-primary-600">{{ group.name }}</span>
              </h1>
              <div class="flex w-full flex-col divide-y divide-gray-300 rounded-xl bg-gray-50 px-4 py-6">
                <div class="flex flex-row items-center justify-between gap-x-6 py-4">
                  <span class="text-gray-700">CLABE</span>
                  <span class="font-bold">{{ group.tokuClabe }}</span>
                </div>
                <div class="flex flex-row items-center justify-between gap-x-6 py-4">
                  <span class="text-gray-700">Monto a pagar</span>
                  <span class="font-bold">
                    {{ $filters.toCurrency(groupLoan.pendingPaymentsAmount || groupLoan.repaymentAmount) }}
                  </span>
                </div>
                <div class="flex flex-row items-center justify-between gap-x-4 py-4">
                  <span class="text-gray-700">Banco</span>
                  <span class="font-bold">STP</span>
                </div>
                <div class="flex flex-row justify-between gap-x-6 py-4">
                  <span class="text-gray-700">Beneficiario</span>
                  <span class="text-right font-bold">XYZ PAGOS SA DE CV</span>
                </div>
                <div class="flex flex-row items-center justify-between gap-x-4 py-4">
                  <span class="text-gray-700">Estado</span>
                  <template v-if="groupLoan.delinquentPayments.length > 0">
                    <base-badge
                      label="Atrasado"
                      color="red"
                    />
                  </template>
                  <template v-else>
                    <base-badge
                      :label="groupLoan.pendingPaymentsAmount > 0 ? 'Pendiente' : 'Pagado'"
                      :color="groupLoan.pendingPaymentsAmount > 0 ? 'yellow' : 'green'"
                    />
                  </template>
                </div>
              </div>
              <base-button
                text="Copiar CLABE"
                variant="white"
                @click="copyClabe"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mt-4 flex flex-col gap-y-4 px-4">
            <h2 class="font-bold">
              Instrucciones de pago
            </h2>
            <ul class="flex list-inside list-disc flex-col gap-y-2 text-gray-700">
              <li>Solicita al cajero hacer un pago Paycash.</li>
              <li>Indica el número de convenio y referencia.</li>
              <li v-if="selectedConvenienceStore.code">
                El <strong>número de convenio</strong> lo encuentras abajo, junto a la
                referencia
              </li>
              <li>
                ¡Listo! podrás ver tu pago reflejado en la plataforma, haciendo
                click
                <a
                  href="/group_loan"
                  class="text-blue-400"
                >
                  aquí</a>.
              </li>
            </ul>
          </div>
          <div class="mt-8 rounded-md bg-white p-6">
            <div class="flex flex-col gap-y-10 pb-10 sm:pb-0 sm:pr-10">
              <h1 class="text-lg font-bold">
                Referencia de pago en efectivo para
                <span class="text-primary-600">{{ group.name }}</span>
                En {{ stores[selectedConvenienceStore].name }}
              </h1>
              <img
                :src="group.tokuBarcodeUrl"
                class="h-auto w-full"
              >
              <div class="flex w-full flex-col divide-y divide-gray-300 rounded-xl bg-gray-50 px-4 py-6">
                <div class="flex flex-row items-center justify-between gap-x-4 py-4">
                  <span class="text-gray-700">Referencia</span>
                  <span class="font-bold">{{ group.tokuReference }}</span>
                </div>
                <div
                  v-if="stores[selectedConvenienceStore].code"
                  class="flex flex-row items-center justify-between gap-x-4 py-4"
                >
                  <span class="text-gray-700">Número de convenio</span>
                  <span class="font-bold">{{ stores[selectedConvenienceStore].code }}</span>
                </div>
                <div class="flex flex-row items-center justify-between gap-x-4 py-4">
                  <span class="text-gray-700">Monto a pagar</span>
                  <span class="font-bold">
                    {{ $filters.toCurrency(groupLoan.pendingPaymentsAmount || groupLoan.repaymentAmount) }}
                  </span>
                </div>
                <div class="flex flex-row items-center justify-between gap-x-4 py-4">
                  <span class="text-gray-700">Tipo de pago</span>
                  <span class="font-bold">Servicio paycash</span>
                </div>
                <div class="flex flex-row items-center justify-between gap-x-4 py-4">
                  <span class="text-gray-700">Estado</span>
                  <template v-if="groupLoan.delinquentPayments.length > 0">
                    <base-badge
                      label="Atrasado"
                      color="red"
                    />
                  </template>
                  <template v-else>
                    <base-badge
                      :label="groupLoan.pendingPaymentsAmount > 0 ? 'Pendiente' : 'Pagado'"
                      :color="groupLoan.pendingPaymentsAmount > 0 ? 'yellow' : 'green'"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <span class="font-medium">
          Selecciona la tienda donde deseas pagar
        </span>
        <ul class="mt-8 flex flex-col gap-y-6">
          <li
            v-for="store in Object.keys(stores)"
            :key="stores[store].name"
          >
            <button
              class="flex h-32 w-full flex-col items-center gap-y-2 rounded-lg bg-white px-6 py-4"
              @click="setSelectedConvenienceStore(store)"
            >
              <span>
                {{ stores[store].name }}
              </span>
              <div class="max-h-full overflow-hidden">
                <img
                  :src="`/images/${stores[store].image}`"
                  class="h-full w-auto object-cover"
                >
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
