import { format as originalFormat, formatDistanceToNow } from 'date-fns';
import humps from 'humps';

export function deaccentisize(value) {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function toCurrency(value, options = {}) {
  if (!Number(value) && value !== 0) {
    return value;
  }

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 0,
    ...options,
  });

  return formatter.format(value);
}

export function split(value) {
  return value.split('_').join(' ');
}

export function filterChain(value, filters) {
  if (!value) return null;

  let result = value;
  filters.forEach((filter) => {
    result = filter(result);
  });

  return result;
}

export function formatNumber(value, options = {}) {
  const { separator = ',', minimumFractionDigits = 0 } = options;

  if (!Number(value) && value !== 0) {
    return value;
  }

  const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits, ...options });

  return formatter.format(value).replaceAll(',', separator);
}

export function humanize(value, format = 'snake_case') {
  switch (format) {
  case 'snake_case':
    return value.replaceAll('_', ' ').toUpperCase();
  default:
    return value;
  }
}

export function abbreviateNumber(value) {
  if (!Number(value) && value !== 0) {
    return value;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  });

  return formatter.format(value);
}

export function secondsToMMSS(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export function formatDate(dateString, formatString) {
  const [year, month, day] = dateString.split('-').map(Number);

  // Create a new Date object - remember to subtract 1 from month as JS months start at 0
  const date = new Date(year, month - 1, day);

  return originalFormat(date, formatString);
}

export function formatTime(time, formatString) {
  return originalFormat(new Date(time), formatString);
}

export function formatRelativeDate(time) {
  const ONE_WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000; // milliseconds in one week
  const date = new Date(time);

  const now = new Date();
  if (now - date < ONE_WEEK_IN_MS) {
    return formatDistanceToNow(date, { addSuffix: true });
  }

  return originalFormat(date, 'dd-MM-yyyy');
}

export function decamelize(object) {
  return humps.decamelizeKeys(object);
}
