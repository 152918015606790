<script setup>
import { ref, computed, watch, nextTick } from 'vue';
import { useQuery, useMutation } from '@tanstack/vue-query';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/vue/24/outline';
import StyledTrixEditor from '@/components/styled-trix-editor.vue';
import BaseComment from '@/components/base-comment.vue';
import { compareAsc } from 'date-fns';
import commentApi from '@/api/comment.js';

const emit = defineEmits(['new-comment']);
const props = defineProps({
  title: { type: String, default: null },
  resourceId: { type: Number, default: null },
  resourceType: { type: String, required: true },
  canWrite: { type: Boolean, default: true },
  name: { type: String, default: 'comments' },
  commentsHeightClass: { type: String, default: 'h-96' },
});

const commentsContainerRef = ref(null);

const { data: comments, refetch, isLoading: isFetchingComments } = useQuery({
  queryKey: ['comments', props.resourceId, props.resourceType],
  queryFn: () => commentApi.getAll(props.resourceType, props.resourceId)
    .then(response => response.comments),
  enabled: Boolean(props.resourceType),
});

const sortedComments = computed(() => [...(comments.value || [])].sort(
  (a, b) => compareAsc(new Date(b.createdAt), new Date(a.createdAt)),
) || []);

watch(sortedComments, () => {
  nextTick(() => {
    commentsContainerRef.value.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
});

const newComment = ref('');

const { mutate, isPending } = useMutation({
  mutationFn: () => commentApi.create({
    body: newComment.value,
    resourceType: props.resourceType,
    resourceId: props.resourceId,
  }),
  onSuccess: ({ comment }) => {
    emit('new-comment', comment);
    newComment.value = '';
    refetch();
  },
});
</script>

<template>
  <div class="flex flex-col gap-y-3">
    <h2 class="flex items-center">
      <ChatBubbleBottomCenterTextIcon
        class="mr-3 h-6 w-6 text-gray-700"
      />
      <span class="text-lg text-gray-700">
        {{ title || 'Comentarios' }}
      </span>
    </h2>
    <div
      ref="commentsContainerRef"
      :class="['mb-6 overflow-y-scroll rounded-lg bg-gray-100 p-2', commentsHeightClass]"
      style="resize: vertical;"
    >
      <div
        v-if="sortedComments.length === 0"
        class="text-base text-gray-100"
      >
        No hay comentarios
      </div>
      <div
        v-else
        class="space-y-2"
      >
        <base-comment
          v-for="comment in sortedComments"
          :key="comment.id"
          :comment="comment"
        />
      </div>
    </div>

    <div
      v-if="canWrite"
      class="flex flex-col space-y-2"
    >
      <styled-trix-editor
        v-model="newComment"
        :name="name"
        v-bind="$attrs"
      />
      <base-button
        class="ml-auto mt-2"
        text="Enviar"
        :loading="isPending"
        :disabled="!newComment"
        @click="mutate"
      />
    </div>
  </div>
</template>
